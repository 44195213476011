<template>
  <div class="management-center">
    <div class="center-box">
      <div class="management-part">
        <span @click="func('A')" v-bind:class="{ active: active == 'A' }"
          >增值税普通发票</span
        >
      </div>
      <div class="block block1" v-if="block == 'A'">
        <div class="corporateNames">
          <div class="corporateName">
            <p>公司名称：</p>
            <input
              type="text"
              placeholder="请填写公司名称"
              v-model="companyName"
              style="margin-left:10px"
            />
          </div>
          <div class="corporateName" style="margin-left:73px">
            <p>纳税人识别码：</p>
            <input
              type="text"
              placeholder="请填写纳税人识别码"
              v-model="taxpayerCode"
            />
          </div>
        </div>
        <div class="corporateNames">
          <div class="corporateName">
            <p>发票内容：</p>
            <input type="text" style="margin-left:10px" v-model="content" />
          </div>
          <div class="corporateName" style="margin-left:75px">
            <p>发票金额：￥</p>
            <input type="text" v-model="money" />
          </div>
        </div>
        <div class="line"></div>

        <div class="corporateName" style="margin-left:80px; font-size: 18px;">
          <p>收件人：</p>
          <input
            type="text"
            v-model="name"
            style="margin-left:22px;width: 300px; border: none;outline: none; font-size: 14px;"
          />
        </div>
        <div
          class="corporateName"
          style="margin-left:80px;margin-top:30px; font-size: 18px;"
        >
          <p>联系电话：</p>
          <input
            type="text"
            v-model="number"
            style="margin-left:10px;  width: 300px; border: none;outline: none;font-size: 14px;"
          />
        </div>
        <div
          class="corporateName"
          style="margin-left:80px;margin-top:30px; font-size: 18px;"
        >
          <p>邮箱地址：</p>
          <input
            type="text"
            placeholder="请填写邮箱地址"
            v-model="eMail"
            style="margin-left:10px;width: 300px; border: none;outline: none;font-size: 14px;"
          />
        </div>
        <div
          class="corporateName"
          style="margin-left:80px;margin-top:30px; font-size: 18px;"
        >
          <p>收件地址：</p>
          <input
            type="text"
            placeholder="请填写收件地址"
            v-model="shippingAddress"
            style=" margin-left:10px;width: 300px; border: none;outline: none;font-size: 14px;"
          />
        </div>
        <div class="lastbox">
          <p>税点：￥{{ taxation }}</p>
          <button class="btn" @click="btn">支付</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { invoice } from "@/api/user";
import storage from "store";
export default {
  name: "RecordIndex",
  data() {
    return {
      taxation: 242,
      eMail: "",
      shippingAddress: "",
      companyName: "", //公司名称
      taxpayerCode: "", //纳税人识别码
      money: "3536",
      content: "服务费(代租车)", //发票内容
      name: "",
      number: 15617522763,
      active: "A",
      block: "A",
      columns1: [
        {
          title: "订单状态",

          key: "name"
        },
        {
          title: "订单号",
          key: "age"
        },
        {
          title: "用车时间",
          key: "address"
        },
        {
          title: "车型",
          key: "address"
        },
        {
          title: "起始路线",
          key: "address"
        },
        {
          title: "订单金额(元)",
          key: "address"
        },
        {
          title: "操作",
          key: "address"
        }
      ],
      data1: [
        {
          name: "王小明",
          age: 18,
          address: "北京市朝阳区芍药居"
        },
        {
          name: "张小刚",
          age: 25,
          address: "北京市海淀区西二旗"
        },
        {
          name: "李小红",
          age: 30,
          address: "上海市浦东新区世纪大道"
        },
        {
          name: "周小伟",
          age: 26,
          address: "深圳市南山区深南大道"
        }
      ],
      userId:''
    };
  },
  methods: {
    func: function(m) {
      this.active = m;
      this.block = m;
    },
    btn() {
      console.log(this.name);
      invoice({
        type: 1,
        enter_prise_user_id: this.userId,
        amount: this.money,
        tax_fee: this.taxation,
        company: this.companyName,
        company_tel: "",
        company_address: "",
        bank_name: "",
        bank_account: "",
        content: this.content,
        receiver: this.name,
        mobile: this.number,
        address: this.shippingAddress,
        email: this.eMail,
        pay_channel: 1,
        serial_no: "SMH-NO-123321"
      }).then(res => {
        console.log(res.data);
      });
    }
  },
  created() {
    this.userId = storage.get("user").id;
  }
};
</script>

<style scoped>
@import "../../../style/common.css";
.management-center {
  width: 1000px;
  height: 800px;
  margin: 64px auto;
  position: relative;
}
.center-box {
  width: 100%;
  height: 660px;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}

.management-centerline {
  height: 18px;
  width: 3px;
  background-color: #ff5400;
  margin-right: 10px;
}
.management-container {
  height: 1200px;
  width: 998px;
  border: 1px solid gainsboro;
}
.management-part {
  display: flex;
  justify-content: flex-start;
  margin-top: 14px;
    cursor: pointer;
}
.management-part div {
  display: inline-block;
  width: 150px;
  height: 34px;
  line-height: 34px;
  background-color: hsla(0, 0%, 100%, 0.9);
  border-radius: 2px;
  border: 1px solid #f16622;
  color: #f16622;
  text-align: center;
  cursor: pointer;
  margin-top: 10px;
}
.management-partdiv {
  margin-left: 370px;
  margin-right: 30px;
}

.management-part span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 146px;
  height: 56px;
  border: 1px solid #d8dde9;
  margin-left: 0;
  text-align: center;
  line-height: 146cpx;
  color: #999;
  background-color: #f3f4f5;
  border-bottom: 1px solid #d8dde9;
  z-index: 100;
  font-size: 16px;
}

.management-part .active {
  background-color: white;
  color: #424456;
  border-bottom: none;
}

.block {
  width: 998px;
  min-height: 700px;
  border: 1px solid #ddd;
  position: absolute;
      top: 119px;
  display: flex;
  justify-content: flex-start;
  color: #151515;
  flex-direction: column;
}

.recharge-rules {
  display: flex;

  align-items: center;
  position: absolute;
  right: 190px;
  top: 170px;
  color: #2fa0ed;
  cursor: pointer;
}
.recharge-rules img {
  width: 16px;
  height: 14px;
}
.titlebox {
  margin-top: 20px;
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.line {
  width: 450px;
  height: 1px;
  background-color: #d9d9d9;
}
.linebox {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.linebox p {
  font-size: 16px;
  font-weight: bold;
  margin-left: 10px;
  margin-right: 10px;
}
.corporateName {
  display: flex;
  flex-direction: row;
}
.corporateNames {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  margin-left: 85px;
  align-items: center;
  font-size: 18px;
}
.corporateNames input {
  width: 300px;
  border: none;
  outline: none;
  font-size: 14px;
}
.line {
  width: 95%;
  height: 1px;
  background-color: #d9d9d9;
  margin: 50px auto;
}
.lastbox {
  width: 100%;
  height: 80px;
  margin-top: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.lastbox p {
  font-size: 16px;
  margin-top: 30px;
  margin-right: 30px;
}
.btn {
  width: 98px;
  height: 34px;
  background: #f16622;
  border: 1px solid #f16622;
  border-radius: 2px;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  margin-top: 30px;
  margin-left: 18px;
  margin-right: 20px;
}
</style>
