import request from '@/utils/request'

const userApi = {
    domesticCities:'/region/cities',
    addressTips:'map/autocomplete',
    serviceType:'/service/day_charter/service_type',
    carTypes:'/service/day_charter/car_types',
    charter:'/service/day_charter/infos',
    charterPrice:'service/day_charter/price',
    charOrder:'/order/create',
    payOrder:'/order/pay',
    onewayPrice:'/service/price/check',
    onewayinfos:'/service/oneway/infos',
    passengerList:'user/passenger_list',
    createUser:'/user/create_normal',
    oneCoupon:'/wallet/coupons/use',
    daycharterPrice:'/service/day_charter/price',
    getCombos:'/service/combos'
}


export function createUser(data) {
    return request({
        url: userApi.createUser,
        method: 'post',
        data
    })
}
export function invoice(data) {
    return request({
        url: userApi.invoice,
        method: 'post',
        data
    })
}
export function charOrder(data) {
    return request({
        url: userApi.charOrder,
        method: 'post',
        data
    })
}
export function payOrder(data) {
    return request({
        url: userApi.payOrder,
        method: 'post',
        data
    })
}
export function domesticCities(params) {
    return request({
        url: userApi.domesticCities,
        method: 'get',
        params
    })
}
export function autocomplete(params) {
    return request({
        url: userApi.addressTips,
        method: 'get',
        params
    })
}
export function serviceType(params) {
    return request({
        url: userApi.serviceType,
        method: 'get',
        params
    })
}
export function carTypes(params) {
    return request({
        url: userApi.carTypes,
        method: 'get',
        params
    })
}

export function charter(params) {
    return request({
        url: userApi.charter,
        method: 'get',
        params
    })
}
// export function carPrice(params) {
//     return request({
//         url: userApi.carPrice,
//         method: 'get',
//         params
//     })
// }

export function charterPrice(params) {
    return request({
        url: userApi.charterPrice,
        method: 'get',
        params
    })
}

export function onewayPrice(params) {
    return request({
        url: userApi.onewayPrice,
        method: 'get',
        params
    })
}

export function onewayinfos(params) {
    return request({
        url: userApi.onewayinfos,
        method: 'get',
        params
    })
}
export function passengerList(params) {
    return request({
        url: userApi.passengerList,
        method: 'get',
        params
    })
}

export function oneCoupon(params) {
    return request({
        url: userApi.oneCoupon,
        method: 'get',
        params
    })
}

export function daycharterPrice(params) {
    return request({
        url: userApi.daycharterPrice,
        method: 'get',
        params
    })
}
export function getCombos(params) {
    return request({
        url: userApi.getCombos,
        method: 'get',
        params
    })
}