
<template>
  <div class="management-center">
    <div class="center-box">
      <div class="p-box">
        <div class="management-centerline"></div>
        <p>券记录</p>
      </div>
      <div class="management-part">
        <span @click="func('A')" v-bind:class="{ active: active == 'A' }"
          >未使用</span
        >
        <span @click="func('B')" v-bind:class="{ active: active == 'B' }"
          >已使用</span
        >
      </div>

      <div class="block block1" v-if="block == 'A'">
        <div class="couponlist">
          <div class="coupon" v-for="item in couponList" :key="item.price">
            <div class="bar"></div>
            <div class="discount">
              <div class="discountP">
                <p style="font-size: 20px; color: #f16622; margin-top: 30px">
                  ￥
                </p>
                <p style="font-size: 50px; color: #f16622">
                  {{ item.discount }}
                </p>
              </div>
              <!-- <p style="font-size: 12px; color: rgba(0, 0, 0, 0.38)">
                满{{ 0 }}元可用
              </p> -->
            </div>
            <img src="../../../assets/line.png" alt />
            <div class="couponName">
              <p style="font-size: 16px; color: #616161; margin-bottom: 6px">
                {{ item.name }}
              </p>
              <p style="font-size: 12px; color: #616161; margin-bottom: 10px">
                有效期:{{ item.end_time }}
              </p>
            </div>
          </div>
          <Page
            style="margin-left: 650px; margin-top: 20px"
            :total="dataCount1"
            :page-size="pageSize1"
            show-sizer
            class="paging1"
            @on-change="changepage1"
            @on-page-size-change="pagesize1"
          ></Page>
        </div>
      </div>
      <div class="block block1" v-if="block == 'B'">
        <div class="couponlist">
          <div class="coupon" v-for="item in couponList1" :key="item.price">
            <div class="bar"></div>
            <div class="discount">
              <div class="discountP">
                <p style="font-size: 20px; color: #f16622; margin-top: 30px">
                  ￥
                </p>
                <p style="font-size: 50px; color: #f16622">
                  {{ item.discount }}
                </p>
              </div>
              <!-- <p style="font-size: 12px; color: rgba(0, 0, 0, 0.38)">
                满{{ item.discount }}可用
              </p> -->
            </div>
            <img src="../../../assets/line.png" alt />
            <div class="couponName">
              <p style="font-size: 16px; color: #616161; margin-bottom: 6px">
                {{ item.name }}
              </p>
              <p style="font-size: 12px; color: #616161; margin-bottom: 10px">
                有效期:{{ item.end_time }}
              </p>
            </div>
          </div>
          <Page
            style="margin-left: 650px; margin-top: 20px"
            :total="dataCount2"
            :page-size="pageSize2"
            show-sizer
            class="paging2"
            @on-change="changepage2"
            @on-page-size-change="pagesize2"
          ></Page>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { wallet } from "@/api/user";
import storage from "store";
export default {
  name: "ManageCenter",
  data() {
    return {
      userId: "",
      active: "A",
      block: "A",
      couponList: [],
      couponList1: [],
      // ajaxHistoryData1: [],
      // 初始化信息总条数
      dataCount1: 0,
      dataCount12: 0,
      // 每页显示多少条
      pageSize1: 10,
      // 当前页码
      page1: 1,
      historyData1: [],

      ajaxHistoryData2: [],
      // 初始化信息总条数
      dataCount2: 0,
      // 每页显示多少条
      pageSize2: 10,
      // 当前页码
      page2: 1,
      historyData2: [],
    };
  },
  methods: {
    func: function (m) {
      this.active = m;
      this.block = m;
      console.log(m);
      if (this.active == "B") {
        console.log(1111111);
        wallet({ user_id: this.userId, status: 1 }).then((res) => {
          console.log(res.data.data);
          const data = (this.historyData2 = res.data.data || []);
          this.dataCount2 = data.length;
          this.changepage2(1);
          //  res.data. meta.pagination.
        });
      } else if (this.active == "A") {
        console.log(2222);
        wallet({ user_id: this.userId, status: 0 }).then((res) => {
          console.log(res.data.data);
          const data = (this.historyData1 = res.data.data || []);
          this.dataCount1 = data.length;
          this.changepage1(1);
        });
      }
    },
    changepage1(index) {
      // 当前页码
      this.page1 = index;
      let _start = (index - 1) * this.pageSize1;
      let _end = index * this.pageSize1;
      this.couponList = this.historyData1.slice(_start, _end);
    },
    pagesize1(index) {
      let _start = (this.page1 - 1) * index;
      let _end = this.page1 * index;
      this.historyData1 = this.ajaxHistoryData1.slice(_start, _end);
      // 当前展示条数
      this.pageSize1 = index;
    },
    changepage2(index) {
      // 当前页码
      this.page2 = index;
      let _start = (index - 1) * this.pageSize2;
      let _end = index * this.pageSize2;
      this.couponList1 = this.historyData2.slice(_start, _end);
    },
    pagesize2(index) {
      let _start = (this.page2 - 1) * index;
      let _end = this.page2 * index;
      this.historyData2 = this.ajaxHistoryData2.slice(_start, _end);
      // 当前展示条数
      this.pageSize2 = index;
    },
  },
  created() {
    this.userId = storage.get("user").id;
    const params = {
      user_id: this.userId,
      status: 0,
    };
    wallet(params).then((res) => {
      // this.couponList=res.data.data;
      this.historyData1 = res.data.data;
      this.dataCount1 = this.historyData1.length;
      if (this.dataCount1 < this.func("A")) {
        this.couponList = this.historyData1;
      } else {
        this.couponList = this.historyData1.slice(0, this.func("A"));
      }
    });
    wallet({ user_id: this.userId, status: 1 }).then((res) => {
      // this.couponList1=res.data.data;
      this.historyData2 = res.data.data;
      this.dataCount2 = this.historyData2.length;
      if (this.dataCount2 < this.pagesize2) {
        this.couponList1 = this.historyData2;
      } else {
        this.couponList1 = this.historyData2.slice(0, this.pagesize2);
      }
    });
  },
};
</script>

<style scoped>
@import "../../../style/common.css";
.management-center {
  width: 1000px;
  height: 1200px;
  margin: 100px auto;
  position: relative;
}
.center-box {
  width: 100%;
  height: 660px;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}
.p-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
}
.management-centerline {
  height: 18px;
  width: 3px;
  background-color: #ff5400;
  margin-right: 10px;
}
.management-container {
  height: 604px;
  width: 998px;
  border: 1px solid gainsboro;
}
.management-part {
  display: flex;
  justify-content: flex-start;
  margin-top: 14px;
  cursor: pointer;
}
.management-part div {
  display: inline-block;
  width: 150px;
  height: 34px;
  line-height: 34px;
  background-color: hsla(0, 0%, 100%, 0.9);
  border-radius: 2px;
  border: 1px solid #f16622;
  color: #f16622;
  text-align: center;
  cursor: pointer;
  margin-top: 10px;
}
.management-partdiv {
  margin-left: 370px;
  margin-right: 30px;
}

.management-part span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 146px;
  height: 56px;
  border: 1px solid #d8dde9;
  margin-left: 0;
  text-align: center;
  line-height: 146cpx;
  color: #999;
  background-color: #f3f4f5;
  border-bottom: 1px solid #d8dde9;
  z-index: 100;
  font-size: 16px;
}

.management-part .active {
  background-color: white;
  color: #424456;
  border-bottom: none;
}

.block {
  width: 998px;
  min-height: 864px;
  border: 1px solid #ddd;
  position: absolute;
  top: 146px;
  display: flex;
  justify-content: flex-start;
  color: #151515;
  flex-direction: column;
}
.couponlist {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.coupon {
  width: 448px;
  height: 108px;
  background-color: white;
  -webkit-box-shadow: 0 2px 6px 0 rgba(66, 68, 86, 0.12);
  box-shadow: 0 2px 6px 0 rgba(66, 68, 86, 0.12);
  margin-left: 30px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.coupon img {
  height: 90px;
}
.discount {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}
.discountP {
  display: flex;
  flex-direction: row;
  margin-right: 20px;
}
.couponName {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}
.bar {
  width: 5px;
  height: 100%;
  background-color: #ff5400;
}
</style>
