<template>
    <div class="footer-container">
      <p>深圳壹路通科技有限公司</p>
      <div class="line"></div>
           <p>粤SDHDG号-3</p>
      <div class="line"></div>
           <p>增值电信许可证；粤B2-20201034</p>
      <div class="line"></div>
          <p>为了更好的产品体验，推荐您使用Google Chrome浏览器</p>
    </div>
</template>

<script>
    // @ is an alias to /src
    export default {
        name: "car-module-header",
        mounted() {

        }
    };
</script>

<style scoped>
    @import "../../style/common.css";
.footer-container{
       width: 100%;
    min-width: 1000px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background-color: #242527;
    font-size: 14px;
    color: #ccc;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -900px;
}
.line{
    width: 2px;
    height: 20px;
    background-color: #cccc;
    margin-left: 10px;
    margin-right: 10px;
}
  
</style>