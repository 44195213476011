<template>
  <div class="container">
    <div class="banner-img">
      <Card shadow class="login-container" v-show="login">
        <p slot="title" style="font-size: 16px">登录</p>
        <div class="input-container">
          <div style=" align-items: flex-start; display: flex; margin-bottom: 20px;margin-left: 80px;">
            <!-- <Input class="base-input" @change="vv" v-model="phoneNumber" style="width:200px" placeholder="输入手机号码" /> -->
            <input @change="vv" v-model="phoneNumber" style="outline-color: #ff5400;border: 1px #d1d1d1 solid; border-radius: 4px;width:350px;height:40px;padding-left:10px" placeholder="输入手机号码">

          </div>
          <div class="code-container">
            <!-- <Input class="base-input" v-model="code" placeholder="输入验证码" style=""/> -->
            <input v-model="code" placeholder="输入验证码"  style="outline-color: #ff5400;border: 1px #d1d1d1 solid; border-radius: 4px;width:250px;height:40px;padding-left:10px" >

            <Button v-if="canGetAuthCode" type="warning" class="get-auth-code" @click="tapclick">获取验证码</Button>
            <Button v-else type="warning" class="get-auth-code">{{countDown}}后重试</Button>
          </div>
        </div>
        <Button type="primary" style="width:320px;background-color:#ff5400;border: 0px;padding:10px 0px;margin-top:20px" @click="tapLogin">登录</Button>
      </Card>
      <Card shadow class="login-container" v-show="logined">
        <p slot="title" style="font-size: 16px">您已登录</p>
        <img src="../assets/tx.png" alt="" style="width:100px;height:100px;border-radius: 100%;">
        <p>{{login_mobile}}</p>
        <button class="btn" @click="btn">去下单</button>
      </Card>
    </div>
    <div>
      <p class="medium-title">服务优势，满足企业全面需求</p>
      <Row style="margin: 0 5vw;background-color: #f3f3f3">
        <Col span="6">
          <Card :bordered="false" style="margin: 0 1px 0 0">
            <img src="../assets/func-img-1.png"/>
            <p>一站式服务</p>
            <p>旅游，长包，短包</p>
          </Card>
        </Col>
        <Col span="6">
          <Card :bordered="false" style="margin: 0 1px 0 0">
            <img src="../assets/func-img-2.png"/>
            <p>即时开票</p>
            <p>6%电子普票当日即开
              提供9%增值税专票</p>
          </Card>
        </Col>
        <Col span="6">
          <Card :bordered="false" style="margin: 0 1px 0 0">
            <img src="../assets/func-img-3.png"/>
            <p>充值送券</p>
            <p>多种支付渠道,即时用车即时支付</p>
          </Card>
        </Col>
        <Col span="6">
          <Card :bordered="false">
            <img src="../assets/func-img-4.png"/>
            <p>超管账户</p>
            <p>设有超级管理员 多种角色权限，多级架构管理</p>
          </Card>
        </Col>
      </Row>
      <Divider/>
      <Row>
      </Row>
    </div>
    <div>
      <p class="medium-title">车型展示</p>
      <div class="cartype">
      <div style=" width: 300px;height: 200px; display: flex;flex-direction: column;  align-items: center;">
      <img src="../assets/suv.png" alt="" style="width: 125px;height: 112px;" >
      <p style="font-weight: bold;font-size:14px;color:#ff5400;margin-top:10px">热门车型</p>
      <p style="color:#ff5400">优选车型，出行选择</p>

      </div>
      <div style=" width: 300px;height:  200px;display: flex;flex-direction: column;  align-items: center;">
          <img src="../assets/suv.png" alt="" style="width: 125px;height: 112px" >
            <p style="font-weight: bold;font-size:14px;margin-top:10px">商务车</p>
      <p>6至14座</p>
      </div>
      <div style=" width: 300px;height:  200px;display: flex;flex-direction: column;  align-items: center;">
          <img src="../assets/ch.png" alt="" style="width: 125px;height: 112px" >
            <p style="font-weight: bold;font-size:14px;margin-top:10px">中巴车</p>
      <p>15-29座</p>
      </div>
      <div style=" width: 300px;height:  200px;display: flex;flex-direction: column;  align-items: center;">
          <img src="../assets/bus.png" alt="" style="width: 125px;height: 112px" >
            <p style="font-weight: bold;font-size:14px;margin-top:10px">大巴车</p>
      <p>30至64座</p>
      </div>

      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {userGetCode, userLogin} from "@/api/user";
import storage from 'store'

export default {
  name: "Home",
  data(){

    return{
      login:true,
      logined:false,
      login_mobile:'',
      phoneNumber:'' ,
      code:'',
      canGetAuthCode: true,
      countDown: 60
    }
  },
  mounted() {

  },
  methods: {
    vv(){
      console.log(this.phoneNumber)
    },
    tapLogin(){
      const data = {
        code:this.code,
        mobile:this.phoneNumber
      }
      userLogin(data).then(res=>{
        console.log(res)
        if (res.code == 201) {
          this.$Message.info(res.msg);
        }else {
          storage.set('user', res.data.user);
          storage.set('balance', res.data.user.balance);

          console.log(storage.get('user'))


          this.$Message.info('登录成功');
          // this.$router.push({ name: '/manage/center' })
           this.$router.push({ path: "/manage/center" });
        }
      })
    },
    tapclick(){
      if(!(/^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/.test(this.phoneNumber))){
       this.$Message.info("请输入正确的电话号码！");
      }else{
   this.canGetAuthCode = false;
      const that = this;
      const params = {
        mobile:this.phoneNumber
      }
      userGetCode(params).then(res=>{
        console.log(res.data.data)
      })

      let interval = setInterval(function(){
        that.countDown--;
        // eslint-disable-next-line no-empty
        if (that.countDown>0) {
        }else{
          that.countDown = 60;//当减到0时赋值为60
          that.canGetAuthCode = true;
          clearInterval(interval);//清除定时器
        }
      },1000);
      }

    },
    btn(){
      this.$router.replace("/manage/center");
    }
  },
  created() {
    this.login_mobile = storage.get("user").login_mobile;
    if(storage.get('user')!=null){
      this.login=false
      this.logined=true
    }else{
      alert('不存在')
    }
  }
};
</script>

<style>
@import "../style/common.css";

.login-container {
  width: 35vw;
  top: 30vh;
  left: 60vw;
  height: 340px;

}

.input-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 20vh;
  
  
}


.code-container{
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
  height: 20px;
  margin-left: 80px;
}

.get-auth-code {
  margin-left: 10px;
}

.func-img {
  background-image: url("../assets/func-img-1.png");

}
.btn{
  width: 300px;
  height: 40px;
  color: white;
  background-color: #ff5400;
  text-align: center;
  line-height: 40px;
  border: none;
  outline :none;
  margin-top: 20px;

}
.cartype{
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;


 }
</style>
