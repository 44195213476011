<template>
  <div class="Management-center">
    <div class="name">
      <p>{{corporateName}}</p>
    </div>
    <div class="corporate-wallet"     @click="imgclick">
      <img class="wallet" src="../../../assets/qb.jpg" alt="" />
      <div class="text">
        <p>企业余额</p>
        <p class="number">{{balance}}元</p>
      </div>
      <img
        class="recharge"
        src="../../../assets/cz.jpg"
        alt=""
      />
    </div>
    <div class="bottom">
      <div class="textspan">
        <div></div>
        <p>已开通产品</p>
      </div>
    </div>
    <div class="last">
      <div class="corporate-car"  @click="charterClick">
        <img class="car" style=" border-radius: 10px;" src="../../../assets/bc.png" alt="">
        <div class="texts">
          <p>国内包车</p>
          <p class="number">300元 <span>原价600元</span></p>
        </div>
        <img class="recharge" src="../../../assets/order.jpg" alt="" >
      </div>
      <div class="corporate-car"  @click="orderClick">
        <img class="car" style=" border-radius: 10px;" src="../../../assets/car.png" alt="" />
        <div class="texts">
          <p>粤港澳单程用车</p>
          <p class="number">500元 <span>原价600元</span></p>
        </div>
        <img
          class="recharge"
          src="../../../assets/order.jpg"
          alt=""

        />
      </div>

    </div>
    <div v-show="last">
      <div class="lastname">
        <div></div>
        <span>企业通用功能设置</span>
      </div>
      <div class="lastbox">
        <div class="setUp" @click="amountclick">
          <img src="../../../assets/bj.png" alt="" />
          <span>设置预警金额</span>
          <span style="margin-left: 10px;color: #e5e5e5;">></span>
        </div>
        <div class="setUp" @click="orderclick">
          <img src="../../../assets/sx.png" alt="" />
          <span>设置赠金消耗顺序</span>
          <span style="margin-left: 10px;color: #e5e5e5;">></span>
        </div>
        <div class="setUp" @click="whyclick">
          <img src="../../../assets/wb.png" alt="" />
          <span>设置用车原由</span>
          <span style="margin-left: 10px;color: #e5e5e5;">></span>
        </div>
      </div>
    </div>

    <div class="show" v-show="show">
      <div class="centerbox">
        <div class="centerpart">
          <span>设置预警金额</span>
          <img src="../../../assets/ca.png" alt="" @click="imgout" />
        </div>
        <p>
          当可用余额不足时，系统会通过短信和邮箱通知指定人员
        </p>
        <div class="inputdiv">
          <div class="left">
            <span style="margin-top: 37px;margin-right:10px"
              ><span style="margin-left:10px;  color: red;">*</span
              >预警金额</span
            >
            <span style="margin-top: 47px;margin-right:10px"
              ><span style="margin-left:10px;  color: red;">*</span
              >预警接收人手机号</span
            >
            <span style="margin-top: 53px;margin-right:10px"
              ><span style="margin-left:10px;  color: red;">*</span
              >预警接收人邮箱</span
            >
          </div>
          <div class="right">
            <i-input
              :value.sync="value"
              style="width: 258px;height: 38px;margin-top:26px"
            ></i-input>
            <i-input
              :value.sync="value"
              style="width: 258px;height: 38px;margin-top:30px"
            ></i-input>
            <i-input
              :value.sync="value"
              style="width: 258px;height: 38px;margin-top:30px"
            ></i-input>
          </div>
        </div>
        <div class="bottombox">
          <button style="background-color:#ff5400;color: #fff;">保存</button>
          <button>取消</button>
        </div>
      </div>
    </div>
    <div class="show" v-show="showmodel">
      <div class="centerbox">
        <div class="centerpart">
          <span>设置赠金消耗顺序</span>
          <img src="../../../assets/ca.png" alt="" @click="imgouts" />
        </div>
        <p>
          赠金只能用于支付路程费用，且赠金支付部分，不可开发票
        </p>

        <Radio-group :model.sync="vertical" vertical>
          <Radio value="apple">
            <Icon type="social-apple"></Icon>
            <span>优先消耗赠金</span>
          </Radio>
          <Radio value="android">
            <Icon type="social-android"></Icon>
            <span>优先消耗本金</span>
          </Radio>
        </Radio-group>
      </div>
    </div>
    <div class="show" v-show="showmodels">
      <div class="centerbox">
        <div class="centerpart">
          <span>设置用车原由</span>
          <img src="../../../assets/ca.png" alt="" @click="imgoutes" />
        </div>
        <p>
          管理员可在web端订单查询页面导出用车原由明细
        </p>
        <div class="checkbox">
          <span>用车原由</span>
          <Checkbox :checked.sync="single"
            >必填(勾选后，企业所有人员下单均需填写用车原由)</Checkbox
          >
        </div>
        <div class="lastcheckbox">
          <button style="color:#ffff;background-color: #ff5400;">
            保存设置
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import storage from "store";

export default {
  data() {
    return {
      show: false,
      showmodel: false,
      vertical: "apple",
      showmodels: false,
      last: false,
      verify:'',
      name:'',
      balance:0,
      corporateName:""
    };
  },
  methods: {
    imgclick() {
      this.$router.push("/manage/recharge/online");
    },
    orderClick() {
      this.$router.push("/car/order/index");
    },
    charterClick(){
      this.$router.push({
        name:'daycharter'
      });
    },
    amountclick: function() {
      this.show = true;
    },
    imgout: function() {
      this.show = !this.show;
    },
    orderclick: function() {
      this.showmodel = true;
    },
    imgouts: function() {
      this.showmodel = !this.showmodel;
    },
    whyclick: function() {
      this.showmodels = true;
    },
    imgoutes: function() {
      this.showmodels = !this.showmodels;

    }
  },
   created() {
      this.verify = storage.get("user").is_verify;
      this.balance = storage.get("balance")
      console.log( this.balance)
      this.corporateName = storage.get("corporateName")
      console.log(this.corporateName)
      console.log(storage.get("user"))

  },
};
</script>

<style scoped>
@import "../../../style/common.css";

.active {
  color: red;
}
.cc {
  color: royalblue;
}
.Management-center {
  width: 1000px;
  height: 630px;
  border: 1px solid gainsboro;
  margin: 100px auto;
  display: flex;
  flex-direction: column;

}
.name {
  width: 100%;
  height: 67px;
  border-bottom: 1px solid gainsboro;
  display: flex;
  justify-content: flex-start;
}
.name p {
  margin-left: 20px;
  line-height: 67px;
  font-size: 15px;
  font-weight: bold;
}
.corporate-wallet {
  width: 350px;
  height: 90px;
  background: #fcfcfc;
  -webkit-box-shadow: 0 2px 6px 0 rgba(66, 68, 86, 0.12);
  box-shadow: 0 2px 6px 0 rgba(66, 68, 86, 0.12);
  margin-left: 60px;
  margin-top: 70px;
  border-radius: 12px;
  position: relative;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}
.corporate-car {
  width: 350px;
  height: 90px;
  background: #fcfcfc;
  -webkit-box-shadow: 0 2px 6px 0 rgba(66, 68, 86, 0.12);
  box-shadow: 0 2px 6px 0 rgba(66, 68, 86, 0.12);
  margin-left: 60px;
  margin-top: 70px;
  border-radius: 12px;
  position: relative;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}
.wallet {
  width: 80px;
  height: 80px;
  position: absolute;
  top: -15px;
  left: 20px;
}
.car {
  width: 80px;
  height: 80px;
  position: absolute;
  top: -15px;
  left: 20px;

}
.text {
  position: absolute;
  top: 10px;
  left: 120px;
  font-size: 16px;
  font-weight: bold;
}
.texts {
  position: absolute;
  top: 10px;
  left: 120px;
  font-size: 16px;
  font-weight: bold;
}
.texts span {
  font-size: 10px;
  color: gainsboro;
}
.number {
  margin-top: 20px;
}
.recharge {
  width: 92px;
  height: 19px;
  position: absolute;
  top: 34px;
  right: 0;
}
.bottom {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.textspan div {
  height: 20px;
  width: 5px;
  background-color: #ff5400;
  margin-right: 10px;
}
.textspan {
  display: flex;
  flex-direction: row;
  margin-left: 60px;
  font-size: 18px;
  margin-top: 50px;
}
.last {
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: row;
}
.lastbox {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}
.setUp {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  width: 214px;
  height: 52px;
  color: #212121;
  font-size: 14px;
  margin-right: 20px;
  background-color: #fcfcfc;
  border: 1px solid #e5e5e5;
  cursor: pointer;
  align-items: center;
  margin-left: 60px;
}
rightimg {
  width: 10px;
  height: 10px;
  margin-top: 10px;
}
.setUp img {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}
.setUp span {
  margin-top: 5px;
}
.lastname {
  margin-top: 10px;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  color: #212121;
  font-size: 18px;
  margin-left: 60px;
}
.lastname div {
  height: 20px;
  width: 5px;
  background-color: #ff5400;
  margin-right: 10px;
}
.show {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 100000;
}
.centerbox {
  width: 520px;
  height: 398px;
  background-color: white;
  margin: 100px auto;
}
.centerpart {
  width: 100%;
  height: 42px;
  border-bottom: #f0f0f0 1px solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.centerpart span {
  margin-left: 16px;
  color: #666;
  font-weight: 500;
}
.centerpart img {
  width: 18px;
  height: 18px;
  margin-right: 20px;
}
.centerbox p {
  margin-top: 20px;
  width: 480px;
  height: 38px;
  line-height: 38px;
  border-radius: 2px;
  background-color: #ecf6fd;
  color: #2fa0ed;
  border: 1px solid #add8f7;
  margin-left: 20px;
}
.inputdiv {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.left {
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: right;
}
.right {
  width: 50%;
  height: 100%;
}
.bottombox {
  width: 100%;
  height: 52px;
  border-top: 1px solid #f0f0f0;
  margin-top: 47px;
  display: flex;
  justify-content: flex-end;
}
.bottombox button {
  width: 90px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  margin-top: 10px;
  border-radius: 2px;
  cursor: pointer;
  outline: none;
  margin-right: 20px;
  border: none;
}
.divradio {
  display: flex;
  flex-direction: column;
}
.checkbox {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}
.checkbox span {
  margin-left: 20px;
  margin-right: 24px;
}
.lastcheckbox {
  width: 100%;
  height: 52px;
  border-top: 1px solid #f0f0f0;
  margin-top: 208px;
  display: flex;
  justify-content: flex-end;
}
.lastcheckbox button {
  width: 90px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  margin-top: 10px;
  border-radius: 2px;
  cursor: pointer;
  outline: none;
  margin-right: 20px;
  border: none;
}
</style>
