<template>
  <div class="management-center">
    <div class="center-box">
      <div class="p-box">
        <div class="management-centerline"></div>
        <p>发票管理</p>
      </div>
      <div class="management-part">
        <span @click="func('A')" v-bind:class="{ active: active == 'A' }"
          >发票申请</span
        >
        <!-- <span @click="func('B')" v-bind:class="{ active: active == 'B' }"
          >专票设置</span
        > -->
        <span @click="func('C')" v-bind:class="{ active: active == 'C' }"
          >开票记录</span
        >
      </div>
      <div class="recharge-rules" @click="rechargeRecord">
        <img src="../../../assets/icon.jpg" alt />
        开票规则
      </div>

      <div class="block block1" v-if="block == 'A'">
        <div class="input">
          <div class="name">
            <p>员工姓名</p>
            <i-input
              :value.sync="value"
              style="width: 132px;height: 34px;"
            ></i-input>
          </div>
          <div class="tel">
            <p>员工电话</p>
            <i-input
              :value.sync="value"
              style="width: 132px;height: 34px;"
            ></i-input>
          </div>
          <div class="carbm">
            <p>用车部门</p>
            <i-select :model.sync="model1" style="width:180px; ">
              <i-option v-for="item in cityList" :key="item.value">{{
                item.label
              }}</i-option>
            </i-select>
          </div>
          <div class="cartime">
            <p>用车日期</p>
            <row>
              <i-col span="12">
                <Date-picker
                  type="date"
                  :options="options3"
                  placeholder="选择日期"
                  style="width: 155px"
                ></Date-picker>
              </i-col>
              <i-col span="12">
                <Date-picker
                  type="date"
                  :options="options4"
                  placeholder="选择日期"
                  style="width: 155px"
                ></Date-picker>
              </i-col>
            </row>
          </div>
        </div>
        <div class="inputs">
          <div class="carbm">
            <p>支付方式</p>
            <i-select :model.sync="model1" style="width:180px; ">
              <i-option v-for="item in cityList" :key="item.value">{{
                item.label
              }}</i-option>
            </i-select>
          </div>
          <div class="carbm">
            <p>业务类型</p>
            <i-select :model.sync="model1" style="width:180px; ">
              <i-option v-for="item in cityList" :key="item.value">{{
                item.label
              }}</i-option>
            </i-select>
          </div>
        </div>
        <button class="btn">查询</button>
        <div class="line"></div>
        <div class="bottom-part">
          <Tabs active-key="key1" style="width:97%;">
            <Tab-pane label="快捷开票" key="key1">
              <div class="labeldiv">
                <div class="text">
                  <!-- <p>选择发票类型:</p>
                  <div class="textdiv">增值税普通电子发票</div>
                  <div class="textdiv">增值税专用纸质发票</div> -->
                  
                </div>
                <div class="lastdiv">
                  <div class="tip">
                    注：以下统计仅展示可开票的已完成订单，优惠或赠送金额不可开票
                  </div>
                  <div class="invoice-brief">
                    <div class="divp">
                      <p>订单行程总数</p>
                      <p style="color: #f16622;font-size: 24px;">{{total}}</p>
                    </div>
                    <div class="lines"></div>
                    <div class="divps">
                      <p>可开票金额(元)</p>
                      <p style="color: #f16622;font-size: 24px;">{{money}}</p>
                    </div>
                  </div>
                      <div class="text">
                  <p>选择开票类型:</p>
                  <div class="textdiv"  @click="invoicing"
                    v-show="showBillingbtn">增值税普通电子发票</div>
                  <div class="textdiv" @click="special"  v-show="showSpecial">增值税专用纸质发票</div>
                  <div class="textsdiv" @click="invoice">
                    增值税普通电子发票与增值税专用纸质发票的区别?
                  </div>
                </div>
                </div>
              </div>
            </Tab-pane>
            <Tab-pane label="充值明细开票" key="key2">
              <div class="labeldiv">
                <!-- <div class="text">
                  <p>选择发票类型:</p>
                  <div class="textdiv">增值税普通电子发票</div>
                  <div class="textdiv">增值税专用纸质发票</div>
                  <div class="textsdivs" @click="invoice">
                    增值税普通电子发票与增值税专用纸质发票的区别?
                  </div>
                </div> -->
                <div class="lastdiv">
                  <div class="tip">
                    注：以下统计仅展示可开票的已完成订单，优惠或赠送金额不可开票
                  </div>
                  <i-table
                    border
                    :columns="columns4"
                    style="margin-top:20px"
                  ></i-table>
                </div>
                      <div class="text">
                  <p>选择开票类型:</p>
                  <div class="textdiv"  @click="invoicing"
                    v-show="showBillingbtn">增值税普通电子发票</div>
                  <div class="textdiv" @click="special"  v-show="showSpecial">增值税专用纸质发票</div>
                  <div class="textsdiv" @click="invoice">
                    增值税普通电子发票与增值税专用纸质发票的区别?
                  </div>
                </div>
              </div>
            </Tab-pane>
          </Tabs>
        </div>
      </div>
      <!-- <div class="block block1" v-if="block == 'B'"></div> -->
      <div class="block block1" v-if="block == 'C'">
        <div class="billingRecord">
          <div class="textPart">
            <div style="  display: flex;">
              <div class="management-centerline"></div>
              <div>开票记录</div>
            </div>
            <p style="color:#f16622;margin-right:20px">
              提交申请后如开票记录未正常显示，请1分钟后刷新重试!
            </p>
          </div>
          <div style="  display: flex;margin-top:20px">
            <div class="tel">
              <p>发票类型</p>
              <i-select :model.sync="model1" style="width:180px; ">
                <i-option v-for="item in invoiceType" :key="item.value">{{
                  item.label
                }}</i-option>
              </i-select>
            </div>
            <div class="carbm">
              <p>发票状态</p>
              <i-select :model.sync="model1" style="width:180px">
                <i-option v-for="item in invoiceStatus" :key="item.value">{{
                  item.label
                }}</i-option>
              </i-select>
            </div>
          </div>
          <button class="btn">查询</button>
          <i-table border :columns="columns3" style="margin-top:20px"></i-table>
        </div>
      </div>
      <div class="show" v-show="show">
        <div class="textdives">
          <div class="p">开票规则</div>
          <div class="linese"></div>
          <div class="textruls">
            <p>
              1.如您具有专票资质，可选择开具增值税专用纸质发票或增值税普通电子发票；如您没有专票资质，根据国家相关政策法规规定，货拉拉将为您开具增值税普通电子发票
            </p>
            <p>
              2.如您选择开具电子普票，将在您申请发票3个工作日内将电子发票和电子行程单推送至您的邮箱
            </p>

            <p>
              3.如您选择开具纸质专票，申请开票后，将于5个工作日内为您安排发票打印并寄出，如您急需发票，我们强烈建议您选择开具电子发票
            </p>

            <p>4.行程中消费的各种形式的折扣、券金额及赠送金额不支持开票</p>

            <p>
              5.开具专票满1000及以上为您包邮，未满1000运费将由您自行承担，10元快递费将从账户余额中自动扣除
            </p>
            <p>
              6.为响应国家税务总局关于推行电子发票的号召，建议您选择开具电子发票，电子发票仅可开具普票
            </p>
            <p>
              7.因您个人填写快递信息有误等原因产生的发票退票及重寄的往来费用，需由您本人自行承担
            </p>
            <p>
              8.如果您选择开具纸质专票，请在快递显示签收时及时确认快递是否收到。如未收到，请在纸质发票快递签收后24小时内联系货拉拉客服，如快递公司确认快递丢失，货拉拉可为您重开。如超过快递签收24小时后联系货拉拉客服，快递公司确认丢件，货拉拉可为您提供原始发票记账联复印件加盖发票章作为您的报销依据
            </p>
            <p>9.货拉拉客服电话4008783636</p>
          </div>
          <div class="bottomdiv">
            <button class="button" @click="outs">我知道了</button>
          </div>
        </div>
      </div>
      <div class="show" v-show="shows">
        <div class="invoicediv">
          <div class="p">增值税普通电子发票与增值税专用纸质发票的区别？</div>
          <div class="linese"></div>
          <div class="textruls">
            <p>
              1.适用对象不同：一般纳税人建议按自身需求选择开具增值税专用发票或增值税电子普票，小规模纳税人建议选择增值税电子普票。增值税专用发票可用于抵扣增值税但仅限于一般纳税人认证抵税；
            </p>
            <p>
              2.承载形式不同：电子普票以邮件形式发送PDF发票文件至邮箱，比较便捷；增值税专票发票需要机打的纸质发票，需快递邮寄，接收时间较长。
            </p>
            <p>
              注：一般纳税人是税务登记为一般纳税人的企业，一般纳税人可以申请增值税专用发票，并用于抵扣增值税。
            </p>
          </div>
          <div class="bottomdiv">
            <button class="button" @click="invoicedivOut">我知道了</button>
          </div>
        </div>
      </div>
        <div class="show" v-show="showinfo">
        <div class="invoicedivinfo">
         <p>暂时未开通</p>
         <button @click="outinfo">知道了</button>
      </div>
    </div>
  </div>
  </div>
</template>

<script>


export default {
  name: "ManageCenter",
  data() {
    return {
      showinfo:true,
      showBillingbtn:false,
      showSpecial:false,
      money:10000,
      total:1,
      active: "A",
      block: "A",
      nowIndex: 0,
      show: false,
      shows: false,

      invoiceType: [
        {
          value: "beijing",
          label: "全部"
        },
        {
          value: "shanghai",
          label: "增值税普通电子发票"
        },
        {
          value: "shanghai",
          label: "增值税专用纸质电子发票"
        }
      ],
      invoiceStatus: [
        {
          value: "beijing",
          label: "全部"
        },
        {
          value: "shanghai",
          label: "已处理"
        },
        {
          value: "shanghai",
          label: "已开票"
        },
        {
          value: "shanghai",
          label: "开票失败"
        }
      ],

      columns4: [
        {
          type: "selection",
          width: 60,
          align: "center"
        },
        {
          title: "订单号",
          key: "name"
        },
        {
          title: "用车时间",
          key: "age"
        },
        {
          title: "车型",
          key: "address"
        },
        {
          title: "起始路线",
          key: "name"
        },
        {
          title: "业务类型",
          key: "age"
        },
        {
          title: "用车原由",
          key: "address"
        },
        {
          title: "订单金额(元)",
          key: "name"
        },
        {
          title: "开票金额(元)",
          key: "age"
        },
        {
          title: "员工信息",
          key: "address"
        },
        {
          title: "部门",
          key: "address"
        }
      ],
      columns3: [
        {
          title: "发票单号",
          key: "name"
        },
        {
          title: "快递单号",
          key: "age"
        },
        {
          title: "快递公司",
          key: "address"
        },
        {
          title: "申请日期",
          key: "name"
        },
        {
          title: "申请人",
          key: "age"
        },
        {
          title: "发票抬头",
          key: "address"
        },
        {
          title: "开票金额(元)",
          key: "name"
        },
        {
          title: "发票类型",
          key: "age"
        },
        {
          title: "发票种类",
          key: "address"
        },
        {
          title: "状态",
          key: "address"
        },
        {
          title: "操作",
          key: "address"
        }
      ]
    };
  },
   created(){
    if(this.total>0){
         this.showBillingbtn=!this.showBillingbtn
    }
    if(this.money>3000){
      this.showSpecial=!this.showSpecial
    }
   },
  methods: {
    outinfo(){
      this.showinfo=!this.showinfo
      this.$router.push({ path: "/manage/center" });
    },
    func(m) {
      this.active = m;
      this.block = m;
    },
    toggleTabs(index) {
      this.nowIndex = index;
    },
    rechargeRecord() {
      this.show = !this.show;
    },
    outs() {
      this.show = !this.show;
    },
    invoicedivOut() {
      this.shows = !this.shows;
    },
    invoice() {
      this.shows = !this.shows;
      // console.log(1111111)
    },
    invoicing() {
      this.$router.push({ path: "/manage/special/special" });
    },
    special(){
   this.$router.push({ path: "/manage/ordinary/ordinary" });
    }
  }
};
</script>

<style scoped>
@import "../../../style/common.css";
.management-center {
  width: 1000px;
  height: 1200px;
  margin: 100px auto;
  position: relative;
}
.center-box {
  width: 100%;
  height: 660px;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}
.p-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
}
.management-centerline {
  height: 18px;
  width: 3px;
  background-color: #ff5400;
  margin-right: 10px;
}
.management-container {
  height: 604px;
  width: 998px;
  border: 1px solid gainsboro;
}
.management-part {
  display: flex;
  justify-content: flex-start;
  margin-top: 14px;
  cursor: pointer;

}
.management-part div {
  display: inline-block;
  width: 150px;
  height: 34px;
  line-height: 34px;
  background-color: hsla(0, 0%, 100%, 0.9);
  border-radius: 2px;
  border: 1px solid #f16622;
  color: #f16622;
  text-align: center;
  cursor: pointer;
  margin-top: 10px;
}
.management-partdiv {
  margin-left: 370px;
  margin-right: 30px;
}

.management-part span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 146px;
  height: 56px;
  border: 1px solid #d8dde9;
  margin-left: 0;
  text-align: center;
  line-height: 146cpx;
  color: #999;
  background-color: #f3f4f5;
  border-bottom: 1px solid #d8dde9;
  z-index: 100;
  font-size: 16px;
}

.management-part .active {
  background-color: white;
  color: #424456;
  border-bottom: none;
}

.block {
  width: 998px;
  min-height: 604px;
  border: 1px solid #ddd;
  position: absolute;
  top: 146px;
  display: flex;
  justify-content: flex-start;
  color: #151515;
  flex-direction: column;
}

.recharge-rules {
  display: flex;

  align-items: center;
  position: absolute;
    right: 45px;
    top: 118px;
  color: #2fa0ed;
  cursor: pointer;
}
.recharge-rules img {
  width: 16px;
  height: 14px;
}
.input {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  margin-top: 30px;
}
.name {
  width: 132px;
  height: 57px;
  display: flex;
  flex-direction: column;
  margin-left: 18px;
}
.name p {
  text-align: left;
  margin-bottom: 5px;
  font-size: 12px;
  color: #666;
}

.tel {
  width: 132px;
  height: 57px;
  margin-right: 50px;
  margin-left: 18px;
}
.tel p {
  text-align: left;
  margin-bottom: 5px;
  font-size: 12px;
  color: #666;
}
.carbm {
  width: 180px;
  height: 57px;
  margin-left: 18px;
}
.carbm p {
  text-align: left;
  margin-bottom: 5px;
  font-size: 12px;
  color: #666;
}
.carbm select {
  width: 180x;
  height: 34px;
  outline: none;
  border: 1px solid #ddd;
}
.cartime {
  width: 343px;
  height: 57px;
  margin-left: 18px;
}
.cartime p {
  text-align: left;
  margin-bottom: 5px;
  font-size: 12px;
  color: #666;
}
.inputs {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  margin-top: 10px;
}
.btn {
  width: 98px;
  height: 34px;
  background: #f16622;
  border: 1px solid #f16622;
  border-radius: 2px;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  margin-top: 30px;
  margin-left: 18px;
}
.line {
  width: 95%;
  height: 1px;
  background-color: #ddd;
  margin: 30px auto;
}
.bottom-part {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 30px;
  cursor: pointer;
}
.bottom-part span {
  margin-left: 18px;
}
.labeldiv {
  width: 100%;
}
.text {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  position: relative;
}
.textdiv {
  width: 148px;
  height: 32px;
  text-align: center;
  border-radius: 2px;
  font-size: 14px;
  cursor: pointer;
  line-height: 32px;
  margin-left: 20px;
  color: #f16622;
  background: #fcf4f0;
  border: 1px solid #f16622;
}
.textsdiv {
  position: absolute;
  left: 636px;
  color: #2fa0ed;
}
.textsdivs {
  margin-left: 230px;
  color: #2fa0ed;
}
.lastdiv {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}
.tip {
  position: absolute;
  left: 0;
  margin-top: 20px;
}
.invoice-brief {
  width: 938px;
  height: 115px;
  border: 1px solid #ddd;
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.lines {
  height: 70px;
  width: 1px;
  background-color: #ddd;
}
.divp {
  margin-left: 50px;
  margin-right: 50px;
}
.divps {
  margin-left: 50px;
  margin-right: 50px;
}

.billingRecord {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.textPart {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  margin-left: 18px;
  justify-content: space-between;
}
.show {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 100000;
}
.textdives {
  width: 650px;
  height: 410px;
  background-color: white;
  position: absolute;
  top: 100px;
  left: 400px;
}
.textdives .p {
  display: flex;
  justify-content: flex-start;
  margin-left: 18px;
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
}
.textruls {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.textruls p {
  text-align: left;
  margin-left: 18px;
  margin-right: 18px;
}
.textruls span {
  color: #ff5400;
}
.bottomdiv {
  width: 100%;
  height: 55px;
  border-top: 1px solid #ddd;
  margin-top: 5px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.button {
  width: 98px;
  height: 34px;
  background: #f16622;
  border: 1px solid #f16622;
  border-radius: 2px;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  margin-right: 18px;
}
.linese {
  width: 100%;
  height: 1px;
  background-color: #ddd;
  margin-top: 10px;
}
.invoicediv {
  width: 600px;
  height: 260px;
  background-color: white;
  position: absolute;
  top: 100px;
  left: 400px;
}
.invoicediv .p {
  font-weight: bolder;
  font-size: 14px;
  text-align: left;
  margin-left: 18px;
  margin-right: 18px;
  margin-top: 5px;
}
.invoicedivinfo p {
  font-weight: bolder;
  font-size: 14px;
  text-align: left;
text-align: center;
  margin-top: 15px;
}
.invoicedivinfo{
   width: 300px;
  height: 100px;
  background-color: white;
  position: absolute;
  top: 260px;
  left: 500px;
}
.invoicedivinfo button{
  width: 98px;
  height: 34px;
  background: #f16622;
  border: 1px solid #f16622;
  border-radius: 2px;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  margin-top: 20px;
  outline: none;
}
</style>
