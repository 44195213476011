
<template>
  <div class="management-center">
    <div class="center-box">
      <div class="p-box">
        <div class="management-centerline"></div>
        <p>充值管理</p>
      </div>
      <div class="management-part">
        <span @click="func('A')" v-bind:class="{ active: active == 'A' }"
          >在线充值</span
        >
        <span @click="func('B')" v-bind:class="{ active: active == 'B' }"
          >对公汇款</span
        >
        <div class="management-partdiv" @click="divtap">对公汇款查询</div>
        <div @click="divtaps">充值记录</div>
      </div>
      <div class="block block1" v-if="block == 'A'">
        <p
          v-show="showname"
          style="font-size: 16px; width: 300px; height: 30px; margin-left: 65px"
        >
          公司名称
          <span style="color: #999; cursor: pointer; margin-left: 33px">{{
            companyName
          }}</span>
        </p>
        <p
          v-show="showauth"
          style="
            font-size: 16px;
            width: 280px;
            height: 30px;
            color: #999;
            cursor: pointer;
          "
          @click="authentication"
        >
          您现在未认证，可以点击去<span style="color: #ff5400">认证</span>！
        </p>
        <div class="recharge-rules" @click="rechargeRecord">
          <img src="../../../assets/icon.jpg" alt />
          充值规则
        </div>
        <div class="recharge-box">
          <span class="rechargetext">充值金额</span>
          <div class="bigRecharge">
            <div class="rechargeBox">
              <div
                class="rechargeNumber"
                :class="{ act: rechargeNumbers == payindex }"
                v-for="(item, payindex) in coupon"
                :key="payindex"
                @click="payclick(payindex, $event)"
                :data-item="item.recharge_amount"
                :data-coupon="item.coupon_description"
                :data-text="item.coupon_amount"
              >
                <span
                  style="font-size: 20px"
                  :data-text="item.coupon_amount"
                  :data-item="item.recharge_amount"
                  :data-coupon="item.coupon_description"
                  >{{ item.recharge_amount }}</span
                >
                <span
                  style="font-size: 14px"
                  :data-text="item.coupon_amount"
                  :data-item="item.recharge_amount"
                  :data-coupon="item.coupon_description"
                  >送{{ item.coupon_amount }}元优惠券</span
                >
              </div>
            </div>
            <div class="rechargeInput">
              <input
                type="number"
                :class="{ act: rechargeNumbers == 99 }"
                placeholder="其他金额"
                @focus="rechargeNumbers=99"
                @change="numberChange"
                v-model="otherNumber"
                style="padding-left: 10px"
              />
              <span>元</span>
            </div>
            <div class="coupon">
              <p>充值优惠</p>
              <span> 赠送{{ couponText }}元优惠券</span>
            </div>

            <div class="coupontext">{{ freeCoupons }}</div>

            <div class="payBox">
              <span>支付方式</span>
              <div class="payimg">
                <div
                  v-for="(item, index) in payWay"
                  :key="index"
                  class="pay"
                  :class="{ actpay: index == payWays }"
                  @click="btn1data(index)"
                >
                  <img :src="item.url" alt="" />
                  {{ item.payWay }}
                </div>
              </div>
            </div>
            <div class="agreementBox">
              <Checkbox
                v-model="single"
                style="position: absolute; left: 165px; margin-top: 10px"
              >
                我已阅读并同意
              </Checkbox>
               <span @click="infos" style="margin-left:115px;margin-top:8px;color:#ff5400">《充值协议》</span>
            </div>
            <div class="payBtn" @click="payBtn">
              充值(实际到账{{ paynumber }}元)
            </div>
          </div>
        </div>
      </div>
      <div class="block block1" v-if="block == 'B'">
        <div class="remittance" v-show="remittance">
          <img class="remittance-div" src="../../../assets/namelogo.png" />
          <span>请填入您企业的名称</span>
          <div class="divinput">
            <input
              type="text"
              placeholder="请先填写您企业的名称"
              v-model="remittancevalue"
            />
          </div>
          <button class="btn" @click="btn">保存</button>
        </div>
        <div class="public" v-show="publicbox">
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              margin-bottom: 30px;
            "
          >
            <div class="publicline"></div>
            <p>汇款流程</p>
          </div>
          <div class="process">
            <img
              src="../../../assets/bz.png"
              style="
                width: 30px;
                height: 550px;
                margin-top: 88px;
                margin-left: 40px;
              "
            />
            <div class="leftbox">
              <div class="leftboxtext">
                <span style="font-size: 12px; margin-top: 10px"
                  >预留汇款银行卡户名</span
                >
                <span style="font-size: 12px"
                  >汇款时使用的银行卡户名与下方任意名称一致方可入账，最多绑定5个银行卡户名。
                  <span style="color: #2fa0ed"
                    >如何修改默认银行卡户名?</span
                  ></span
                >
                <div class="lasttextbox">
                  <div class="inputbox">{{ text }}</div>
                  <!-- <div class="inputbox">+添加其他银行卡</div> -->
                  <span
                    style="
                      background-color: #f16622;
                      position: absolute;
                      top: -9px;
                      left: 10px;
                      height: 17px;
                      line-height: 17px;
                      padding: 0 11px;
                      border-radius: 4px;
                      font-size: 12px;
                      color: #fff;
                    "
                    >默认银行户名</span
                  >
                  <!-- <Tooltip
                    content="您可在汇款后补录银行卡户名，汇款后点击左侧“添加银行卡户名"
                    style="font-size:12px;color:#2fa0ed;margin-left:10px;margin-top:8px"
                  >
                    先汇款再补录银行卡户名？
                  </Tooltip> -->
                </div>
              </div>
              <div class="leftboxtext" style="margin-top: 10px; height: 180px">
                <span style="font-size: 12px; margin-top: 10px"
                  >汇款至您的专属汇款账号</span
                >
                <span style="font-size: 12px"
                  >{{ text }} 专属汇款账号信息：
                  <span style="color: #2fa0ed"
                    >如何修改默认银行卡户名?</span
                  ></span
                >
                <div style="margin-top: 6px">
                  <table
                    border="1"
                    style="
                      border-collapse: collapse;
                      border: 1px solid #e5e5e5;
                      font-size: 14px;
                      color: #757575;
                    "
                  >
                    <tr style="height: 30px">
                      <td style="width: 100px; text-align: center">收款户名</td>
                      <td style="width: 400px; text-align: center">
                        深圳市壹路通科技有限公司
                      </td>
                    </tr>
                    <tr style="height: 30px">
                      <td style="width: 100px; text-align: center">收款银行</td>
                      <td style="width: 400px; text-align: center">
                        中国建设银行深圳科苑南支行
                      </td>
                    </tr>
                    <tr style="height: 30px">
                      <td style="width: 100px; text-align: center">收款账号</td>
                      <td style="width: 400px; text-align: center">
                        44250100018200000045
                      </td>
                    </tr>
                  </table>
                </div>
              </div>

              <div class="leftboxtext" style="margin-top: 60px; height: 80px">
                <span
                  style="
                    font-size: 12px;
                    margin-top: 10px;
                    color: #2fa0ed;
                    cursor: pointer;
                  "
                  @click="info"
                  >请点击此处提交汇款信息
                </span>
                <span style="font-size: 12px; margin-top: 10px"
                  >1～3个工作日后才能充值到账。</span
                >
              </div>

              <div class="leftboxtext" style="margin-top: 70px; height: 80px">
                <span style="font-size: 14px; margin-top: 10px"
                  >请耐心等待，1天内系统将自动充值到账</span
                >
                <span style="color: #666; font-size: 12px; margin-top: 10px"
                  >汇款银行卡户名、汇款信息正确的情况下1天内可到账（使用招商银行卡汇款，最快5分钟内可到账），请您耐心等待。</span
                >
                <span style="color: #666; font-size: 12px"
                  >我们将通过短信、消息中心向您反馈充值结果。</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="show" v-show="show">
        <div class="textdiv">
          <div class="p">充值规则</div>
          <div class="line"></div>
          <div class="textruls">
            <p>1.企业充值所获优惠券，系统将即时发放至其企业账户；</p>
            <p>2.优惠券有效期以企业账户实际收到优惠券之日起算；</p>

            <p>
              3.企业在下单过程中，若有小马跨境车认定的违规行为（包括但不限于下单时备注产品推销类信息、发布虚假订单或存在任何其他扰乱平台秩序的行为），小马跨境车有权从企业账户中直接扣除已使用优惠券等额款项、扣收已支付订单费用20%款项作为违约金，并对企业进行封号处理；
            </p>
            <p>
              4.进行充值则表示贵司已经同意了<span @click="infoBtn">《充值协议》</span>，充值前请仔细阅读；如您不同意该充值协议，请勿予以充值；
            </p>
            <p>5.在法律法规允许的范围内，小马跨境车对上述规则享有解释权。</p>
            <p></p>
          </div>
          <div class="bottomdiv">
            <button class="button" @click="outs">我知道了</button>
          </div>
        </div>
      </div>

       <div class="show" v-show="showinfo">
        <div class="textdivs">
          <p v-html="agreementInfo" style="text-align: left;"></p>
          <button class="info" @click="inOut">我知道了</button>
        </div>
      </div>











    </div>
    <div class="show" v-show="showing">
      <div class="divtext">
        <img src="../../../assets/out.png" alt @click="outed" />
        <div class="leftboxs">
          <div class="management-centerline"></div>
          <p>充值管理</p>
        </div>
        <div class="leftinput">
          状态
          <i-select style="width: 180px; margin-left: 10px">
            <i-option v-for="item in invoiceType" :key="item.value">{{
              item.label
            }}</i-option>
          </i-select>
          <button class="buttons">导出</button>
        </div>
      </div>
    </div>
    <div class="show" v-show="showsing">
      <div class="divtext">
        <img src="../../../assets/out.png" alt @click="outeds" />
        <div class="leftboxs">
          <div class="management-centerline"></div>
          <p>充值记录</p>
        </div>

        <!-- <Table
          border
          stripe
          ref="selection"
          :columns="tableColumns1"
          :data="data1"
          style="height:500px;"
        ></Table>
        <Page
          :total="dataCount"
          :page-size="pageSize"
          @on-change="changepage"
          show-total
          show-sizer
          show-elevator
        /> -->
        <i-table
          :content="self"
          :data="data1"
          :columns="tableColumns1"
          stripe
          style="margin-top: 40px"
          height="450"
          text-align="center"
        ></i-table>
        <!-- <div style="margin: 10px;overflow: hidden">
          <div style="float: right;">
            <Page :total="100" :current="10" @on-change="changePage" ></Page>
          </div>
        </div> -->
      </div>
    </div>
    <div class="show" v-show="showpay">
      <div class="showpay">
        <div class="paways">
          {{ pay }}
          <img
            src="../../../assets/ca.png"
            alt=""
            style="width: 15px; height: 15px; margin-right: 10px"
            @click="outpay"
          />
        </div>
        <qriously :value="qrcodes" :size="200" style="margin-top: 20px" />
        <p style="color: #151515">请使用微信扫描二维码支付</p>
      </div>
    </div>
    <div class="show" v-show="remittanceInformation">
      <div class="remittanceInformation">
        <p
          style="
            font-size: 16px;
            font-weight: 400;
            color: #666;
            text-align: left;
            padding-left: 15px;
            height: 48px;
            line-height: 48px;
            border-bottom: 1px solid #d9d9d9;
          "
        >
          汇款信息
          <img
            src="../../../assets/ca.png"
            alt=""
            style="width: 15px; height: 15px; margin-left: 400px"
            @click="outinfo"
          />
        </p>
        <div class="tip">
          汇款至通用账号1229*****0802 (15位) , 1~3个工作日后汇款充值到账
          为了节省您的等待时间，建议汇款至您的专属汇款账号
        </div>
        <div class="inputinfo">
          <p style="color: red; margin-left: 20px; font-size: 18px">*</p>
          <p style="margin-right: 10px; font-size: 14px">汇款开户行</p>
          <Input
            v-model="value1"
            placeholder="请输入汇款开户行"
            style="width: 380px;margin-left:18px"
          />
        </div>
        <div class="inputinfo">
          <p style="color: red; margin-left: 20px; font-size: 18px">*</p>
          <p style="margin-right: 10px; font-size: 14px">汇款户名</p>
          <Input
            v-model="value2"
            placeholder="请输入汇款户名"
            style="width: 380px;margin-left:30px"
          />
        </div>
        <div class="inputinfo">
          <p style="color: red; margin-left: 20px; font-size: 18px">*</p>
          <p style="margin-right: 10px; font-size: 14px">汇款汇款账号</p>
          <Input
            v-model="value3"
            placeholder="请输入汇款账号"
            style="width: 380px;"
          />
        </div>
        <div class="inputinfo">
          <p style="color: red; margin-left: 20px; font-size: 18px">*</p>
          <p style="margin-right: 10px; font-size: 14px">汇款金额</p>
          <Input
            v-model="value4"
            placeholder="请输入汇款金额"
            style="width: 180px;margin-left:30px"
          />
        </div>
        <div class="inputinfo">
          <p style="color: red; margin-left: 20px; font-size: 18px">*</p>
          <p style="margin-right: 10px; font-size: 14px">汇款日期</p>
          <DatePicker
            type="date"
            placeholder="请选择日期"
            style="width: 180px;margin-left:30px"
          ></DatePicker>
        </div>
        <div class="inputinfo">
          <p style="color: red; margin-left: 20px; font-size: 18px">*</p>
          <p style="margin-right: 10px; font-size: 14px">汇款日期</p>
          <Upload
            action="http://192.168.0.102:8000/api/recharge/upload/depositCertImg"
            :on-success="handleSuccess"
            style="margin-left:30px"
          >
            <Button icon="ios-cloud-upload-outline">上传凭证</Button>
          </Upload>
        </div>
        <p style="text-align: left; margin-left: 20px">
          1、汇款凭证的照片详细信息需清晰可见，并承诺图片内容真实有效；
        </p>
        <p style="text-align: left; margin-left: 20px">
          2、图片格式为JPEG、JPG、PNG，并且小于10M。
        </p>
        <div class="btnbox">
          <p
            style="
              background-color: #f16622;
              color: #fff;
              width: 100px;
              height: 32px;
              line-height: 32px;
              border: 1px solid #f16622;
              border-radius: 2px;
              cursor: pointer;
              margin-top: 40px;
              margin-left: 400px;
            "
            @click="submit"
          >
            提交
          </p>
        </div>
      </div>
    </div>
     <div class="show" v-show="paytip">
      <div class="showtip">
        <p>可用余额{{ balance }}</p>
        <p class="tipBtn" @click="paytipbtn">立即预约</p>
      </div>
    </div>
    <div class="show" v-show="agtip">
      <div class="showtip">
        <p>请勾选协议须知</p>
        <p class="tipBtn" @click="agbtn">立即勾选</p>
      </div>
    </div>
  </div>
</template>

<script>
import { qrcode, recharge, coupon ,agreement,result} from "@/api/user";
import storage from "store";
export default {
  name: "ManageCenter",
  data() {
    return {
      agreementInfo:'',
      agtip:false,
      showinfo:false,
      paytip:false,
      historyData: [],
      ajaxHistoryData: [],
      // 初始化信息总条数
      dataCount: 0,
      // 每页显示多少条
      pageSize: 10,
      alipay: "",
      paynumber: "",
      coupontext: "充值特惠，多充多送",
      showmode: true,
      pay: "微信支付",
      tableData: "",
      invoiceType: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 1,
          label: "人工审核中",
        },
        {
          value: 2,
          label: "充值成功",
        },
        {
          value: 3,
          label: "充值失败",
        },
      ],
      payWay: [
        { payWay: "微信支付", url: require("../../../assets/wxzf.png") },
        { payWay: "支付宝支付", url: require("../../../assets/zfbzf.png") },
      ],
      userId: "",
      qrcodes: "",
      active: "A",
      block: "A",
      single: false,
      show: false,
      showing: false,
      showsing: false,
      activeClass: true,
      rechargeNumbers: 0,
      select: false,
      selects: false,
      payWays: 0,
      showpay: false,
      remittance: true,
      remittanceInformation: false,
      publicbox: false,
      remittancevalue: "",
      data1: [
        // {
        //   id: 1,
        //   mobile: 1676732768,
        //   created_at: "20201015",
        //   pay_type: "微信",
        //   amount: 100,
        //   am: 100,
        //   g:0
        // }
      ],
      data2: [],
      tableColumns1: [
        {
          title: "充值序号",
          key: "order_trade_no",
          align: "center",
        },
        {
          title: "充值状态",
          key: "status",
          align: "center",
        },
        {
          title: "充值时间",
          key: "created_at",
          align: "center",
        },
        {
          title: "充值方式",
          key: "pay_type",
          align: "center",
        },
        {
          title: "充值金额",
          key: "amount",
          align: "center",
        },
      ],
      value1: "",
      value2: "",
      value3: "",
      value4: "",
      url: "",
      types: 1,
      text: "",
      coupon: "",
      freeCoupons: "",
      couponText: "",
      payIndex: 0,
      payIp: "",
      verify: "",
      otherNumber: "",
      companyName: "",
      showauth: true,
      showname: false,
      recharge_id:'',
      timeD:''
    };
  },
  methods: {
    agbtn(){
      this.agtip=!this.agtip
      this.single=!this.single
    },
    inOut(){
      this.showinfo=!this.showinfo
    },
    infoBtn(){
          this.showinfo=!this.showinfo
          this.show=!this.show
    },
    paytipbtn(){
     this.paytip=!this.paytip
     this.$router.push({
                path: '/manage/center'
            });

    },
    // bourInput(){
    //   this.otherNumber=''
    // },
    handleListApproveHistory() {},
    func: function (c) {
      this.active = c;
      this.block = c;
    },
    divtap: function () {
      this.showing = !this.showing;
    },
    infos(){
      this.showinfo=!this.showinfo
      this.single=!this.single
    },
    divtaps: function () {
      // // 保存取到的所有数据
      //         this.ajaxHistoryData = this.data1
      //         this.dataCount = this.data1.length;
      //         // 初始化显示，小于每页显示条数，全显，大于每页显示条数，取前每页条数显示
      //         if(this.data1.length < this.pageSize){
      //             this.historyData = this.ajaxHistoryData;
      //         }else{
      //             this.historyData = this.ajaxHistoryData.slice(0,this.pageSize);
      //         }
      this.showsing = !this.showsing;
    },
    //  changepage(index){
    //           var _start = ( index - 1 ) * this.pageSize;
    //           var _end = index * this.pageSize;
    //           this.historyData = this.ajaxHistoryData.slice(_start,_end);
    //       },

    rechargeRecord: function () {
      this.show = !this.show;
    },
    imgclick: function () {},
    out: function () {
      console.log(11111);
    },
    outs: function () {
      this.show = !this.show;
    },
    outed: function () {
      this.showing = !this.showing;
    },
    outeds: function () {
      this.showsing = !this.showsing;
    },
    payclick(payindex, event) {
      this.otherNumber = "";
      this.paynumber = event.target.dataset.item;
      //console.log(event.target.dataset.item);
      //console.log(event.target.dataset.text);
      this.freeCoupons = event.target.dataset.coupon;
      this.couponText = event.target.dataset.text;
      this.rechargeNumbers = payindex;
      //console.log(this.rechargeNumbers);
    },
    btn1data(index) {
      console.log(index);
      this.payIndex = index;
      this.payWays = index;
    },
   
    payBtn() {
     if(!this.single){
       this.agtip=!this.agtip
     }else{
       if (this.payIndex == 0) {
        let that = this;
        qrcode({
          amount: that.paynumber,
          user_id: that.userId,
          type: 1,
          amount_of_donation: that.couponText,
        }).then((res) => {
          console.log(res.data);
          that.qrcodes = res.data.code_url;
          that.recharge_id=res.data.recharge_id;
          that.timeD=window.setInterval(function () {
            result({
              id: that.recharge_id,
            }).then((res) => {
              console.log(typeof(res.data.status) );
              if(res.data.status==2){
                console.log(res.data.balance)
                 that.showpay = !that.showpay;
                 that.paytip=!that.paytip
                 that.balance=res.data.balance
                 
                   storage.set('balance', res.data.balance)
           clearInterval(that.timeD)
          
              }

            });
          }, 5000);
        });
        this.showpay = !this.showpay;
      } else {
        qrcode({
          amount: this.paynumber,
          user_id: this.userId,
          type: 2,
          amount_of_donation: this.couponText,
        }).then((res) => {
        window.open(res.data.link)
        });
        
      }
     }
    },
    //  changePage () {
    //             // 这里直接更改了模拟的数据，真实使用场景应该从服务端获取数据
    //             this.data1 = this.data2;
    //         }
    outinfo() {
      this.remittanceInformation = !this.remittanceInformation;
    },
    info() {
      this.remittanceInformation = !this.remittanceInformation;
    },
     outpay() {
      this.showpay = !this.showpay;
           clearInterval(this.timeD)



    },
    submit() {
      if (this.value1 == "") {
        alert("请输入");
      } else {
        qrcode({
          amount: this.value4,
          user_id: this.userId,
          type: 3,
          bank_of_deposit: this.value1,
          account: this.value2,
          card_number: this.value3,
          img: this.url,
        }).then((res) => {
          console.log(res.data);
        });
      }
    },
    handleSuccess() {
      this.url = JSON.parse(event.currentTarget.response).code.url;
    },
    btn() {
      this.remittance = false;
      this.publicbox = true;
      this.text = this.remittancevalue;
    },
    numberChange() {
      //console.log(this.otherNumber);
      this.paynumber = this.otherNumber;

      if (this.otherNumber >= 0 && this.otherNumber < 3000) {
        this.couponText = 0;
        this.freeCoupons = "";
      }else if (this.otherNumber >= 3000 && this.otherNumber < 5000) {
        this.couponText = 300;
        this.freeCoupons = "券包含30元立减券x10张";
      } else if (this.otherNumber >= 5000 && this.otherNumber < 8000) {
        this.couponText = 600;
        this.freeCoupons = "券包含50元立减券x10张,10元立减券x10张";
      } else if (this.otherNumber >= 8000 && this.otherNumber < 50000) {
        this.couponText = 900;
        this.freeCoupons = "券包含50元立减券x10张,30元立减券x10张,10元立减券x10张";
      } else if (this.otherNumber >= 50000 && this.otherNumber < 100000) {
        this.couponText = 3000;
        this.freeCoupons = "券包含50元立减券x30张,30元立减券x50张";
      } else if (this.otherNumber > 100000) {
        // alert('5000优惠券');
        this.couponText = 5000;
        this.freeCoupons = "券包含30元立减券x50张,10元立减券x50张,100元立减券x30张";
      }
    },
    authentication() {
      this.$router.replace("/manage/verify/submit");
    },
    
  },
  created() {
    this.verify = storage.get("user").is_verify;
    this.userId = storage.get("user").id;
    this.companyName = storage.get("user").name;
    console.log(storage.get("user"));
    if (this.companyName != null) {
      this.showname = true;
      this.showauth = false;
    }
    if (this.verify == 1) {
      this.remittance = true;
      this.publicbox = false;
    }
    const params = {
      user_id: this.userId,
      type: 1,
      status: 1,
    };
    recharge(params).then((res) => {
      console.log(res.data.data);
      this.data1 = res.data.data;
      console.log(this.data1.length);
    });
    agreement({type:1}).then((res) => {
      console.log("<,,,,,,,,,,,,,")
      console.log(res.data[0].content);
      this.agreementInfo=res.data[0].content
    });
  },
  mounted() {
    coupon().then((res) => {
      this.coupon = res.data;
      for (let i in this.coupon) {
        console.log(i, this.coupon[i].coupon_amount);
        this.couponText = this.coupon[0].coupon_amount;
        this.freeCoupons = this.coupon[0].coupon_description;
        this.paynumber = this.coupon[0].recharge_amount;
        console.log(this.couponText);
      }
    });
  },
  beforeDestroy(){
     clearInterval(this.timeD)
  }
};
</script>

<style scoped>
@import "../../../style/common.css";
.management-center {
  width: 1000px;
  height: 900px;
  margin: 100px auto;
  position: relative;
}
.ivu-table td /deep/.ivu-table th {
  text-align: center;
}
.center-box {
  width: 100%;
  height: 660px;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}
.p-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
}
.management-centerline {
  height: 18px;
  width: 3px;
  background-color: #ff5400;
  margin-right: 10px;
}
.publicline {
  height: 18px;
  width: 3px;
  background-color: #ff5400;
  margin-right: 10px;
  position: absolute;
  top: 44px;
  left: 20px;
}
.management-container {
  height: 604px;
  width: 998px;
  border: 1px solid gainsboro;
}
.management-part {
  display: flex;
  justify-content: flex-start;
  margin-top: 14px;
  cursor: pointer;
}
.management-part div {
  display: inline-block;
  width: 150px;
  height: 34px;
  line-height: 34px;
  background-color: hsla(0, 0%, 100%, 0.9);
  border-radius: 2px;
  border: 1px solid #f16622;
  color: #f16622;
  text-align: center;
  cursor: pointer;
  margin-top: 10px;
}
.management-partdiv {
  margin-left: 370px;
  margin-right: 30px;
}

.management-part span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 146px;
  height: 56px;
  border: 1px solid #d8dde9;
  margin-left: 0;
  text-align: center;
  line-height: 146cpx;
  color: #999;
  background-color: #f3f4f5;
  border-bottom: 1px solid #d8dde9;
  z-index: 100;
  font-size: 16px;
}

.management-part .active {
  background-color: white;
  color: #424456;
  border-bottom: none;
}

.block {
  width: 998px;
  min-height: 850px;
  border: 1px solid #ddd;
  position: absolute;
  top: 146px;
  display: flex;
  justify-content: flex-start;
  color: #151515;
  flex-direction: column;
}
.block p {
  color: #151515;
  margin-top: 40px;
  margin-left: 20px;
  width: 92px;
  height: 30px;
}

.block .recharge-box {
  display: flex;
  flex-direction: row;
}
.block .recharge-box .rechargetext {
  margin-left: 56px;
  font-size: 16px;
  width: 92px;
  height: 30px;
  margin-top: 50px;
}
.recharge {
  width: 500px;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.bigRecharge {
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 500px;
  margin-top: 50px;
}
.bigRecharge input {
  width: 228px;
  height: 33px;
  outline: none;
  border: 1px solid #e5e5e5;
  margin-left: 20px;
}
.rechargeInput {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.rechargeInput span {
  color: #333;
  margin-left: 10px;
}
.rechargeP {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.rechargeP .textTip {
  width: 436px;
  color: #f16622;
  background: #fffaeb;
  height: 23px;
  margin-top: 30px;
  text-align: left;
}
.rechargeP .textTips {
  width: 436px;
  height: 23px;
  text-align: left;
  margin-top: 10px;
}
.textbox {
  display: flex;
  flex-direction: row;
}
.payBox {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}
.payBox span {
  position: absolute;
  left: 53px;
  font-size: 16px;
  width: 92px;
  height: 30px;
  margin-top: 15px;
}
.payimg {
  display: flex;
  flex-direction: row;
  margin-left: 19px;
}
.payimg img {
  width: 148px;
  height: 48px;
  margin-top: 20px;
  margin-right: 20px;
}
.agreementBox span {
  color: #f16622;
}
.agreementBox{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.payBtn {
  width: 218px;
  height: 46px;
  line-height: 46px;
  border-radius: 2px;
  margin-left: 108px;
  text-align: center;
  background-color: #f16622;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  margin-top: 40px;
  margin-left: 17px;
}

.recharge-rules {
  position: absolute;
  right: 10px;
  font-size: 13px;
  margin-top: 30px;
  color: #2fa0ed;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.remittance {
  width: 998px;
  min-height: 604px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* background-color: black; */
}
.remittance-div {
  width: 72px;
  height: 72px;
}
.recharge-rules img {
  width: 16px;
  height: 14px;
  margin-right: 3px;
}
.remittance span {
  margin-top: 10px;
  font-size: 14px;
}
.divinput {
  width: 367px;
  height: 40px;
  border: 1px solid #e5e5e5;
  margin-top: 10px;
}
.divinput input {
  border: none;
  outline: none;
  height: 35px;
  width: 340px;
}
.btn {
  margin: 22px auto 139px;
  width: 162px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  color: #fff;
  background: #f16622;
  border-radius: 2px;
  border: 1px solid #f16622;
  cursor: pointer;
}
.show {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 100000;
}
.showpay {
  width: 350px;
  height: 350px;
  background-color: #ffff;
  position: absolute;
  left: 500px;
  top: 200px;
  z-index: 100000;
}
.textdivs{
   width: 1300px;
  height: 600px;
  background-color: white;
  position: absolute;
  top: 20px;
  left: 25px;
  overflow-y: scroll;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 12px;
}
.textdiv {
  width: 650px;
  height: 250px;
  background-color: white;
  position: absolute;
  left:50%;
  top:50%; 
  margin-left:-325px;
  margin-top:-125px;
}
.textdiv .p {
  display: flex;
  justify-content: flex-start;
  margin-left: 18px;
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
}
.textruls {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.textruls p {
  text-align: left;
  margin-left: 18px;
  margin-right: 18px;
}
.textruls span {
  color: #ff5400;
}
.bottomdiv {
  width: 100%;
  height: 55px;
  border-top: 1px solid #ddd;
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.button {
  width: 98px;
  height: 34px;
  background: #f16622;
  border: 1px solid #f16622;
  border-radius: 2px;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  margin-right: 18px;
}
.show {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 100000;
}
.divtext {
  width: 1150px;
  height: 630px;
  background-color: white;
  position: fixed;
  right: 0;
  top: 64px;
}
.divtext img {
  position: absolute;
  left: -31px;
  top: 20px;
}
.rechargeBox {
  width: 100%;
  height: 500px;
  display: flex;
  flex-wrap: wrap;
}
.rechargeNumber {
  width: 134px;
  height: 84px;
  border: 1px solid #e5e5e5;
  margin-left: 18px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: 15px;
  cursor: pointer;
  color: #000;
  border-radius: 2px;
}
.act {
  width: 134px;
  height: 84px;
  margin-left: 18px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: 15px;
  cursor: pointer;
  background-color: rgba(241, 102, 34, 0.04);
  border-radius: 2px;
  border: 1px solid #f16622;
  color: #f16622;
}
.leftboxs {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  margin-left: 18px;
  font-size: 18px;
  align-items: center;
}
.leftinput {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  margin-left: 25px;
  color: #757575;
  font-size: 14px;
  align-items: center;
}
.buttons {
  width: 98px;
  height: 34px;
  background: #f16622;
  border: 1px solid #f16622;
  border-radius: 2px;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  margin-left: 780px;
}
.info{
  width: 98px;
  height: 34px;
  background: #f16622;
  border: 1px solid #f16622;
  border-radius: 2px;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  margin-left: 1100px;
  outline: none;
  margin-bottom: 10px;
}
.pay {
  width: 148px;
  height: 48px;
  line-height: 46px;
  margin-right: 12px;
  border: 1px solid #e5e5e5;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #757575;
}
.actpay {
  width: 148px;
  height: 48px;
  line-height: 46px;
  margin-right: 12px;
  border: 1px solid #f16622;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #757575;
}
.on-choose {
  width: 148px;
  height: 48px;
  line-height: 46px;
  margin-right: 12px;
  border: 1px solid #f16622;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #757575;
}
.on-chooses {
  width: 148px;
  height: 48px;
  line-height: 46px;
  margin-right: 12px;
  border: 1px solid #f16622;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #757575;
}
.pay img {
  width: 28px;
  height: 28px;
  margin-left: 10px;
  margin-bottom: 18px;
}
.paways {
  width: 100%;
  height: 48px;
  border-bottom: 1px solid #d9d9d9;

  padding-left: 10px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.public {
  width: 100%;
  height: 500px;
}

.process {
  width: 100%;
  height: 700px;
  display: flex;
  flex-direction: row;
}
.leftbox {
  width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.leftboxtext {
  width: 900px;
  height: 150px;
  background-color: #f8f8f8;

  display: flex;
  flex-direction: column;
  text-align: left;
  padding-left: 15px;
}
.lasttextbox {
  width: 850px;
  height: 50px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  text-align: left;
  position: relative;
}
.inputbox {
  height: 34px;
  width: 210px;
  background-color: white;
  margin-left: 30px;
  text-align: center;
  line-height: 34px;
  color: #666;
  font-size: 12px;
}
.remittanceInformation {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 520px;
  height: 660px;
  margin-left: -260px;
  margin-top: -330px;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.tip {
  width: 470px;
  height: 54px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 6px 12px;
  background-color: #ecf6fd;
  border-radius: 4px;
  border: 1px solid #add8f7;
  line-height: 20px;
  color: #2fa0ed;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-top: 20px;
}
.inputinfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}
.btnbox {
  width: 100%;
  height: 80px;
  border-top: 1px solid #d9d9d9;
  margin-top: 50px;
}
.coupon {
  width: 100%;
  height: 50px;
  width: 436px;
  color: #f16622;
  background: #fffaeb;
  position: relative;
  margin-top: 10px;
  margin-left: 20px;
}
.coupon p {
  position: absolute;
  left: -133px;
  top: -39px;
  font-size: 16px;
}
.coupon span {
  position: absolute;
  left: 13px;
  top: 4px;
}
.coupontext {
  width: 300px;
  height: 50px;
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
  color: #999;
}
.show {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 100000;
}
.showtip {
  text-align: center;
  width: 240px;
  height: 170px;
  background-color: #ffff;
  position: absolute;
  left: 550px;
  top: 300px;
  z-index: 100000;
  border-radius: 4px;
}

.showtip p {
  margin-top: 20px;
  font-size: 18px;
}

.showtip .tipBtn {
  margin: 52px auto 139px;
  width: 100px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  color: #fff;
  background: #f16622;
  border-radius: 2px;
  border: 1px solid #f16622;
  cursor: pointer;
}
</style>
