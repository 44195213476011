<template>
  <div class="management-center">
    <div class="center-box">
      <div class="p-box">
        <div class="management-centerline"></div>
        <p>客服中心</p>
      </div>
      <div class="management-part">
        <span @click="func('A')" v-bind:class="{ active: active == 'A' }"
          >联系我们</span
        >
        <span @click="func('B')" v-bind:class="{ active: active == 'B' }"
          >常见问题</span
        >
        <span @click="func('C')" v-bind:class="{ active: active == 'C' }"
          >隐私政策</span
        > 
      
      </div>
      <div class="block block1" v-if="block == 'B'">
        <div class="commonProblem">
          <div class="pbox">
         <div class="management-centerline"></div>
          <p>订单相关问题</p>
          </div>
          <Collapse active-key="1">
        <Panel key="1" style="text-align: left;">
            可以预约最近/最长什么时候的用车？
            <p slot="content">支持预约2小时后出发的用车；最长支持预约90天内出发的用车。</p>
        </Panel>
        <Panel key="2" style="text-align: left;">
            车辆最多可承载多少人？
            <p slot="content">企业用车，有7座和8座的跨境商务车，最多可承载6人或7人（不论年龄大小，包含怀抱的幼童），不允许超出法定载客人数。 个人用车，有7座跨境商务车，最多可承载6人（不论年龄大小，包含怀抱的幼童），不允许超出法定载客人数。</p>
        </Panel>
        <Panel key="3" style="text-align: left;">
            可以携带多少行李？
            <p slot="content">最多可携带4个24英寸行李箱，如超过此标准，可咨询客服人员确认。我司不接受任何形式的搬家、货运用途用车。</p>
        </Panel>
           <Panel key="4" style="text-align: left;">
           是否支持多点上/下车？
            <p slot="content">支持多点上下车，添加途经点，将加收途经点费用。目前系统只支持添加一个人途经点，如需添加多个途经点请在下单时在备注中填写需要增加的途经点，下单成功后联系紫荆拼车客服0755-86665622，确认途经点费用后再支付订单。</p>
        </Panel>
           <Panel key="5" style="text-align: left;">
           出发前司机会联系乘客吗？
            <p slot="content">出发前，司机会主动联系乘客确认出发的时间和上车地址等信息。请在下单时准确填写联系人的电话号码。 </p>
        </Panel>
           <Panel key="6" style="text-align: left;">
            如何联系司机？
            <p slot="content">a) 预订当天出行的订单，司机及车牌信息会在调度后以短信推送的形式发送至客户手机，请客户保持手机通讯的畅通；<br/>b) 预订第二天及之后的订单，我们会在出发前一天晚上18点前将司机及车牌信息以短信推送的形式发送至客户手机，请客户保持手机通讯的畅通。</p>
        </Panel>   
        <Panel key="7" style="text-align: left;">
           乘客迟到怎么办？
            <p slot="content">司机到达上车点后可免费等候30分钟，超过约定上车时间30分钟将会收取等候费用。等候费用为每小时200RMB|250HKD，从约定的上车时间开始计时，不足1小时按照1小时收取。<br/>为避免造成不必要的损失，需要更改出行时间请提前联系紫荆拼车客服0755-86665622。<br/>注：预订深圳市内（福田罗湖南山）或香港出发的用车，在预订上车时间3小时之前可免费更改上车时间；在距离预订上车时间小于3小时更改上车时间，则需收取（订单总额的50%）违约费。<br/>预订的是深圳市外出发的用车，在预订上车时间5小时之前可免费更改上车时间；在距离预订上车时间小于5小时更改上车时间，则需收取（订单总额的50%）违约费。<br/>注: a、以上时间，均以工作小时计算，工作时间为9:00-18:00。b、以上为取消及修改订单的收费标准，若已到用车时间，客户未出现，或临近用车时间，司机已到达客户用车地的取消或修改订单，均需收取全单费用。</p>
        </Panel>
          <Panel key="8" style="text-align: left;">
           乘客过关超时怎么办？
            <p slot="content">如因乘客自身原因导致过关时间超出30分钟，将收取等候费。等候费用为每小时200RMB|250HKD，从下车过关时间开始计时，不足1小时按照1小时收取。</p>
        </Panel>
        <Panel key="8" style="text-align: left;">
           取消订单如何退款？
            <p slot="content">a) 取消订单的退款规则：预订深圳市内（福田罗湖南山）或香港出发的用车，在预订上车时间3小时之前取消订单，可全额退款；距离预订上车时间小于3小时取消订单，则不退款。<br/>预订的是深圳市外出发的用车，在预订上车时间5小时之前取消订单，可全额退款；距离预订上车时间小于5小时取消订单，则不退款。<br/>b) 因行程更改取消订单的退款规则：预订深圳市内（福田罗湖南山）或香港出发的用车，在预订上车时间3小时之前取消订单，可全额退款；在距离预订上车时间小于3小时取消订单，则仅退还订单支付金额的50%。<br/>预订的是深圳市外出发的用车，在预订上车时间5小时之前取消订单，可全额退款；在距离预订上车时间小于5小时取消订单，则仅退还订单支付金额的50%。<br/>注：<br/>如遇外界不可抗因素：台风天气、地震等，无法正常用车的情况下我们将与您取得联系，取消该笔订单并在1~15个工作日内原路退回全部订车金额。<br/>以上时间，均以工作小时计算，工作时间为9:00-18:00。<br/>以上为取消及更改行程的收费标准，若已到用车时间，客户未出现，或临近用车时间，司机已到达客户用车地点，取消或更改行程，均需收取全单费用。</p>
        </Panel>
    </Collapse>
  <div class="pbox">
         <div class="management-centerline"></div>
          <p>费用相关问题</p>
          </div>
          <Collapse active-key="1">
        <Panel key="1" style="text-align: left;">
            路桥高速、停车费等额外费用由谁承担？
            <p slot="content">用车过程中产生路桥高速费、停车费等额外费用，根据实际产生的金额由客户支付。</p>
        </Panel>
        <Panel key="2" style="text-align: left;">
            基础线路价格
            <p slot="content">专车点对点接送基础线路价格参照双方已签订合同的用车协议价格。</p>
        </Panel>
        <Panel key="3" style="text-align: left;">
           举牌费
            <p slot="content">机场接机业务如客人要求举牌，每次收取50RMB举牌费。</p>
        </Panel>
           <Panel key="4" style="text-align: left;">
           途经点加点费
            <p slot="content">加点费:专车为点对点接送，同城市内客人如需增加上/下车点，顺路情况下 加收站点费(同区每个站点加收50RMB、跨区每个站点加收100RMB)，非顺 路情况下，视实际加点地点加收200RMB-800RMB元不等，具体详询客服 18188600361。</p>
        </Panel>
        <Panel key="5" style="text-align: left;">
           夜间加班费
            <p slot="content">夜间加班费:22:00-06:59期间用车需加收夜间加班费收费标准:22:00-23:59及05:00-06:59加收100RMB;00:00-04:59加收200RMB</p>
        </Panel>
        <Panel key="5" style="text-align: left;">
           夜间加班费
            <p slot="content">夜间加班费:22:00-06:59期间用车需加收夜间加班费收费标准:22:00-23:59及05:00-06:59加收100RMB;00:00-04:59加收200RMB</p>
        </Panel>
        <Panel key="5" style="text-align: left;">
           节假日加收费
            <p slot="content">节假日加收费标准元旦假期:每单加收100RMB;<br/>农历春节假期:腊月二十八至二十九每单加收200RMB、年三十至初三每单加 收400RMB、初四至初八每单加收300RMB;<br/>复活节及清明节假期: 每单加收200RMB; 五一假期: 每单加收100RMB; 端午节及中秋节假期: 每单加收100RMB; 国庆节假期: 每单加收200RMB; 圣诞节假期: 每单加收200RMB;</p>
        </Panel>
        <Panel key="5" style="text-align: left;">
           超时等候费
            <p slot="content">非接机业务以预订时间起计免费等候时间为30分钟，接机业务以 航班降落时间起计免费等候时间为60分钟;超时等候(包括客人原因造成的过 关等候)按每小时200RMB计收超时等候费，不足1小时按1小时计算;</p>
        </Panel>
      </Collapse>
 <div class="pbox">
         <div class="management-centerline"></div>
          <p>过关相关问题</p>
          </div>
          <Collapse active-key="1">
        <Panel key="1" style="text-align: left;">
            过关需携带的哪些证件?
            <p slot="content">a)香港居⺠:香港居⺠身份证和《港澳居⺠来往内地通行证》，即回乡证。<br/>b)台湾居⺠:《台湾居⺠来往大陆通行证》，即《台胞证》;或者护照但需要签证;<br/>c)中国大陆居⺠:港澳通行证或者护照和去往国家的机票;<br/>d)外籍人人员:护照及签证。</p>
        </Panel>
        <Panel key="2" style="text-align: left;">
            L签是否需要提前通过旅行社办理团签表?
            <p slot="content">持“L”签注的团队旅客，不需要再经旅行社团签过关， L签用户也可以乘坐。</p>
        </Panel>
        <Panel key="3" style="text-align: left;">
        哪些东⻄是不能带过关的?
            <p slot="content">a)对中国政治、经济、文化、道德有害的印刷品、胶卷、照片、唱片、影片、 录音带、录像带、激光视盘、计算机存储介质及其他物品禁止进境。<br/>b)各种毒品、武器、仿真武器、弹药及爆炸物品、假币等违禁物品。<br/>c)濒危野生动植物及其制品。新鲜水果、茄科蔬菜、活动物(犬、猫除外)、动物 尸体、土壤、转基因生物材料等，一经查获，海关将按规定予以没收、处罚等 相应处理。</p>
        </Panel>
           <Panel key="4" style="text-align: left;">
        携带哪些东⻄是限量的?
            <p slot="content">a)香港政府规定，离境人士所携带出境的奶粉每人不得超过1.8公斤，违例者最 高可被罚款50万港元及监禁两年。<br/>b)去香港扫货，进出境时，人⺠币现钞不能超过2万元。对于外币，进出境可携 带的额度则分3种情况:一是当天进出境超1次携带外币金额折合不超过500美 元，二是15天内进出境超一次携带外币金额折合不超过1000美元，三是非以上 情况携带外币金额折合不超过5000美元。<br/>c)香港海关还规定，旅客只能带19支香烟进香港。</p>
        </Panel>
        <Panel key="5" style="text-align: left;">
        烟酒要不要交税?
            <p slot="content">关于烟、酒的免税规定(仅限年满16周岁的旅客):香港、澳⻔地区居⺠及因私来往香港、澳⻔地区的内地居⺠，每人免税香烟200 支，或雪茄50支，或烟丝250克;免税12度以上酒精饮料限750毫升。其他旅客，每人免税香烟400支，或雪茄100支，或烟丝500克;免税12度以上 酒精饮料限1500毫升。</p>
        </Panel>
        <Panel key="5" style="text-align: left;">
        哪些是不予免税的商品?
            <p slot="content">国家规定了20种不予免税的商品:电视机、摄像机、录像机、放像机、音响设备、空调器、电冰箱(电 冰柜)、洗衣机、照相机、复印机、程控电话交换机、微型计算机及外设、电 话机、无线寻呼系统、传真机、电子计算器、打字机及文字处理机、家具、灯 具、餐料。其中，ipad被归类为微型计算机!! IPHONE和IPAD入关都是需要报税的，收10%的税。<br/>举例:从香港买一个IPAD2回国，虽然该物品只有3000多元，但属于电脑类物 品。海关会按最低基数征税，也就是5000元的20%。最后这个3000多元的 IPAD2要缴纳1000元的关税。</p>
        </Panel>
        <Panel key="5" style="text-align: left;">
        不同种类物品税率是怎样的?
            <p slot="content">不同种类物品税率都不同，按照《海关总署公告2012年第15号》规定，一般税 率在10%到50%之间。<br/>a)10%税率的物品<br/>⻝品、饮料;皮革服装及配饰;箱包及鞋靴;金银珠宝及其制品、艺术品、收 藏品;家用医疗、保健及美容器材;厨房用具及电话机、传真机、游戏机等小 家电;家具;摄影摄像机及配件;书报、刊物及其他各类印刷品;教育专用的 电影片、幻灯片、原版录音带、录像带;文具用品及玩具;邮票、乐器、体育 用品(除高尔夫);未有列名的其他物品。<br/>b)20%税率的物品<br/>纺织品及其制成品;手表(万元以下)、钟及其配件;电饭煲、榨汁机、豆浆机等 电器类厨房用品;空调、电冰箱、洗衣机、电视机、影音设备、电视摄像机设 备及其配件;自行⻋、三轮⻋、童⻋及其配件。<br/>c)30%税率的物品<br/>高档手表(人⺠币1万元及以上)、高尔夫球及球具、高尔夫用品<br/>d)50%税率物品<br/>烟、酒、化妆品</p>
        </Panel>
    </Collapse>
    <div class="pbox">
         <div class="management-centerline"></div>
          <p>售后问题</p>
          </div>
          <Collapse active-key="1">
        <Panel key="1" style="text-align: left;">
            如何申请发票？
            <p slot="content">小马跨境企业版可统一由企业账户管理员在企业版web端管理中心开具发票，并且可以查询开票进度。</p>
        </Panel>
        <Panel key="2" style="text-align: left;">
            物品遗留车上怎么办？
            <p slot="content">24小时内完成的订单，您可通过电话联系司机。您也可以致电小马跨境客服协调处理。小马跨境企业用户客服专线： 0755-86665622 。</p>
        </Panel>
        <Panel key="3" style="text-align: left;">
      如何联系客服？
            <p slot="content">小马跨境企业用户客服专线： 0755-86665622 。 </p>
        </Panel>
           <Panel key="4" style="text-align: left;">
          订单完成后如何联系司机？
            <p slot="content">24小时内完成的订单，您可通过电话联系司机。超过联系时间，请致电小马跨境企业用户客服专线： 0755-86665622 。</p>
        </Panel>
    </Collapse>
      <div class="pbox">
         <div class="management-centerline"></div>
          <p>其他问题</p>
          </div>
          <Collapse active-key="1">
        <Panel key="1" style="text-align: left;">
          如果因司机或⻋辆原因影响行程，会如何处理?
            <p slot="content">a)如司机没有按约定路线或者接送时间准时发⻋而导致乘客严重延误并且产生 损失，我们将会承担相应的赔偿责任。如果或司机在订单时间内进行除乘客以外的任何租⻋行为或与乘客行程线路无关的行驶活动，或者、擅自搭载其他人 员，我们将返还订单金额50%做为罚款会承担相应的赔偿责任。<br/>b)如遇⻋辆在途中发生故障或交通事故，临时需要调换⻋辆，会经乘客同意， 由此引起的费用，由我司承担。<br/>c)如我们提供的⻋辆在途中发生故障造成途中抛锚，并且预计在2小时内无法修 复，我们会更换相应的⻋辆，如没有备用⻋辆或时间不允许，乘客有权选择其 他交通工具，且另行选择其他交通工具所产生合理的相关费用我司承担。</p>
        </Panel>
        <Panel key="1" style="text-align: left;">
          如果⻋辆出现事故，责任和赔偿如何处理? 
            <p slot="content">如果⻋辆发生交通事故或其它安全事故，我们将会按照国家相关法律规定及⻋ 辆保险责任对乘客予以相应赔偿。</p>
        </Panel>
    </Collapse>
        </div>
      </div>
      </div>
      <div class="block block1" v-if="block == 'A'">
       
        <div style=" display: flex;flex-direction: row; text-align: left;font-size:15px;margin-top:20px;margin-left:20px">
           <p>客服电话：0755-86665622</p>
     <p style="margin-left:10px">商务合作：market@ylt100.cn</p>
     <p style="margin-left:10px">司机加入：market@ylt100.cn</p>
     <p style="margin-left:10px">地址：南山区数字技术园A3栋5楼</p>
        </div>
        <img src="../../../assets/weixin.png" alt="" style=" width: 200px;height: 200px;margin-left:100px;margin-top:10px">
       <p style=" text-align: left;font-size:15px;margin-top:20px;margin-left:20px">微信公众号搜索小马跨境车</p>
       
      </div>  
      <div class="block block1" v-if="block == 'C'">
        <p v-html="agreementInfo" style=" text-align: left;font-size:15px;margin-top:20px;margin-left:20px"></p>
      </div>
      </div>


</template>

<script>
import { agreement} from "@/api/user";
export default {
  name: "RuleService",
  data() {
    return {
      agreementInfo:'',
      active: "C",
      block: "C",
      // nowIndex: 0,
        columns1: [
                   
                    {
                        title: '订单状态',
                        key: 'name'
                    },
                    {
                        title: '订单编号',
                        key: 'age'
                    },
                    {
                        title: '订单时间',
                        key: 'address'
                    },
                     {
                        title: '路线',
                        key: 'address'
                    },
                
                     
                ],
                columns2: [
                   
                    {
                        title: '问题',
                        key: 'name'
                    },
                    {
                        title: '时间',
                        key: 'age'
                    },
                    {
                        title: '状态',
                        key: 'address'
                    },
                  
                
                     
                ],

      
    };
  },
  methods: {
    func: function(m) {
      this.active = m;
      this.block = m;
    }
  },
  created(){
    agreement({type:1}).then((res) => {
      console.log("<,,,,,,,,,,,,,")
      console.log(res.data[0].content);
      this.agreementInfo=res.data[0].content
    });
  }
};
</script>

<style scoped>
@import "../../../style/common.css";
.management-center {
  width: 1000px;
  height: 1200px;
  margin: 60px auto;
  position: relative;

}
.center-box {
  width: 100%;
  height: 660px;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}
.p-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
}
.management-centerline {
  height: 18px;
  width: 3px;
  background-color: #ff5400;
  margin-right: 10px;
}
.management-container {
  height: 1200px;
  width: 998px;
  border: 1px solid gainsboro;
}
.management-part {
  display: flex;
  justify-content: flex-start;
  margin-top: 14px;
  cursor: pointer;
}
.management-part div {
  display: inline-block;
  width: 150px;
  height: 34px;
  line-height: 34px;
  background-color: hsla(0, 0%, 100%, 0.9);
  border-radius: 2px;
  border: 1px solid #f16622;
  color: #f16622;
  text-align: center;
  cursor: pointer;
  margin-top: 10px;
}
.management-partdiv {
  margin-left: 370px;
  margin-right: 30px;
}

.management-part span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 146px;
  height: 56px;
  border: 1px solid #d8dde9;
  margin-left: 0;
  text-align: center;
  line-height: 146cpx;
  color: #999;
  background-color: #f3f4f5;
  border-bottom: 1px solid #d8dde9;
  z-index: 100;
  font-size: 16px;
}

.management-part .active {
  background-color: white;
  color: #424456;
  border-bottom: none;
}

.block {
  width: 998px;
  min-height: 1200px;
  border: 1px solid #ddd;
  position: absolute;
  top: 146px;
  display: flex;
  justify-content: flex-start;
  color: #151515;
  flex-direction: column;
}

.recharge-rules {
  display: flex;

  align-items: center;
  position: absolute;
  right: 190px;
  top: 170px;
  color: #2fa0ed;
  cursor: pointer;
}
.recharge-rules img {
  width: 16px;
  height: 14px;
}
.commonProblem{
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;


}

.pbox{
  font-size: 16px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  margin-left: 15px;
  margin-top: 10px;
  
}
</style>
