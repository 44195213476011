<template>
  <div class="management-center">
    <div class="center-part">
      <div class="textbox">国内包车</div>
      <div class="management-part">
        <span @click="func('A')" v-bind:class="{ active: active == 'A' }"
          >单程用车</span
        >
        <span @click="func('B')" v-bind:class="{ active: active == 'B' }"
          >市内包车</span
        >
        <!-- <span @click="func('C')" v-bind:class="{ active: active == 'C' }"
          >城际包车</span
        > -->
      </div>
      <div class="block block1" v-if="block == 'A'">
        <span
          style="
            font-size: 12px;
            color: red;
            margin-top: 10px;
            text-align: left;
            margin-left: 80px;
          "
          >A到B，如酒店到机场，机场到酒店，多余用接机/送机</span
        >
        <div class="inputinfo" style="margin-top: 10px">
          <p
            style="
              color: red;
              margin-left: 20px;
              font-size: 18px;
              margin-top: 10px;
            "
          >
            *
          </p>
          <p style="margin-right: 10px; font-size: 14px">用车日期</p>
          <DatePicker
            :options="options3"
            v-model="date"
            format="yyyy年MM月dd日"
            @on-change="dateChange"
            placeholder="请选择日期"
            style="width: 280px"
          ></DatePicker>
          <TimePicker
            v-model="time"
            format="HH:mm"
            @on-change="timeChange"
            placeholder="请选择时间"
            style="width: 180px; margin-left: 10px"
            :disabled-hours="disabledHours"
            :disabled-minutes="disabledMins"
            :disabled="disabledTimePicker"
          ></TimePicker>
        </div>
        <div class="inputinfo">
          <p
            style="
              color: red;
              margin-left: 20px;
              font-size: 18px;
              margin-top: 10px;
            "
          >
            *
          </p>
          <p style="margin-right: 10px; font-size: 14px">上车地点</p>
          <Select
            v-model="insideCityId"
            style="width: 180px"
            placeholder="请选择城市"
            @on-change="citySelect(e)"
          >
            <Option
              v-for="item in cityLists"
              :value="item.id"
              :key="item.city_name"
              :data-name="item.city_name"
              >{{ item.city_name }}
            </Option>
          </Select>
          <AutoComplete
            v-model="departure1"
            @on-search="insideonSearchDepartureTips"
            placeholder="去哪儿接您"
            style="width: 280px; margin-left: 10px"
          >
            <div class="searchAddressListMask">
              <Icon type="ios-loading" v-if="!insideisGetAddressList" />
              <div v-else class="searchAddressListContainer">
                <div
                  v-for="tip in insidedepartureTipList"
                  :key="tip.id"
                  class="tip-container"
                  @click="insideselectDepartureAddress(tip)"
                >
                  <Icon type="ios-navigate-outline" />
                  <span>{{ tip.name }}</span>
                  <div class="line"></div>
                </div>
              </div>
            </div>
          </AutoComplete>
        </div>
        <div class="inputinfo">
          <p
            style="
              color: red;
              margin-left: 20px;
              font-size: 18px;
              margin-top: 10px;
            "
          >
            *
          </p>
          <p style="margin-right: 10px; font-size: 14px">下车地点</p>
          <Select
            v-model="outCityId"
            style="width: 180px"
            placeholder="请选择城市"
            @on-change="citySelects(e)"
          >
            <Option
              v-for="item in cityLists1"
              :value="item.id"
              :key="item.city_name"
              :data-name="item.city_name"
              >{{ item.city_name }}
            </Option>
          </Select>
          <AutoComplete
            v-model="departure2"
            @on-search="outonSearchDepartureTips"
            placeholder="去哪儿接您"
            style="width: 280px; margin-left: 10px"
          >
            <div class="searchAddressListMask">
              <Icon type="ios-loading" v-if="!outisGetAddressList" />
              <div v-else class="searchAddressListContainer">
                <div
                  v-for="tip in outdepartureTipList"
                  :key="tip.id"
                  class="tip-container"
                  @click="outselectDepartureAddress(tip)"
                >
                  <Icon type="ios-navigate-outline" />
                  <span>{{ tip.name }}</span>
                  <div class="line"></div>
                </div>
              </div>
            </div>
          </AutoComplete>
        </div>
        <button class="btn" @click="btnsubmit">查询</button>
      </div>
      <div class="block block1" v-if="block == 'B'">
        <span
          style="
            font-size: 12px;
            color: red;
            margin-top: 10px;
            text-align: left;
            margin-left: 80px;
          "
          >市区内A到B到C到多个市区目的地，如从家到公司，再到客户公司，再到某展会</span
        >
        <div class="inputinfo" style="margin-top: 10px">
          <p
            style="
              color: red;
              margin-left: 20px;
              font-size: 18px;
              margin-top: 10px;
            "
          >
            *
          </p>
          <p style="margin-right: 10px; font-size: 14px">用车日期</p>
          <DatePicker
            v-model="withindate"
            format="yyyy年MM月dd日"
            @on-change="dateChange"
            placeholder="请选择日期"
            style="width: 280px"
            :options="options3"
          ></DatePicker>
          <TimePicker
            v-model="withintime"
            format="HH:mm"
            @on-change="withintimeChange"
            placeholder="请选择时间"
            style="width: 180px; margin-left: 10px"
            :disabled-hours="disabledHours"
            :disabled-minutes="disabledMins"
            :disabled="disabledTimePicker"
          ></TimePicker>
        </div>
        <div class="inputinfo">
          <p
            style="
              color: red;
              margin-left: 20px;
              font-size: 18px;
              margin-top: 10px;
            "
          >
            *
          </p>
          <p style="margin-right: 10px; font-size: 14px">上车地点</p>
          <Select
            v-model="withinCityId"
            style="width: 180px"
            placeholder="请选择城市"
            @on-change="citySelect1(e)"
          >
            <Option
              v-for="item in cityLists2"
              :value="item.id"
              :key="item.city_name"
              :data-name="item.city_name"
              >{{ item.city_name }}
            </Option>
          </Select>
          <AutoComplete
            v-model="departure3"
            @on-search="withinonSearchDepartureTips"
            placeholder="去哪儿接您"
            style="width: 280px; margin-left: 10px"
          >
            <div class="searchAddressListMask">
              <Icon type="ios-loading" v-if="!withinisGetAddressList" />
              <div v-else class="searchAddressListContainer">
                <div
                  v-for="tip in withindepartureTipList"
                  :key="tip.id"
                  class="tip-container"
                  @click="withinselectDepartureAddress(tip)"
                >
                  <Icon type="ios-navigate-outline" />
                  <span>{{ tip.name }}</span>
                  <div class="line"></div>
                </div>
              </div>
            </div>
          </AutoComplete>
        </div>
        <div class="inputinfo">
          <!-- <p style="color:red;margin-left:20px;font-size:18px">*</p>
          <p style="margin-right:10px;font-size:14px">用车模式</p>
           <Select v-model="carType" style="width: 180px;" placeholder="请选择城市"  @on-change="carTypes">
                <Option
                  v-for="item in serviceCarType"
                  :value="item.name"
                  :key="item.name"
                  >{{ item.name }}
                </Option>
              </Select> -->
          <!-- <p style="margin-right: 10px; font-size: 14px; margin-left: 28px">
            用车天数
          </p>
          <Select
            v-model="carDays"
            style="width: 204px; margin-left: 10px"
            placeholder="请选择用车天数"
            @on-change="carDay"
          >
            <Option
              v-for="item in dateList"
              :value="item.value"
              :key="item.value"
              >{{ item.label }}
            </Option>
          </Select> -->
        </div>
        <button class="btn" @click="withinBtn">查询</button>
      </div>
      <div class="block block1" v-if="block == 'C'">
        <span
          style="
            font-size: 12px;
            color: red;
            margin-top: 10px;
            text-align: left;
            margin-left: 80px;
          "
          >跨城A到B，如深圳至广州，东莞，佛山等</span
        >
        <div class="inputinfo" style="margin-top: 10px">
          <p
            style="
              color: red;
              margin-left: 20px;
              font-size: 18px;
              margin-top: 10px;
            "
          >
            *
          </p>
          <p style="margin-right: 10px; font-size: 14px">用车日期</p>
          <DatePicker
            v-model="externaldate"
            format="yyyy年MM月dd日"
            @on-change="dateChange"
            placeholder="请选择日期"
            style="width: 280px"
            :options="options3"
          ></DatePicker>
          <TimePicker
            v-model="externaltime"
            format="HH:mm"
            @on-change="externaltimeChange"
            placeholder="请选择时间"
            style="width: 180px; margin-left: 10px"
            :disabled-hours="disabledHours"
            :disabled-minutes="disabledMins"
            :disabled="disabledTimePicker"
          ></TimePicker>
        </div>
        <div class="inputinfo">
          <p
            style="
              color: red;
              margin-left: 20px;
              font-size: 18px;
              margin-top: 10px;
            "
          >
            *
          </p>
          <p style="margin-right: 10px; font-size: 14px">上车地点</p>
          <Select
            v-model="externalCityId"
            style="width: 180px"
            placeholder="请选择城市"
            @on-change="citySelect2(e)"
          >
            <Option
              v-for="item in cityLists3"
              :value="item.id"
              :key="item.city_name"
              :data-name="item.city_name"
              >{{ item.city_name }}
            </Option>
          </Select>
          <AutoComplete
            v-model="departure4"
            @on-search="externalonSearchDepartureTips"
            placeholder="去哪儿接您"
            style="width: 280px; margin-left: 10px"
          >
            <div class="searchAddressListMask">
              <Icon type="ios-loading" v-if="!externalisGetAddressList" />
              <div v-else class="searchAddressListContainer">
                <div
                  v-for="tip in externaldepartureTipList"
                  :key="tip.id"
                  class="tip-container"
                  @click="externalselectDepartureAddress(tip)"
                >
                  <Icon type="ios-navigate-outline" />
                  <span>{{ tip.name }}</span>
                  <div class="line"></div>
                </div>
              </div>
            </div>
          </AutoComplete>
        </div>
        <div class="inputinfo">
          <!-- <p style="color:red;margin-left:20px;font-size:18px">*</p>
          <p style="margin-right:10px;font-size:14px">用车模式</p>
           <Select v-model="externalcarType" style="width: 180px;" placeholder="请选择城市"  @on-change="externalcarTypes">
                <Option
                  v-for="item in serviceCarType"
                  :value="item.name"
                  :key="item.name"
                  >{{ item.name }}
                </Option>
              </Select> -->
          <p style="margin-right: 10px; font-size: 14px; margin-left: 28px">
            用车天数
          </p>
          <Select
            v-model="externalcarDays"
            style="width: 204px; margin-left: 10px"
            placeholder="请选择用车天数"
            @on-change="externalcarDay"
          >
            <Option
              v-for="item in dateList"
              :value="item.value"
              :key="item.value"
              >{{ item.label }}
            </Option>
          </Select>
        </div>
        <button class="btn" @click="externalbtnsubmit">查询</button>
      </div>
      <div class="show" v-show="tip">
        <div class="showtip">
          <p>请填写完整的用车信息</p>

          <p class="tipBtn" @click="tipbtn">确认</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import AMap from "AMap";
import { domesticCities, autocomplete, serviceType } from "@/api/charterCar";
import moment from "moment";
import storage from "store";

export default {
  name: "charter",
  data() {
    return {
      userCarType: 2,
      outdepartureTipList: "",
      outisGetAddressList: false,
      withinisGetAddressList: false,
      outCityId: "",
      insideCityId: "",
      withinCityId: "",
      externalCityId: "",
      carDays: "",
      carType: "",
      active: "A",
      block: "A",
      departure1: "",
      departure2: "",
      departure3: "",
      departure4: "",

      departureCity: "",
      route: "",
      vehicleDays: "",
      externalcarType: "",
      externalcarDays: "",
      date: "",
      time: "",
      withindate: "",
      withintime: "",

      externaldate: "",
      externaltime: "",
      is_in_mainland: 1,
      cityLists: "",
      insideisGetAddressList: false,
      serviceCarType: "",
      dateList: [
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
        { value: "5", label: "5" },
        { value: "6", label: "6" },
        { value: "7", label: "7" },
        { value: "8", label: "8" },
        { value: "9", label: "9" },
        { value: "10", label: "10" },
      ],
      insidedepartureTipList: "",
      tip: false,
      outDepartureCity: "",
      withinDepartureCity: "",
      externaldepartureCity: "",
      cityLists1: "",
      cityLists2: "",
      withindepartureTipList: "",
      externalisGetAddressList: false,
      externaldepartureTipList: "",
      insidelat: "",
      outlng: "",
      urbanCharteredDeparture: "",
      disabledTimePicker:true,
      disabledHours:[],
      disabledMins:[],
      options3: {
        disabledDate(date) {
          // console.log(date);
          //return date < new Date() - 60 * 60 * 1000;
          return date && date.valueOf() < Date.now();
        },
      },
    };
  },
  methods: {
    //单程上车点位置选择
    insideonSearchDepartureTips(value) {
      storage.set("insideCityId", this.insideCityId);

      autocomplete({ content: value, city: this.insideCityId }).then((res) => {
        this.insidedepartureTipList = [];
        this.insidedepartureTipList = res.data.tips;
        if (res.data.tips.length > 0) {
          this.insideisGetAddressList = true;
        }
      });
    },
    insideonSearchDestinationTips(value) {
      autocomplete({ content: value, city: this.insideCityId }).then((res) => {
        this.destinationTipList = res.data.tips;
      });
    },
    insideselectDepartureAddress(tip) {
      this.departure1 = tip.name;
      this.insidelat = tip.location;
      console.log(this.insidelat);
      storage.set("insidedept_lat", tip.location.split(",")[0]);
      storage.set("insidedept_lng", tip.location.split(",")[1]);
      this.departureAddress = tip;
      storage.set("departure1", this.departure1);
    },
    //单程下车点位置选择
    outonSearchDepartureTips(value) {
      storage.set("outCityId", this.outCityId);
      autocomplete({ content: value, city: this.outCityId }).then((res) => {
        this.outdepartureTipList = [];
        this.outdepartureTipList = res.data.tips;
        if (res.data.tips.length > 0) {
          this.outisGetAddressList = true;
        }
      });
    },
    outeonSearchDestinationTips(value) {
      autocomplete({ content: value, city: this.outCityId }).then((res) => {
        this.destinationTipList = res.data.tips;
      });
    },
    outselectDepartureAddress(tip) {
      this.departure2 = tip.name;
      this.outlng = tip.location;
      console.log(this.outlng);
      storage.set("outdept_lat", tip.location.split(",")[0]);
      storage.set("outdept_lng", tip.location.split(",")[1]);
      this.departureAddress = tip;
      storage.set("departure2", this.departure2);
    },

    //市内包车位置选择
    withinonSearchDepartureTips(value) {
      autocomplete({ content: value, city: this.withinCityId }).then((res) => {
        this.withindepartureTipList = [];
        this.withindepartureTipList = res.data.tips;
        if (res.data.tips.length > 0) {
          this.withinisGetAddressList = true;
        }
      });
    },
    withinonSearchDestinationTips(value) {
      autocomplete({ content: value, city: this.withinCityId }).then((res) => {
        this.destinationTipList = res.data.tips;
      });
    },
    withinselectDepartureAddress(tip) {
      this.departure3 = tip.name;
      this.urbanCharteredDeparture = tip.location
      storage.set(" withindept_lat", tip.location.split(",")[0]);
      storage.set(" withindept_lng", tip.location.split(",")[1]);
      this.departureAddress = tip;
      storage.set("departure3", this.departure3);
    },
    //跨城用车位置选择
    externalonSearchDepartureTips(value) {
      autocomplete({ content: value, city: this.externalCityId }).then(
        (res) => {
          this.externaldepartureTipList = [];
          this.externaldepartureTipList = res.data.tips;
          if (res.data.tips.length > 0) {
            this.externalisGetAddressList = true;
          }
        }
      );
    },
    externalonSearchDestinationTips(value) {
      autocomplete({ content: value, city: this.externalCityId }).then(
        (res) => {
          this.destinationTipList = res.data.tips;
        }
      );
    },
    externalselectDepartureAddress(tip) {
      this.departure4 = tip.name;
      storage.set(" externaldept_lat", tip.location.split(",")[0]);
      storage.set(" externaldept_lng", tip.location.split(",")[1]);
      this.departureAddress = tip;
      storage.set("departure4", this.departure4);
    },
    //单程用车查询
    btnsubmit() {
      if (this.insidelat != "" && this.outlng != "") {
        this.$router.push({
          name: "daycharter_charterindex_index",
          params: {
            departureCity: this.departureCity,
            departure2: this.departure2,
            time: this.time,
            date: this.date,
            cityId: this.insideCityId,
            departure: this.departure1,
            insidelat: this.insidelat,
            outlng: this.outlng,
            outDepartureCity: this.outDepartureCity,
            outCityId: this.outCityId,
            userCarType: this.userCarType,
          },
        });
      } else {
        this.tip = true;
      }
    },
    //城际包车查询
    externalbtnsubmit() {
      console.log(this.externaldate);
      console.log(this.externaltime);
      console.log(this.externalcarDays);
      console.log(this.externalCityId);
      if (
        this.externaldate != "" &&
        this.externaltime != "" &&
        this.externalCityId != "" &&
        this.externalcarDays != ""
      ) {
        this.$router.push({
          name: "daycharter_charterindex_index",
          params: {
            departureCity: this.externalDepartureCity,
            time: this.externaltime,
            date: this.externaldate,
            carDays: this.externalcarDays,
            cityId: this.externalCityId,
            departure: this.departure4,
            userCarType: this.userCarType,
          },
        });
      } else {
        this.tip = true;
      }
    },
    //市内查询
    withinBtn() {
      if (
        this.withindate != "" &&
        this.withintime != "" &&
        this.withinCityId != ""
      ) {
        this.$router.push({
          name: "daycharter_charterindex_index",
          params: {
            departureCity: this.withinDepartureCity,
            time: this.withintime,
            date: this.withindate,
            carDays: this.carDays,
            cityId: this.withinCityId,
            departure: this.departure3,
            ucInsidelat: this.urbanCharteredDeparture,
            ucOutlng: this.urbanCharteredDeparture,
            userCarType: this.userCarType,
          },
        });
      } else {
        this.tip = true;
      }
    },
    tipbtn() {
      this.tip = false;
    },
    //市内包车时间选择
    withindateChange() {
      // this.date=moment().format("yyyy年MM月DD日")
      console.log(this.withindate);
      console.log(moment(this.withindate).unix());
    },
    withintimeChange() {
      console.log(this.withintime);
      storage.set("times", this.withintime);
    },
    //城际时间选择
    externaldateChange() {
      // this.date=moment().format("yyyy年MM月DD日")
      console.log(this.withindate);
      console.log(moment(this.withindate).unix());
    },
    externaltimeChange() {
      console.log(this.withintime);
      storage.set("times", this.withintime);
    },
    //单程时间选择
    dateChange(date) {
      // this.date=moment().format("yyyy年MM月DD日")
      this.disabledTimePicker=false
      const selectedDate = date
      const startMonth = selectedDate.lastIndexOf("年")+1
      const stopMonth = startMonth + 2
      const selectedMonth= date.substring(startMonth,stopMonth)
      const start = selectedDate.lastIndexOf("月")+1
      const stop = start + 2
      const daySelectedDate = date.substring(start,stop)
      const today = new Date()
      const monthToday = today.getMonth() + 1
      const dayTomorrow = today.getDate() + 1
      const hourToday = new Date().getHours()
      const minToday = new Date().getMinutes()
      console.log('selectedMonth',selectedMonth)
      console.log('monthToday',monthToday)
      
      if(parseInt(selectedMonth) == parseInt(monthToday)){
        if(daySelectedDate == dayTomorrow)
        {
          console.log('monthToday',monthToday)
          let disabledHour= hourToday
          for(let i=0;i<disabledHour;i++)
          {
            this.disabledHours.push(i)
          }
          let disabledMin= minToday
          for(let i=0;i<disabledMin;i++)
          {
            this.disabledMins.push(i)
          }
        }
        else
        {
          this.disabledHours = []
          this.disabledMins = []
        }
      }
      else
      {
          this.disabledHours = []
          this.disabledMins = []
      }
    },
    timeChange() {
      console.log(this.time);
      storage.set("times", this.time);
    },
    //单程上车的城市选择
    citySelect() {
      let index = this.cityLists.filter(
        (item) => item.id === this.insideCityId
      );
      this.departureCity = index[0].city_name;
      storage.set("departureCity", this.departureCity);
    },
    //单程下车点选择
    citySelects() {
      let index = this.cityLists1.filter((item) => item.id === this.outCityId);
      this.outDepartureCity = index[0].city_name;
      storage.set("outDepartureCity", this.outDepartureCity);
    },
    //市内包车选择
    citySelect1() {
      let index = this.cityLists2.filter(
        (item) => item.id === this.withinCityId
      );
      this.withinDepartureCity = index[0].city_name;
      storage.set("withinDepartureCity", this.withinDepartureCity);
    },
    //城际包车选择
    citySelect2() {
      let index = this.cityLists3.filter(
        (item) => item.id === this.externalCityId
      );
      this.externalDepartureCity = index[0].city_name;
      storage.set("externalDepartureCity", this.externalDepartureCity);
      console.log(this.externalDepartureCity);
    },

    //市内车型用车时间选择
    carTypes() {
      console.log(this.carType);
      storage.set("carType", this.carType);
    },
    carDay() {
      console.log(this.carDays);
      storage.set("carDays", this.carDays);
    },
    //城际车型时间选择
    externalcarTypes() {
      console.log(this.carType);
      storage.set("carType", this.carType);
    },
    externalcarDay() {
      console.log(this.carDays);
      storage.set("carDays", this.carDays);
    },

    func: function (m) {
      this.active = m;
      this.block = m;
      if(this.active == "A") {
        this.userCarType = 2;
      }else if (this.active == "B") {
        this.userCarType = 3;
      } else if (this.active == "C") {
        this.userCarType = 4;
      }
    },
  },
  created() {
    const params = {
      is_in_mainland: this.is_in_mainland,
    };
    domesticCities(params).then((res) => {
      console.log(res.data);
      this.cityLists = res.data;
      this.cityLists1 = res.data;
      this.cityLists2 = res.data;
      this.cityLists3 = res.data;
    });
    serviceType(params).then((res) => {
      console.log(res.data);
      this.serviceCarType = res.data;
    });
  },
};
</script>

<style scoped>
@import "../../../style/common.css";
.management-center {
  position: relative;
}
.center-part {
  margin: 60px auto;
  width: 700px;
  height: 430px;
  border: 1px solid #ff5400;
  border-radius: 4px;
}
.textbox {
  width: 100%;
  height: 80px;
  background-color: #ff5400;
  color: white;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  line-height: 80px;
  padding-left: 20px;
}
.inputinfo {
  margin-left: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}
.btn {
  width: 200px;
  height: 50px;
  color: white;
  border-radius: 4px;
  background-color: #ff5400;
  outline: none;
  border: none;
  font-size: 14px;
  margin-top: 30px;
  margin-left: 240px;
}
.searchAddressListMask {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  flex-direction: column;
}

.searchAddressListContainer {
  display: flex;
  justify-content: center;
  width: 730px;
  height: 200px;
  flex-direction: column;
  overflow-y: auto;
}

.tip-container {
  padding: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}
.line {
  width: 100%;
  height: 1px;
  background-color: #d9d9d9;
  position: absolute;
  left: 0;
  top: 0px;
}
.tip {
  width: 500px;
  height: 300px;
  background-color: white;
  position: absolute;
  top: 100px;
  left: 400px;
}
.show {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 100000;
}
.showtip {
  text-align: center;
  width: 350px;
  height: 150px;
  background-color: #ffff;
  position: absolute;
  left: 500px;
  top: 300px;
  z-index: 100000;
  border-radius: 4px;
}

.showtip p {
  margin-top: 20px;
  font-size: 18px;
}

.showtip .tipBtn {
  margin: 52px auto 139px;
  width: 100px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  color: #fff;
  background: #f16622;
  border-radius: 2px;
  border: 1px solid #f16622;
  cursor: pointer;
}
.management-part {
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
}
.management-part div {
  display: inline-block;
  width: 150px;
  height: 34px;
  line-height: 34px;
  background-color: hsla(0, 0%, 100%, 0.9);
  border-radius: 2px;
  border: 1px solid #f16622;
  color: #f16622;
  text-align: center;
  cursor: pointer;
  margin-top: 10px;
}
.management-partdiv {
  margin-left: 370px;
  margin-right: 30px;
}

.management-part span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 146px;
  height: 56px;
  border: 1px solid #d8dde9;
  margin-left: 0;
  text-align: center;
  line-height: 146cpx;
  color: #999;
  background-color: #f3f4f5;
  border-bottom: 1px solid #d8dde9;
  z-index: 100;
  font-size: 16px;
}

.management-part .active {
  background-color: white;
  color: #ff5400;
  border-bottom: none;
}

.block {
  width: 800px;
  min-height: 400px;
  position: absolute;
  top: 196px;
  display: flex;
  justify-content: flex-start;
  color: #151515;
  flex-direction: column;
}
</style>


