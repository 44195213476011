<template>
  <div class="layout">
    <card class="order-card" shadow="false">
      <!-- <div class="p-box">
        <div class="management-centerline"></div>
        <p>在线下单</p>
      </div> -->
      <div class="step-form">
        <steps
          class="steps"
          :current="current"
          size="small"
          direction="horizontal"
        >
          <step title="填写行程信息"></step>
          <step title="选择车型"></step>
          <step title="确认行程信息"></step>
          <step title="完成"></step>
        </steps>
      </div>
      <div class="content">
        <div class="inputOrderInfo" v-if="current == 0">
          <Form
            style="text-align: left"
            label-position="left"
            ref="formItem"
            :model="formItem"
            :rules="ruleValidate"
            :label-width="80"
          >
            <FormItem prop="time" label="出发时间">
              <Row>
                <Col span="5">
                  <FormItem prop="date">
                    <DatePicker
                      placeholder="请选择用车日期"
                      format="yyyy年MM月dd日"
                      v-model="formItem.date"
                      @on-change="dateChange"
                    ></DatePicker>
                  </FormItem>
                </Col>
                <Col span="2" style="text-align: center">-</Col>
                <Col span="11">
                  <FormItem prop="time">
                    <TimePicker
                      v-model="formItem.time"
                      format="HH:mm"
                      placeholder="请选择用车时间"
                      @on-change="timeChange"
                    ></TimePicker>
                  </FormItem>
                </Col>
              </Row>
            </FormItem>
            <FormItem prop="departure" label="出发地">
              <Select v-model="departureCity" style="width: 200px">
                <Option
                  v-for="item in cityLists"
                  :value="item.city_name"
                  :key="item.city_name"
                  >{{ item.city_name }}
                </Option>
              </Select>
              <AutoComplete
                v-model="formItem.departure"
                @on-search="onSearchDepartureTips"
                placeholder="请输入出发地"
                style="width: 730px; margin-left: 20px"
              >
                <div class="searchAddressListMask">
                  <Icon type="ios-loading" v-if="!isGetAddressList" />
                  <div v-else class="searchAddressListContainer">
                    <div
                      v-for="tip in departureTipList"
                      :key="tip.id"
                      class="tip-container"
                      @click="selectDepartureAddress(tip)"
                    >
                      <Icon type="ios-navigate-outline" />
                      <span>{{ tip.name }}</span>
                      <div class="line"></div>
                    </div>
                  </div>
                </div>
              </AutoComplete>
            </FormItem>
            <FormItem prop="destination" label="目的地">
              <Select v-model="destinationCity" style="width: 200px">
                <Option
                  v-for="item in hCityLists"
                  :value="item.city_name"
                  :key="item.city_name"
                  >{{ item.city_name }}
                </Option>
              </Select>
              <AutoComplete
                v-model="formItem.destination"
                @on-search="onSearchDestinationTips"
                placeholder="请输入目的地"
                style="width: 730px; margin-left: 20px"
              >
                <div class="searchAddressListMask">
                  <Icon type="ios-loading" v-if="!isGetAddressList" />
                  <div v-else class="searchAddressListContainer">
                    <div
                      v-for="tip in destinationTipList"
                      :key="tip.id"
                      class="tip-container"
                      @click="selectDestinationAddress(tip)"
                    >
                      <Icon type="ios-navigate-outline" />
                      <span style="">{{ tip.name }}</span>
                      <div class="line"></div>
                    </div>
                  </div>
                </div>
              </AutoComplete>
            </FormItem>
            <Row>
              <Col span="12">
                <FormItem prop="people" label="乘车人数">
                  <Select v-model="formItem.people" style="width: 400px">
                    <Option
                      v-for="item in cityList"
                      :value="item.value"
                      :key="item.value"
                      >{{ item.label }}
                    </Option>
                  </Select>
                </FormItem>
              </Col>
              <Col span="12">
                <FormItem
                  prop="port"
                  label="通关口岸"
                  style="margin-left: 36px"
                >
                  <Select
                    v-model="formItem.port"
                    :label-in-value="true"
                    @on-change="portChange"
                    placeholder="请选择口岸"
                    style="width: 340px; margin-left: 0px"
                  >
                    <Option
                      v-for="item in customList"
                      :value="item.id"
                      :key="item.name"
                      >{{ item.name }}
                    </Option>
                  </Select>
                </FormItem>
              </Col>
            </Row>
            <!-- <FormItem label="乘车人" prop="name">
              <Row>
                <Col span="12">
                  <FormItem prop="name">
                    <Input
                      prefix="ios-contact"
                      placeholder="请输入姓名"
                      v-model="formItem.name"
                      style="width:400px"
                    />
                  </FormItem>
                  
                </Col>
                <Col span="12">
                  <FormItem prop="phone" label="联系电话">
                    <Input
                      prefix="ios-phone-portrait"
                      placeholder="请输入电话"
                      v-model="formItem.phone"
                      style="width:340px;margin-left:0px"
                    />
                  </FormItem>
                </Col>
              </Row>
            </FormItem> -->
            <div
              class="user"
              @mouseover="mouseOver"
              @mouseleave="mouseLeave"
              :style="active"
              @click="addUser"
            >
              +添加乘车人信息
            </div>
            <FormItem label="乘车人" prop="name">
            <!-- <div class="passenger">
              <p style="margin-right: 43px">乘车人</p> -->
              <!-- <span v-show="ptip" style="position: relative;left: 10px;top: 27px;color:red">请选择乘车人</span> -->
              <Select
                v-model="passengerNumber"
                :label-in-value="true"
                @on-change="passengerChange"
                placeholder="请选择乘车人"
                style="width: 340px; margin-left: 0px; "
              >
                <Option
                  :label="item.nick_name"
                  v-for="item in passenger"
                  :value="`${item.nick_name}  ${item.mobile} `"
                  :key="item.nick_name"
                  :data-uid="item.uid"
                  >{{ item.nick_name }} {{ item.mobile }}
                </Option>
              </Select>
              </FormItem>
              <!-- <span  v-for="(item, index) in passenger"
                  :key="index">
                  <div class="passengerBox">
                    <span style="margin-top:10px;margin-left:10px">{{item.nick_name}}</span>
                    <span style="margin-top:10px;margin-left:10px">{{item.mobile}}</span></div>
                   <div class="pline" ></div>
                    
                  </span> -->
            <!-- </div> -->
            <FormItem prop="desc" label="备注信息">
              <Input
                v-model="formItem.desc"
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 5 }"
                placeholder="Enter something..."
              ></Input>
            </FormItem>
          </Form>
        </div>
        <div class="selectCarService" v-if="current == 1">
          <Card
            dis-hover
            shadow
            style="margin: 12px 24px"
            v-for="priceItem in priceList"
            :key="priceItem.id"
          >
            <div class="selectCar">
              <div class="car-info">
                <div class="car-img">
                  <img
                    :src="priceItem.img"
                    style="width: 135px; height: 105px"
                  />
                  <span style="padding: 12px; font-weight: bold">{{
                    priceItem.service_name
                  }}</span>
                </div>
                <div class="car-desc">
                  <span
                    style="font-size: 20px; font-weight: bold; margin-top: 12px"
                    >{{ priceItem.service_desc }}</span
                  >
                  <div class="car-desc-item-wrapper">
                    <div class="car-desc-item">
                      <Icon type="ios-people" />
                      <span style="padding: 0px 6px">{{
                        priceItem.seat_count
                      }}</span>
                    </div>
                    <div class="car-desc-item">
                      <Icon type="ios-basket" />
                      <span>{{ priceItem.specifications }}</span>
                    </div>
                    <div class="car-desc-item">
                      <Icon type="ios-people" />
                      <span style="padding-left: 6px">{{
                        priceItem.attrs
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="car-action">
                <div class="car-price-item">
                  <span style="color: #ff5400">¥{{ priceItem.price }}</span>
                </div>
                <div class="car-order" @click="orderCar(priceItem)">
                  立即预定
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div class="confirmOrderInfo" v-if="current === 2">
          <Card dis-hover style="margin: 40px">
            <div style="text-align: left">
              <div>
                <Row>
                  <Col span="3">
                    <span class="item-header">用车时间</span>
                  </Col>
                  <Col span="9">
                    <label>{{ useDate + " " + useTime }}</label>
                  </Col>
                  <Col span="3">
                    <span class="item-header">通关口岸</span>
                  </Col>
                  <Col span="9">
                    <label>{{ showPort.label }}</label>
                  </Col>
                </Row>
                <Row style="margin-top: 12px">
                  <Col span="3">
                    <span class="item-header">乘车人信息</span>
                  </Col>
                  <Col span="9">
                    <label>{{ uname + " | " + unumber }}</label>
                  </Col>
                  <Col span="3">
                    <span class="item-header">乘车人数</span>
                  </Col>
                  <Col span="9">
                    <label>{{ formItem.people + "人" }}</label>
                  </Col>
                </Row>
                <Row style="margin-top: 12px">
                  <Col span="3">
                    <span class="item-header">备注</span>
                  </Col>
                  <Col span="21">
                    <label>{{ formItem.desc }}</label>
                  </Col>
                </Row>
              </div>
              <br />
              <Row style="margin-top: 12px">
                <Col span="3">
                  <span class="item-header">行程线路:</span>
                </Col>
                <Col span="21">
                  <label>{{
                    formItem.departure + "->" + formItem.destination
                  }}</label>
                  <div class="carPrices">
                    <h3>价格：</h3>
                    <span style="color: #ff5400">{{ carPrices }}</span>
                  </div>
                </Col>
              </Row>
              <br />
              <div>
                <h3>车型</h3>
                <img :src="carImg" />
              </div>
            </div>
          </Card>
        </div>
        <div class="order-finish-div" v-if="current === 3">
          <img
            class="img-responsive"
            style="width: 150px; height: 150px"
            src="../../../assets/yes.png"
          />
          <br />
          <span>预约成功</span>
          <card class="finish-card">
            <Row>
              <Col :sm="8" :xs="24">
                <h4>订单号</h4>
              </Col>
              <Col :sm="16" :xs="24">
                <label>KJC39827482379</label>
              </Col>
            </Row>
            <Row>
              <Col :sm="8" :xs="24">
                <h4>用车时间</h4>
              </Col>
              <Col :sm="16" :xs="24">
                <label>2020-09-09 9:9</label>
              </Col>
            </Row>
            <Row>
              <Col :sm="8" :xs="24">
                <h4>行程</h4>
              </Col>
              <Col :sm="16" :xs="24">
                <label>科技园->迪士尼</label>
              </Col>
            </Row>
          </card>
        </div>
      </div>
      <div class="deployBtn">
        <Checkbox v-model="single" v-if="current == 2">
          我已阅读并同意
          <a href target="blank">《支付条款》</a>
        </Checkbox>
        <br />
        <button
          type="primary"
          @click="prev"
          v-if="current == 2"
          style="
            margin-right: 20px;
            margin-top: 10px;
            background-color: #ff5400;
            color: #fff;
            border: none;
            outline: none;
            width: 80px;
            height: 30px;
          "
        >
          上一步
        </button>
        <button
          type="primary"
          @click="prev"
          v-if="current == 1"
          style="
            margin-right: 20px;
            margin-top: 10px;
            background-color: #ff5400;
            color: #fff;
            border: none;
            outline: none;
            width: 80px;
            height: 30px;
          "
        >
          上一步
        </button>
        <button
          type="primary"
          @click="next('formItem')"
          v-if="current == 0"
          style="
            color: #fff;
            margin-top: 10px;
            border: none;
            outline: none;
            width: 80px;
            height: 30px;
            background-color: #ff5400;
          "
        >
          下一步
        </button>
        <button
          type="primary"
          @click="confirm"
          v-if="current == 2"
          style="
            color: #fff;
            margin-top: 10px;
            border: none;
            outline: none;
            width: 80px;
            height: 30px;
            background-color: #ff5400;
          "
        >
          确认用车
        </button>
        <button
          type="primary"
          @click="finish"
          v-if="current == 3"
          style="
            color: #fff;
            margin-top: 10px;
            border: none;
            outline: none;
            width: 80px;
            height: 30px;
            background-color: #ff5400;
          "
        >
          再次预约
        </button>
        <button
          type="primary"
          v-if="current == 3"
          @click="orderList"
          style="
            color: #fff;
            margin-top: 100px;
            border: none;
            outline: none;
            width: 80px;
            height: 30px;
            background-color: #ff5400;
            margin-left: 10px;
          "
        >
          订单列表
        </button>
      </div>
    </card>

    <div class="show" v-show="tips">
      <div class="showpay">
        <p>目的地不能与出发地相同</p>
        <p class="btn" @click="btn">确认</p>
      </div>
    </div>
    <div class="lastTips" v-show="lastTips">
      <div class="showtip">
        <p>
          本次用车 <span style="color: #ff5400">{{ carPrices }}</span
          >元
        </p>
        <p>余额{{ balance }}元</p>
        <p class="tipBtn" @click="tipbtn">确认</p>
      </div>
    </div>
    <div class="show" v-show="agreement">
      <div class="showpay">
        <p>请勾选用车协议</p>
        <p class="btn" @click="agreementBtn">确认</p>
      </div>
    </div>
    <div class="show" v-show="userinfo">
      <div class="userinfo">
        <div class="userN">
          <p style="margin-left: 10px">乘车人信息</p>
          <img
            src="../../../assets/ca.png"
            alt=""
            style="width: 15px; height: 15px; margin-right: 10px"
            @click="outinfo"
          />
        </div>
        <div class="inputinfo">
          <p style="color: red; margin-left: 20px; font-size: 18px">*</p>
          <p style="margin-right: 10px; font-size: 14px">乘车人姓名</p>
          <Input
            v-model="uname"
            placeholder="请输入乘车人姓名"
            style="width: 380px"
          />
        </div>
        <div class="inputinfo">
          <p style="color: red; margin-left: 20px; font-size: 18px">*</p>
          <p style="margin-right: 10px; font-size: 14px">乘车人电话</p>
          <Input
            v-model="unumber"
            placeholder="请输入乘车人电话"
            style="width: 380px"
          />
        </div>
        <button class="btnSubmit" @click="btnSubmit">提交</button>
      </div>
    </div>
    <div class="show" v-show="usertips">
      <div class="showpay">
        <p>请填写乘车人信息</p>
        <p class="btn" @click="userbtn">确认</p>
      </div>
    </div>
    <div class="show" v-show="balanceShow">
      <div class="showpay">
        <p>您的余额不足，请充值！</p>
        <p class="btn" @click="userbtns">确认</p>
      </div>
    </div>
    <div class="show" v-show="tipShow">
      <div class="showpay">
        <p>请输入完整的乘车人信息</p>
        <p class="btn" @click="tipbtns">确认</p>
      </div>
    </div>
     <div class="show" v-show="tipShows">
      <div class="showpays">
        <p>根据疫情防控工作需要,即日起暂停预订商务车,详情请联系客服0755-86665622</p>
        <p class="btn" @click="closebtn">确认</p>
      </div>
    </div>
  </div>
</template>
<script>
import {
  price,
  createOrder,
  custom,
  city,
  autocomplete,
  createUser,
  passengerList,
} from "@/api/user";
// import format from "@/plugins/format.js";
import AMap from "AMap";
import storage from "store";

export default {
  data() {
    return {
      tipShows:true,
      ptip: false,
      tipShow: false,
      balanceShow: false,
      passengerNumber: "",
      usertips: false,
      userinfo: false,
      carPrices: "",
      cityname: "",
      citynames: "",
      hCityLists: "",
      cityList: [
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
        { value: "5", label: "5" },
        { value: "6", label: "6" },
        { value: "7", label: "7" },
        { value: "8", label: "8" },
        { value: "9", label: "9" },
        { value: "10", label: "10" },
        { value: "11", label: "11" },
        { value: "12", label: "12" },
        { value: "13", label: "13" },
        { value: "14", label: "14" },
        { value: "15", label: "15" },
        { value: "16", label: "16" },
        { value: "17", label: "17" },
        { value: "18", label: "18" },
        { value: "19", label: "19" },
        { value: "20", label: "20" },
        { value: "21", label: "21" },
        { value: "22", label: "22" },
        { value: "23", label: "23" },
        { value: "24", label: "24" },
        { value: "25", label: "25" },
        { value: "26", label: "26" },
        { value: "27", label: "27" },
        { value: "28", label: "28" },
        { value: "29", label: "29" },
        { value: "30", label: "30" },
        { value: "31", label: "31" },
        { value: "32", label: "32" },
        { value: "33", label: "33" },
        { value: "34", label: "34" },
        { value: "35", label: "35" },
        { value: "36", label: "36" },
        { value: "37", label: "37" },
        { value: "38", label: "38" },
        { value: "39", label: "39" },
        { value: "40", label: "40" },
        { value: "41", label: "41" },
        { value: "42", label: "42" },
        { value: "43", label: "43" },
        { value: "44", label: "44" },
        { value: "45", label: "45" },
        { value: "46", label: "46" },
        { value: "47", label: "47" },
        { value: "48", label: "48" },
        { value: "49", label: "49" },
        { value: "50", label: "50" },
      ],
      customList: [],
      lastTips: false,
      tips: false,
      locationData: {
        // 用于定位相关信息提交
        lat: "", // 纬度
        lon: "", // 经度
        province: "", // 省
        city: "", // 市
        district: "", // 区 县
        nowPlace: "", // 省-市-区
        Address: "", // 详细地址
      },
      current: 0,
      formItem: {
        single: true,
        destination: "",
        departure: "",
        port: "",
        carType: "五座商务车",
        date: "",
        time: "",
        people: "",
        name: "",
        phone: "",
        desc: "",
        items: [
          {
            value: "",
            index: 1,
            status: 0,
          },
        ],
      },
      ruleValidate: {
        date: [
          {
            required: true,
            type: "date",
            message: "请输入用车日期",
            trigger: "change",
          },
        ],
        time: [
          { required: true, message: "请输入用车时间", trigger: "change" },
        ],
        name: [
          { required: true, message: "请输入联系人姓名", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "请输入联系人电话", trigger: "blur" },
        ],
        people: [
          { required: true, message: "请输入乘车人数", trigger: "blur" },
        ],
        port: [{ required: true, message: "请输入口岸", trigger: "blur" }],
        // desc: [
        //   {
        //     required: true,
        //     message: "Please enter a personal introduction",
        //     trigger: "blur"
        //   },
        //   {
        //     type: "string",
        //     min: 20,
        //     message: "Introduce no less than 20 words",
        //     trigger: "blur"
        //   }
        // ],
        departure: [
          { required: true, message: "请输入出发地", trigger: "blur" },
        ],
        destination: [
          { required: true, message: "请输入目的地", trigger: "blur" },
        ],
      },
      index: 1,
      useDate: "",
      useTime: "",
      showPort: "",
      successLng: "",
      successLat: "",
      dest_lat: 22.241648,
      dest_lng: 114.169555,
      address: "",
      modelInformation: "",
      carPrice: "",
      timer: "",
      userId: "",
      balance: "",
      customId: "",
      agreement: false,
      is_in_mainland: 1,
      cityLists: "",
      isGetAddressList: false,
      departureCity: "",
      destinationCity: "",
      priceList: [],
      carImg: "",
      active: "",
      uname: "",
      unumber: "",
      uid: "",
      passenger: "",
      departLat: "",
      departLng: "",
      destLat: "",
      destLng: "",
    };
  },
  computed: {
    showItems: function () {
      return this.formItem.items.filter(function (items) {
        return items.status;
      });
    },
  },
  methods: {
    carChange(carType) {
      console.log("skdjflksdf", carType);
      this.formItem.carType = carType.service_name;
      this.carPrices = carType.price;
    },
    cartype(e) {
      console.log(e.target.dataset.item);
    },
    dateChange(date) {
      console.log(this.date);
      this.useDate = date;
      console.log(this.useDate);
    },
    timeChange(time) {
      this.useTime = time;
    },
    portChange(port) {
      // console.log(port)
      this.showPort = port;
      console.log(this.formItem.port);
      this.customId = this.formItem.port;
    },
    next(name) {
      console.log(this.passengerNumber);

      this.$refs[name].validate((valid) => {
        if (valid && this.passengerNumber != "") {
          console.log(11111);
          if (this.current < 3) {
            this.current += 1;
          }
          this.backTop();
          // this.$Message.success('Success!');
        } else {
          console.log(1111);
          this.$Message.error("Fail!");
        }
      });

      if (this.current == 0) {
        this.getCharterPrice();
      }
    },
    orderCar(priceItem) {
      this.selectPriceItem = priceItem;
      console.log(this.selectPriceItem.price);
      this.carImg = this.selectPriceItem.img;
      this.carPrices = this.selectPriceItem.price;
      this.current += 1;
    },
    prev() {
      if (this.current > 0) {
        this.current -= 1;
      }
      this.backTop();
    },
    confirm() {
      console.log(this.uid);
      console.log(this.carPrices);
      if (this.carPrices > this.balance) {
        this.balanceShow = !this.balanceShow;
      } else {
        if ((this.single = !this.single)) {
          this.agreement = !this.agreement;
        } else {
          if (this.current <= 2) {
            this.current = 3;
          }
          console.log(this.formItem);
          this.timer =
            this.useDate.match(/[0-9]+/g).join("-") + " " + this.useTime;
          console.log(this.timer);
          createOrder({
            dept_loc: this.citynames + " " + this.formItem.departure,
            dest_loc: this.cityname + " " + this.formItem.destination,
            dept_date_time: this.timer,
            route_id: this.route.id,
            passenger_name: this.uname,
            passenger_mobile: this.unumber,
            service_id: this.selectPriceItem.service_id,
            amount: this.carPrices,
            user_id: this.userId,
            order_no: "ENTERPRISE1231",
            custom_id: this.customId,
            type: 1,
            uid: this.uid,
          }).then((res) => {
            console.log(res.data.balance);
            storage.set("balance", res.data.balance);
            this.balance = res.data.balance;
          });
          this.lastTips = !this.lastTips;
        }
      }
    },
    mouseOver: function () {
      this.active = "border: 1px #2d8cf0 dashed";
    },
    mouseLeave: function () {
      this.active = "";
    },
    addUser() {
      this.userinfo = !this.userinfo;
    },
    finish() {
      this.current = 0;
    },
    handleAdd() {
      this.index++;
      this.formItem.items.push({
        value: "",
        index: this.index,
        status: 1,
      });
    },
    handleRemove(index) {
      this.showItems[index].status = 0;
    },
    backTop() {
      var timer = setInterval(function () {
        let osTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          osTop + ispeed;
        this.isTop = true;
        if (osTop === 0) {
          clearInterval(timer);
        }
      }, 30);
    },
    outinfo() {
      this.userinfo = !this.userinfo;
    },
    btnSubmit() {
      if (!this.uname && !this.uname) {
        this.tipShow = !this.tipShow;
      } else {
        createUser({
          user_mobile: this.unumber,
          user_id: this.userId,
          nick_name: this.uname,
        }).then((res) => {
          console.log(res.data.uid);
          this.uid = res.data.uid;
          console.log(this.uid);
        });
        this.userinfo = !this.userinfo;
      }
    },
    tipbtns() {
      this.tipShow = !this.tipShow;
    },
    userbtn() {
      this.usertips = !this.usertips;
    },
    userbtns() {
      this.balanceShow = !this.balanceShow;
      this.$router.push("/manage/recharge/online");
    },
    getLocation() {
      const self = this;
      AMap.plugin("AMap.Geolocation", function () {
        var geolocation = new AMap.Geolocation({
          enableHighAccuracy: true, // 是否使用高精度定位，默认:true
          timeout: 10000, // 超过10秒后停止定位，默认：无穷大
          maximumAge: 0, // 定位结果缓存0毫秒，默认：0
          convert: true, // 自动偏移坐标，偏移后的坐标为高德坐标，默认：true
        });

        geolocation.getCurrentPosition();
        AMap.event.addListener(geolocation, "complete", onComplete);
        AMap.event.addListener(geolocation, "error", onError);

        function onComplete(data) {
          // data是具体的定位信息
          // debugger
          console.log("定位成功信息：", data);
          self.successLng = data.position.lng;
          self.successLat = data.position.lat;
          self.address = data.formattedAddress;
          console.log("定位成功信息：", self.successLng);
          console.log("定位成功信息：", self.successLat);
          console.log("定位成功信息：", self.address);
        }

        function onError(data) {
          // debugger
          // 定位出错
          console.log("定位失败错误：", data);
          self.getLngLatLocation();
        }
      });
    },
    btn() {
      this.tips = !this.tips;
    },
    tipbtn() {
      this.lastTips = !this.lastTips;
    },
    orderList() {
      this.$router.push({ path: "/car/record/index" });
    },
    agreementBtn() {
      this.agreement = !this.agreement;
    },
    onSearchDepartureTips(value) {
      autocomplete({ content: value, city: this.departureCity }).then((res) => {
        this.departureTipList = [];
        this.departureTipList = res.data.tips;
        if (res.data.tips.length > 0) {
          this.isGetAddressList = true;
        }
      });
    },
    onSearchDestinationTips(value) {
      autocomplete({ content: value, city: this.destinationCity }).then(
        (res) => {
          this.destinationTipList = res.data.tips;
        }
      );
    },
    selectDepartureAddress(tip) {
      this.formItem.departure = tip.name;
      this.departureAddress = tip;
      this.departLat = Number(this.departureAddress.location.split(",")[0]);
      this.departLng = Number(this.departureAddress.location.split(",")[1]);
    },
    selectDestinationAddress(tip) {
      this.formItem.destination = tip.name;
      this.destinationAddress = tip;
      this.destLat = Number(this.destinationAddress.location.split(",")[0]);
      this.destLng = Number(this.destinationAddress.location.split(",")[1]);
      console.log(this.destLng);
    },
    getCharterPrice() {
      // const dept_latlng = this.departureAddress.location.split(',');
      // const dest_latlng = this.destinationAddress.location.split(',');
      const params = {
        dept_lat: this.departLng, //经纬度写反了
        dept_lng: this.departLat,
        dest_lat: this.destLng,
        dest_lng: this.destLat,
      };
      price(params).then((res) => {
        this.priceList = res.data.priceList;
        this.route = res.data.route;
      });
    },
    passengerChange(e) {
      this.uname = e.label;
      this.uid = event.target.dataset.uid;
      this.unumber = this.passengerNumber.replace(/[^0-9]/gi, "");
      console.log(this.passengerNumber.replace(/[^0-9]/gi, ""));
      console.log(event.target.dataset.uid);
    },
    closebtn(){
      this.$router.replace("/car/service/index")
      this.tipShows=! this.tipShows
    }
  },
  created() {
    this.userId = storage.get("user").id;
    this.getLocation();
    console.log(this.modelInformation);
    custom().then((res) => {
      console.log(res.data);
      this.customList = res.data;
    });
    const params = {
      is_in_mainland: this.is_in_mainland,
    };
    city(params).then((res) => {
      console.log(res.data);
      this.cityLists = res.data;
    });
    city({ is_in_mainland: 0 }).then((res) => {
      console.log(res.data);
      this.hCityLists = res.data;
    });

    passengerList({ user_id: this.userId }).then((res) => {
      console.log(res.data.data);
      this.passenger = res.data.data;
    });
    console.log(storage.get("balance"));
    this.balance = storage.get("balance");
  },
};
</script>
<style scoped>
@import "../../../style/common.css";

.layout {
  border: 1px solid #d7dde4;
  background: #fcfcfc;
  position: relative;
  border-radius: 4px;
  overflow: auto;
  height: 100%;
  position: relative;
}
/* .layout /deep/.ivu-form-item{
  margin-bottom: 18px;
} */

.step-form {
  /* background: url("../../../assets/stepform-background.png"); */
  border-radius: 4px;
  display: flex;
  align-items: center; /*垂直居中*/
  justify-content: center; /*水平居中*/
  width: 100%;
  height: 100px;
  margin-top: 50px;
}

.order-card {
  width: 1200px;
  margin: 30px auto;
}

.order-card .p {
  width: 886px;
  height: 51px;
  border-bottom: 1px solid #d7dde4;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: 0;
  padding-left: 30px;
  font-size: 15px;
  font-weight: bold;
}

.finish-card {
  width: 300px;
  height: auto;
  border-radius: 4px;
  margin: 0 auto;
}

.content {
  position: relative;
  top: 4px;
  flex: 1 1 auto;
  margin-left: 24px;
  line-height: 22px;
}

.inputOrderInfo {
  margin-top: 24px;
}

.confirmOrderInfo {
  margin-top: 24px;
}

.deployBtn {
  text-align: right;
  margin-top: 10px;
}

.priceDiv {
  background-color: #f7f7f8;
  font-weight: bold;
  font-size: xx-large;
  color: #ff5400;
  text-align: right;
  margin-top: 15px;
}

.img-responsive {
  display: inline-block;
  height: auto;
  max-width: 100%;
}

.order-finish-div {
  overflow: auto;
  height: 100%;
}

.selectCarService {
  display: flex;
  flex-direction: column;
  background-color: #f3f3f3;
}

.selectCar {
  display: flex;
  flex-direction: row;
}

.car-info {
  flex: 2;
  display: flex;
  flex-direction: row;
}

.car-img {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
 
}

.car-desc {
  flex: 2;
  flex-direction: column;
  display: flex;
  margin-left: 20px;
  align-items: flex-start;
}

.car-desc-item-wrapper {
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  justify-content: center;
  align-items: center;
}

.car-desc-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.car-action {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  padding: 12px;
}

.car-price-item {
  margin-right: 20px;
  font-size: 20px;
  font-weight: bold;
}

.car-order {
  background-color: #ff5400;
  padding: 6px 24px;
  margin-top: 20px;
  border-radius: 4px;
  color: white;
}

.searchAddressListMask {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  flex-direction: column;
}

.searchAddressListContainer {
  display: flex;
  justify-content: center;
  width: 730px;
  height: 200px;
  flex-direction: column;
  overflow-y: auto;
}

.tip-container {
  padding: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.item-header {
  font-size: 18px;
  font-weight: bold;
  padding: 20px 0px;
}

.p-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
}

.management-centerline {
  height: 18px;
  width: 3px;
  background-color: #ff5400;
  margin-right: 10px;
}

.input {
  width: 900px;
  height: 30px;
  border: 1px solid #d9d9d9;
}

.show {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 100000;
}

.lastTips {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 100000;
}

.showpay {
  width: 300px;
  height: 150px;
  background-color: #ffff;
  position: absolute;
  left: 500px;
  top: 300px;
  z-index: 100000;
}
.showpays {
  width: 400px;
  height: 180px;
  background-color: #ffff;
  position: absolute;
  left: 500px;
  top: 300px;
  z-index: 100000;
}

.showpays p {
  margin-top: 30px;
  font-size: 18px;
}
.showpay p {
  margin-top: 30px;
  font-size: 18px;
}

.showtip {
  width: 350px;
  height: 150px;
  background-color: #ffff;
  position: absolute;
  left: 500px;
  top: 300px;
  z-index: 100000;
  border-radius: 4px;
}

.showtip p {
  margin-top: 10px;
  font-size: 18px;
}

.showtip .tipBtn {
  margin: 20px auto 139px;
  width: 100px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  color: #fff;
  background: #f16622;
  border-radius: 2px;
  border: 1px solid #f16622;
  cursor: pointer;
}

.btn {
  margin: 45px auto 139px;
  width: 100px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  color: #fff;
  background: #f16622;
  border-radius: 2px;
  border: 1px solid #f16622;
  cursor: pointer;
}

.layout /deep/ .ivu-steps-item.ivu-steps-status-process .ivu-steps-head-inner {
  background-color: #ff5400;
  border: #ff5400;
  text-align: center;
}

.layout
  /deep/
  .ivu-steps-item.ivu-steps-status-finish
  .ivu-steps-head-inner
  span,
.ivu-steps-item.ivu-steps-status-finish
  .ivu-steps-head-inner
  > .ivu-steps-icon {
  color: #ff5400;
  border: #ff5400;
  text-align: center;
}

.layout /deep/ .ivu-steps-item.ivu-steps-status-finish .ivu-steps-head-inner {
  background-color: #fff;
  border-color: #ff5400;
}
.layout
  /deep/.ivu-steps-horizontal
  .ivu-steps-status-finish
  .ivu-steps-title:after {
  background-color: #ff5400;
}
.carPrices {
  display: flex;
  position: absolute;
  left: 389px;
  top: -6px;
}
.line {
  width: 100%;
  height: 1px;
  background-color: #d9d9d9;
  position: absolute;
  left: 0;
  top: 0px;
}
.user {
  width: 400px;
  height: 30px;
  border: 1px #d9d9d9 dashed;
  margin-left: 80px;
  margin-bottom: 20px;
  border-radius: 4px;
  color: #515a6e;
  text-align: center;
  line-height: 30px;
}
.show {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 100000;
}
.userinfo {
  width: 550px;
  height: 300px;
  background-color: white;
  margin: 120px auto;
  border-radius: 4px;
}
.userN {
  width: 100%;
  height: 48px;
  border-bottom: 1px solid rgb(217, 217, 217);
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  color: rgb(102, 102, 102);
}
.inputinfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}
.btnSubmit {
  width: 300px;
  height: 40px;
  background-color: rgb(255, 84, 0);
  color: white;
  margin: 50px auto;
  border: none;
  outline: none;
  border-radius: 4px;
  font-size: 16px;
}
.passenger {
  width: 800px;
  margin-left: 94px;
  margin-bottom: 10px;
  position: absolute;
  left: 517px;
  top: 206px;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  color: #515a6e;
  margin-top: 20px;
  align-items: center;
}
.passengerBox {
  display: flex;
  flex-direction: row;
}
.pline {
  width: 100%;
  height: 1px;
  background-color: #d9d9d9;
  margin-top: 10px;
}
</style>
