import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import iView from 'iview';
import 'iview/dist/styles/iview.css';
import VueQriously from 'vue-qriously';
import AmapVue from '@amap/amap-vue';
import moment from 'moment'


AmapVue.config.version = '2.0'; // 默认2.0，这里可以不修改
AmapVue.config.key = '您的JSAPI KEY';
AmapVue.config.plugins = [
  'AMap.ToolBar',
  'AMap.MoveAnimation',
  // 在此配置你需要预加载的插件，如果不配置，在使用到的时候会自动异步加载
];

Vue.config.productionTip = false;
Vue.use(iView);
Vue.use(VueQriously)
Vue.use(AmapVue);
Vue.prototype.$moment = moment


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
