<template>
  <div class="management-center">
    <div class="center-box">
      <div class="management-part">
        <span @click="func('A')" v-bind:class="{ active: active == 'A' }"
          >余额流水</span
        >
      </div>

      <div class="block block1" v-if="block == 'A'">
        <div class="availableBalance">
          <div class="p-box">
            <div class="management-centerline"></div>
            <p>可用余额(元)</p>
            <p
              style="color: #ff5400;font-size: 16px;margin-top:10px;position: absolute;left: 40px;margin-top: 20px;"
            >
              0
            </p>
          </div>
          <div class="invoice-brief">
            <div class="divp">
              <p>本金(元)</p>
              <p style="color: #9e9e9e;font-size: 16px;">0</p>
            </div>
            <div class="lines"></div>
            <div class="divps">
              <p>赠金(元)</p>
              <p style="color: #9e9e9e;font-size: 16px;">0</p>
            </div>
            <div class="lines"></div>
            <div class="divps">
              <p>待支付金额(元)</p>
              <p style="color: #9e9e9e;font-size: 16px;">0</p>
            </div>
          </div>
        </div>
        <div class="line"></div>
        <div class="cartime">
          <p>操作时间</p>
          <row>
            <i-col span="12">
              <Date-picker
                type="date"
                :options="options3"
                placeholder="选择日期"
                style="width: 155px"
              ></Date-picker>
            </i-col>

            <i-col span="12">
              <Date-picker
                type="date"
                :options="options4"
                placeholder="选择日期"
                style="width: 155px"
              ></Date-picker>
            </i-col>
          </row>
        </div>
        <div style=" display: flex;flex-direction: row;">
          <button class="btn">查询</button>
          <button class="btns">导出</button>
        </div>
        <i-table border :columns="columns1" style="margin-top:20px"></i-table>
      </div>
    </div>
       <div class="show" v-show="showinfo">
        <div class="invoicedivinfo">
         <p>暂时未开通</p>
         <button @click="outinfo">知道了</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ManageCenter",
  data() {
    return {
       showinfo:true,
      active: "A",
      block: "A",
      columns1: [
        {
          title: "操作时间",
          key: "name"
        },
        {
          title: "类型",
          key: "age"
        },
        {
          title: "备注",
          key: "address"
        },
        {
          title: "本金变动(元)",
          key: "name"
        },
        {
          title: "赠金变动(元)",
          key: "age"
        },
        {
          title: "本金结欲(元)",
          key: "address"
        },
        {
          title: "赠金结余(元)",
          key: "name"
        },
        {
          title: "开票金额(元)",
          key: "age"
        },
        {
          title: "账户结余(元)",
          key: "address"
        }
      ],
      data1: []
    };
  },
  methods: {
     outinfo(){
      this.showinfo=!this.showinfo
      this.$router.push({ path: "/manage/center" });
    },
  }
};
</script>

<style scoped>
@import "../../../style/common.css";
.management-center {
  width: 1000px;
  height: 1200px;
  margin: 100px auto;
  position: relative;
}
.center-box {
  width: 100%;
  height: 660px;
  display: flex;
  flex-direction: column;
  margin-top: 80px;
}

.management-container {
  height: 604px;
  width: 998px;
  border: 1px solid gainsboro;
}
.management-part {
  display: flex;
  justify-content: flex-start;
    cursor: pointer;
}
.show {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 100000;
}
.management-part span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 146px;
  height: 56px;
  border: 1px solid #d8dde9;
  margin-left: 0;
  text-align: center;
  line-height: 146cpx;
  color: #999;
  background-color: #f3f4f5;
  border-bottom: 1px solid #d8dde9;
  z-index: 100;
  font-size: 16px;
}

.management-part .active {
  background-color: white;
  color: #424456;
  border-bottom: none;
}

.block {
  width: 998px;
  min-height: 604px;
  border: 1px solid #ddd;
  position: absolute;
     top: 135px;
  display: flex;
  justify-content: flex-start;
  color: #151515;
  flex-direction: column;
}
.availableBalance {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 30px;
}
.management-centerline {
  height: 18px;
  width: 3px;
  background-color: #ff5400;
  margin-right: 10px;
}
.p-box {
  display: flex;
  margin-left: 18px;
}
.invoice-brief {
  width: 488px;
  height: 64px;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 100px;
}
.lines {
  height: 40px;
  width: 1px;
  background-color: #e9e9e9;
}
.divp {
  margin-left: 50px;
  margin-right: 50px;
}
.divps {
  margin-left: 50px;
  margin-right: 50px;
}
.line {
  width: 95%;
  height: 1px;
  background-color: #ddd;
  margin: 20px auto;
}
.cartime {
  width: 343px;
  height: 57px;
  margin-left: 18px;
}
.cartime p {
  text-align: left;
  margin-bottom: 5px;
  font-size: 12px;
  color: #666;
}
.btn {
  width: 98px;
  height: 34px;
  background: #f16622;
  border: 1px solid #f16622;
  border-radius: 2px;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  margin-top: 30px;
  margin-left: 18px;
}
.btns {
  width: 98px;
  height: 34px;
  background: #f16622;
  border: 1px solid #f16622;
  border-radius: 2px;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  margin-top: 30px;
  margin-left: 750px;
}
.invoicedivinfo p {
  font-weight: bolder;
  font-size: 14px;
  text-align: left;
text-align: center;
  margin-top: 15px;
}
.invoicedivinfo{
   width: 300px;
  height: 100px;
  background-color: white;
  position: absolute;
  top: 260px;
  left: 500px;
}
.invoicedivinfo button{
  width: 98px;
  height: 34px;
  background: #f16622;
  border: 1px solid #f16622;
  border-radius: 2px;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  margin-top: 20px;
  outline: none;
}
</style>
