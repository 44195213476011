<template>
  <div class="header-container">
    <div class="menu-container">
      <div class="icon" @click="logo"></div>
      <div class="outLogin" v-show="outLogin" @mouseleave="mobileLeave">
        <p class="outText" @click="outText">退出登录</p>
      </div>
      <Menu mode="horizontal" :theme="theme1" :active-name="activeIndex">
        <MenuItem name="1" to="/daycharter/charters/index">
          在线下单
        </MenuItem>
        <MenuItem name="2" to="/daycharter/order/index">
          订单记录
        </MenuItem>
        <MenuItem name="3" to="/rule/service/index">
          客服中心
        </MenuItem>
        <MenuItem name="4" to="/rule/charge/index">
          收费标准
        </MenuItem>
      </Menu>
      <div class="df-rc action">
        <div class="df-rc user-mobile" @mouseenter="mobile">
          <Icon type="ios-contact" size="30" style="margin-right: 12px"/>
          <span class="mobile">{{ login_mobile }}</span>
        </div>
        <router-link to="/manage/index">
          <Button type="warning">进入管理中心</Button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import storage from "store";

export default {
  name: "car-module-header",
  props: {
    active: String
  },
  data() {
    return {
      login_mobile: "",
      outLogin: false,
      activeIndex:3,
    };
  },
  methods: {
    mobile() {
      console.log(1111);
      this.outLogin = true;
    },
    mobileLeave() {
      this.outLogin = false;
    },
    outText() {
      storage.remove("user");
      this.$router.replace("/Login");
    },
    logo() {
      this.$router.replace("/manage/center");
    }
  },

  created() {
    this.login_mobile = storage.get("user").login_mobile;
    console.log(this.login_mobile);
  },

  watch: {
    active(newData){
      this.activeIndex = newData;
    }
  }
};
</script>

<style scoped>
@import "../../style/common.css";

.header-container {
}

.menu-container {
  display: flex;
  flex-direction: row;
  background-color: #242527;
  justify-content: space-around;
}

.icon {
  width: 20vw;
  background: url("../../assets/logo.png") no-repeat center center;
}

.action {
  width: 20vw;
}

.user-mobile {
  padding: 20px;
  font-size: 16px;
  color: white;
}

.menu-container /deep/ .ivu-menu {
  background-color: #242527;
  flood-color: #42b983;
}

.menu-container
/deep/
.ivu-menu-light.ivu-menu-horizontal
.ivu-menu-item:hover {
  color: white;
  border-bottom: 2px solid #ff5400;
  font-weight: bold;
}

.menu-container
/deep/
.ivu-menu-light.ivu-menu-horizontal
.ivu-menu-submenu:hover {
  color: white;
  border-bottom: 2px solid #ff5400;
  font-weight: bold;
}

.menu-container /deep/ .ivu-menu-light.ivu-menu-horizontal .ivu-menu-item {
  color: #929293;
  font-weight: bold;
  border-bottom: 2px solid #242527;
}

.menu-container /deep/ .ivu-menu-light.ivu-menu-horizontal .ivu-menu-submenu {
  color: #929293;
  font-weight: bold;
  border-bottom: 2px solid #242527;
}

.menu-container
/deep/
.ivu-menu-light.ivu-menu-horizontal
.ivu-menu-item-active {
  color: white;
  border-bottom: 3px solid #ff5400;
}

.mobile {
  cursor: pointer;
}

.outLogin {
  width: 80px;
  height: 50px;
  background-color: #fff;
  position: absolute;
  right: 250px;
  top: 42px;
  box-shadow: 0 2px 6px 0 rgba(66, 68, 86, 0.12);
  z-index: 100000;
}

.outText {
  margin-top: 10px;
  cursor: pointer;
}

.outText:hover {
  color: #ff5400;
}
</style>
