<template>
  <div class="management-center">
    <div class="center-box">
      <div class="management-part">
        <span @click="func('A')" v-bind:class="{ active: active == 'A' }"
          >订单查询</span
        >
        <!-- <span @click="func('B')" v-bind:class="{ active: active == 'B' }"
          >跨境车</span
        > -->
      </div>
      <div class="block block1" v-if="block == 'A'">
        <div class="input">
          <p>用车日期</p>

          <Date-picker
          v-model="date"
            type="date"
            :options="options3"
            placeholder="选择日期"
            style="width: 155px"
          ></Date-picker>

          <!-- <Date-picker
            type="time"
            :options="options4"
            placeholder="选择日期"
            style="width: 155px"
          ></Date-picker> -->

          <p>订单号</p>
          <i-input
            :value.sync="value"
            style="width: 132px; height: 34px"
          ></i-input>
          <p>订单状态</p>
          <i-select v-model="model1" style="width: 180px" @on-change="orderTypechange">
            <i-option v-for="item in orderType" :value="item.value" :key="item.value">{{
              item.label
            }}</i-option>
          </i-select>
          <p>订单类型</p>
          <i-select v-model="model2" style="width: 180px" @on-change="useorderChange">
            <i-option v-for="item in useorder" :value="item.value" :key="item.value">{{
              item.label
            }}</i-option>
          </i-select>
        </div>
         <div class="input">
           <p>订单跨境</p>
          <i-select v-model="model3" style="width: 180px" @on-change="orderuseChange">
            <i-option v-for="item in orderuse" :value="item.value" :key="item.value">{{
              item.label
            }}</i-option>
          </i-select>
         </div>
        
        <button class="btn" @click="orderBtn">查询</button>
        <i-table border  :columns="columns1" :data="orderLists" style="margin-top: 20px"></i-table>
        <div class="show" v-show="show">
          <div class="textdiv">
            <div class="p">充值规则</div>
            <div class="line"></div>
            <div class="textruls">
              <p>1.企业充值所获优惠券，系统将即时发放至其企业账户；</p>
              <p>2.优惠券有效期以企业账户实际收到优惠券之日起算；</p>

              <p>
                3.充值金额仅支持一次性全部退还，赠金及优惠券对应金额不支持申请退款
                。
                如需申请退款，您可以拨打货拉拉企业版客服热线：400-878-3636进行咨询与申请。退款额=充值金额-（已使用的本金金额+已使用的赠金/优惠券对应的金额）；退款额将在退款申请通过后7-20工作日内原路退还。一旦退款成功后，
                货拉拉企业版APP的可用余额及优惠券将同时被清零；
              </p>

              <p>
                4.企业在下单过程中，若有货拉拉认定的违规行为（包括但不限于下单时备注产品推销类信息、发布虚假订单或存在任何其他扰乱平台秩序的行为），货拉拉有权从企业账户中直接扣除已使用优惠券等额款项、扣收已支付订单费用20%款项作为违约金，并对企业进行封号处理；
              </p>

              <p>
                5.进行充值则表示贵司已经同意了<span>《充值协议》</span>，充值前请仔细阅读；如您不同意该充值协议，请勿予以充值；
              </p>
              <p>6.在法律法规允许的范围内，货拉拉对上述规则享有解释权。</p>
              <p></p>
            </div>
            <div class="bottomdiv">
              <button class="button">我知道了</button>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="block block1" v-if="block == 'B'">
        <div class="input">
          <p>用车日期</p>

          <Date-picker
            type="date"
            :options="options3"
            placeholder="选择日期"
            style="width: 155px;margin-right:10px"
          ></Date-picker>

          <Date-picker
            type="date"
            :options="options4"
            placeholder="选择日期"
            style="width: 155px"
          ></Date-picker>

          <p>订单号</p>
          <i-input
            :value.sync="value"
            style="width: 132px; height: 34px"
          ></i-input>
          <p>订单状态</p>
          <i-select :model.sync="model1" style="width: 180px">
            <i-option v-for="item in orderType" :key="item.value">{{
              item.label
            }}</i-option>
          </i-select>
        </div>
        <button class="btn">查询</button>
        <i-table border :columns="columns1" style="margin-top: 20px"></i-table>
        <div class="show" v-show="show">
          <div class="textdiv">
            <div class="p">充值规则</div>
            <div class="line"></div>
            <div class="textruls">
              <p>1.企业充值所获优惠券，系统将即时发放至其企业账户；</p>
              <p>2.优惠券有效期以企业账户实际收到优惠券之日起算；</p>

              <p>
                3.充值金额仅支持一次性全部退还，赠金及优惠券对应金额不支持申请退款
                。
                如需申请退款，您可以拨打货拉拉企业版客服热线：400-878-3636进行咨询与申请。退款额=充值金额-（已使用的本金金额+已使用的赠金/优惠券对应的金额）；退款额将在退款申请通过后7-20工作日内原路退还。一旦退款成功后，
                货拉拉企业版APP的可用余额及优惠券将同时被清零；
              </p>

              <p>
                4.企业在下单过程中，若有货拉拉认定的违规行为（包括但不限于下单时备注产品推销类信息、发布虚假订单或存在任何其他扰乱平台秩序的行为），货拉拉有权从企业账户中直接扣除已使用优惠券等额款项、扣收已支付订单费用20%款项作为违约金，并对企业进行封号处理；
              </p>

              <p>
                5.进行充值则表示贵司已经同意了<span>《充值协议》</span>，充值前请仔细阅读；如您不同意该充值协议，请勿予以充值；
              </p>
              <p>6.在法律法规允许的范围内，货拉拉对上述规则享有解释权。</p>
              <p></p>
            </div>
            <div class="bottomdiv">
              <button class="button">我知道了</button>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import moment from "moment";
import storage from "store";
import { orderList } from "@/api/user";
export default {
  name: "ManageCenter",
  data() {
    return {
      active: "A",
      block: "A",
      show: false,
      model1:'',
      model2:'',
      model3:'',
      orderLists:[],
      orderType: [
        {
          value: 1,
          label: "全部",
        },
        {
          value: 2,
          label: "已完成",
        },
        {
          value: 3,
          label: "已取消",
        },
      ],
       useorder: [
        {
          value: 1,
          label: "单程用车",
        },
        {
          value: 2,
          label: "市内包车",
        },
        {
          value: 3,
          label: "城际包车",
        },
      ],
       orderuse: [
        {
          value: 0,
          label: "国内用车",
        },
        {
          value: 1,
          label: "跨境用车",
        },
      ],

      columns1: [
       {
          title: "订单号",
          key: "order_no"
        },
        {
          title: "用车时间",
          key: "dept_date_time"
        },
        {
          title: "车型",
          key: "car_name"
        },
        {
          title: "出发地",
          key: "dept_address"
        },
         {
          title: "目的地",
          key: "dest_address"
        },
        {
          title: "订单金额(元)",
          key: "amount"
        },
        {
          title: "操作",
          key: "passenger_name"
        }
      ],
      data1: [],
      date:'',
      time:''
    };
  },
  
  methods: {
    orderTypechange(){
      console.log(this.model1)
    },
    useorderChange(){
      console.log(this.model2)

    },
    orderuseChange(){
      console.log(this.model3)

    },
    orderBtn(){
    this.date=moment(this.date).format("YYYY-MM-DD")
    const that = this;
    console.log(that.userId);
    const params = {
      user_id:storage.get("user").id,
      // status: that.status1,
      // order_type:1,
      date:that.date,
      order_type:that.model2,
      status:that.model1,
      is_cross_border:that.model3

    };
      orderList(params).then(res => {
          // const data=res.data.data
      // data.forEach(item => item.carName = item.car_service.desc)
      this.orderLists=res.data.data 
    });
    },
    func(m) {
      this.active = m;
      this.block = m;
    },
  },
};
</script>

<style scoped>
@import "../../../style/common.css";
.management-center {
  width: 1000px;
  height: 900px;
  margin: 100px auto;
  position: relative;
}
.center-box {
  width: 100%;
  height: 660px;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}

.management-container {
  height: 604px;
  width: 998px;
  border: 1px solid gainsboro;
}
.management-part {
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
}

.management-part span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 146px;
  height: 56px;
  border: 1px solid #d8dde9;
  margin-left: 0;
  text-align: center;
  line-height: 146cpx;
  color: #999;
  background-color: #f3f4f5;
  border-bottom: 1px solid #d8dde9;
  z-index: 100;
  font-size: 16px;
}

.management-part .active {
  background-color: white;
  color: #424456;
  border-bottom: none;
}

.block {
  width: 998px;
  min-height: 604px;
  border: 1px solid #ddd;
  position: absolute;
  top: 104px;
  display: flex;
  justify-content: flex-start;
  color: #151515;
  flex-direction: column;
}
.availableBalance {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 30px;
}
.management-centerline {
  height: 18px;
  width: 3px;
  background-color: #ff5400;
  margin-right: 10px;
}
.p-box {
  display: flex;
  margin-left: 18px;
}
.invoice-brief {
  width: 488px;
  height: 64px;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 100px;
}
.lines {
  height: 40px;
  width: 1px;
  background-color: #e9e9e9;
}
.divp {
  margin-left: 50px;
  margin-right: 50px;
}
.divps {
  margin-left: 50px;
  margin-right: 50px;
}
.line {
  width: 100%;
  height: 1px;
  background-color: #ddd;
  margin-top: 10px;
}
.cartime {
  width: 943px;
  height: 57px;
  margin-left: 18px;
  display: flex;
  flex-direction: row;
  background-color: #ff5400;
}
.cartime p {
  text-align: left;
  margin-bottom: 5px;
  font-size: 12px;
  color: #666;
}
.btn {
  width: 98px;
  height: 34px;
  background: #f16622;
  border: 1px solid #f16622;
  border-radius: 2px;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  margin-top: 30px;
  margin-left: 18px;
  line-height: 34px;
}
.btns {
  width: 98px;
  height: 34px;
  background: #f16622;
  border: 1px solid #f16622;
  border-radius: 2px;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  margin-top: 30px;
  margin-left: 750px;
}
.input {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  height: 60px;
  line-height: 30px;
}
.input p {
  font-size: 14px;
  margin-left: 20px;
  margin-right: 10px;
}
.inputs {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  margin-top: 10px;
}
.name {
  width: 132px;
  height: 57px;
  display: flex;
  flex-direction: column;
  margin-left: 18px;
}
.name p {
  text-align: left;
  margin-bottom: 5px;
  font-size: 12px;
  color: #666;
}

.tel {
  width: 132px;
  height: 57px;
  margin-right: 50px;
  margin-left: 18px;
}
.tel p {
  text-align: left;
  margin-bottom: 5px;
  font-size: 12px;
  color: #666;
}
.carbm {
  width: 180px;
  height: 57px;
  margin-left: 18px;
}
.carbm p {
  text-align: left;
  margin-bottom: 5px;
  font-size: 12px;
  color: #666;
}
.carbm select {
  width: 180x;
  height: 34px;
  outline: none;
  border: 1px solid #ddd;
}
.cartime {
  width: 343px;
  height: 57px;
  margin-left: 18px;
}
.cartime p {
  text-align: left;
  margin-bottom: 5px;
  font-size: 12px;
  color: #666;
}
.show {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 100000;
}
.textdiv {
  width: 650px;
  height: 350px;
  background-color: white;
  position: absolute;
  top: 100px;
  left: 400px;
}
.textdiv .p {
  display: flex;
  justify-content: flex-start;
  margin-left: 18px;
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
}
.textruls {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.textruls p {
  text-align: left;
  margin-left: 18px;
  margin-right: 18px;
}
.textruls span {
  color: #ff5400;
}
.bottomdiv {
  width: 100%;
  height: 55px;
  border-top: 1px solid #ddd;
  margin-top: 45px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.button {
  width: 98px;
  height: 34px;
  background: #f16622;
  border: 1px solid #f16622;
  border-radius: 2px;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  margin-right: 18px;
}
</style>
