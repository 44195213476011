<template>
  <div class="management-center">
    <div class="center-box">
      <div class="p-box">
        <div class="management-centerline"></div>
        <p>订单记录</p>
      </div>
      <div class="management-part">
        <span @click="func('A')" v-bind:class="{ active: active == 'A' }"
          >全部订单</span
        >
        <span @click="func('B')" v-bind:class="{ active: active == 'B' }"
          >进行中</span
        >
        <span @click="func('C')" v-bind:class="{ active: active == 'C' }"
          >已完成</span
        >
        <span @click="func('D')" v-bind:class="{ active: active == 'D' }"
          >已取消</span
        >
      </div>
      <div class="block block1" v-if="block == 'A'">
        <!-- <i-table
          border
          :on-row-click="canel"
          :columns="columns1"
          :data="data1"
          style=" margin-top: 20px;"
        ></i-table> -->

        <Table
          border
          :columns="columns1"
          :data="historyData1"
          style="margin-bottom: 20px"
        ></Table>
        <Page
          :total="dataCount1"
          :page-size="pageSize1"
          show-sizer
          class="paging1"
          @on-change="changepage1"
          @on-page-size-change="pagesize1"
        ></Page>
      </div>
      <div class="block block1" v-if="block == 'B'">
        <Table
          border
          :columns="columns1"
          :data="historyData2"
          style="margin-bottom: 20px"
        ></Table>
        <Page
          :total="dataCount2"
          :page-size="pageSize2"
          show-sizer
          class="paging2"
          @on-change="changepage2"
          @on-page-size-change="pagesize2"
        ></Page>
      </div>
      <div class="block block1" v-if="block == 'C'">
        <Table
          border
          :columns="columns1"
          :data="historyData3"
          style="margin-bottom: 20px"
        ></Table>
        <Page
          :total="dataCount3"
          :page-size="pageSize3"
          show-sizer
          class="paging3"
          @on-change="changepage3"
          @on-page-size-change="pagesize3"
        ></Page>
      </div>
      <div class="block block1" v-if="block == 'D'">
        <Table
          border
          :columns="columns1"
          :data="historyData4"
          style="margin-bottom: 20px"
        ></Table>
        <Page
          :total="dataCount4"
          :page-size="pageSize4"
          show-sizer
          class="paging4"
          @on-change="changepage4"
          @on-page-size-change="pagesize4"
        ></Page>
      </div>
    </div>
  </div>
</template>

<script>
import { orderList } from "@/api/user";
import storage from "store";
export default {
  name: "RecordIndex",

  data() {
    return {
      ajaxHistoryData1: [],
      // 初始化信息总条数
      dataCount1: 0,
      // 每页显示多少条
      pageSize1: 10,
      // 当前页码
      page1: 1,
      historyData1: [],

      ajaxHistoryData2: [],
      // 初始化信息总条数
      dataCount2: 0,
      // 每页显示多少条
      pageSize2: 10,
      // 当前页码
      page2: 1,
      historyData2: [],

      ajaxHistoryData3: [],
      // 初始化信息总条数
      dataCount3: 0,
      // 每页显示多少条
      pageSize3: 10,
      // 当前页码
      page3: 1,
      historyData3: [],

      ajaxHistoryData4: [],
      // 初始化信息总条数
      dataCount4: 0,
      // 每页显示多少条
      pageSize4: 10,
      // 当前页码
      page4: 1,
      historyData4: [],

      userId: "",
      active: "A",
      block: "A",
      columns1: [
        {
          title: "订单号",
          key: "order_no",
        },
        {
          title: "用车时间",
          key: "dept_date_time",
        },
        {
          title: "车型",
          key: "car_name",
        },
        {
          title: "出发地",
          key: "dept_loc",
        },
        {
          title: "目的地",
          key: "dest_loc",
        },
        {
          title: "套餐类型",
          key: "combo_name",
        },
        {
          title: "订单金额(元)",
          key: "amount",
        },
        {
          title: "乘车人信息",
          key: "passenger_name",
        },
      ],
      data1: [],
      data2: [],
      data3: [],
      data4: [],

      status1: 1,
      status2: 2,
      status3: 3,
      status4: 4,
      carName: "",
    };
  },
  methods: {
    // 获取历史记录信息
    handleListApproveHistory1() {
      // 保存取到的所有数据
      this.ajaxHistoryData1 = this.data1;
      this.dataCount1 = this.data1.length;
      // 初始化显示，小于每页显示条数，全显，大于每页显示条数，取前每页条数显示
      if (this.data1.length < this.pageSize1) {
        this.historyData1 = this.ajaxHistoryData1;
      } else {
        this.historyData1 = this.ajaxHistoryData1.slice(0, this.pageSize1);
      }
    },
    changepage1(index) {
      // 当前页码
      this.page1 = index;
      let _start = (index - 1) * this.pageSize1;
      let _end = index * this.pageSize1;
      this.historyData1 = this.ajaxHistoryData1.slice(_start, _end);
    },
    pagesize1(index) {
      let _start = (this.page1 - 1) * index;
      let _end = this.page1 * index;
      this.historyData1 = this.ajaxHistoryData1.slice(_start, _end);
      // 当前展示条数
      this.pageSize1 = index;
    },

    // 获取历史记录信息
    handleListApproveHistory2() {
      // 保存取到的所有数据
      this.ajaxHistoryData2 = this.data2;
      this.dataCount2 = this.data2.length;
      // 初始化显示，小于每页显示条数，全显，大于每页显示条数，取前每页条数显示
      if (this.data2.length < this.pageSize2) {
        this.historyData2 = this.ajaxHistoryData2;
      } else {
        this.historyData2 = this.ajaxHistoryData2.slice(0, this.pageSize2);
      }
    },
    changepage2(index) {
      // 当前页码
      this.page2 = index;
      let _start = (index - 1) * this.pageSize2;
      let _end = index * this.pageSize2;
      this.historyData2 = this.ajaxHistoryData2.slice(_start, _end);
    },
    pagesize2(index) {
      let _start = (this.page2 - 1) * index;
      let _end = this.page2 * index;
      this.historyData2 = this.ajaxHistoryData2.slice(_start, _end);
      // 当前展示条数
      this.pageSize2 = index;
    },

    // 获取历史记录信息
    handleListApproveHistory3() {
      // 保存取到的所有数据
      this.ajaxHistoryData3 = this.data3;
      this.dataCount3 = this.data3.length;
      // 初始化显示，小于每页显示条数，全显，大于每页显示条数，取前每页条数显示
      if (this.data3.length < this.pageSize3) {
        this.historyData3 = this.ajaxHistoryData3;
      } else {
        this.historyData3 = this.ajaxHistoryData3.slice(0, this.pageSize3);
      }
    },
    changepage3(index) {
      // 当前页码
      this.page3 = index;
      let _start = (index - 1) * this.pageSize3;
      let _end = index * this.pageSize3;
      this.historyData3 = this.ajaxHistoryData3.slice(_start, _end);
    },
    pagesize3(index) {
      let _start = (this.page3 - 1) * index;
      let _end = this.page3 * index;
      this.historyData3 = this.ajaxHistoryData3.slice(_start, _end);
      // 当前展示条数
      this.pageSize3 = index;
    },

    // 获取历史记录信息
    handleListApproveHistory4() {
      // 保存取到的所有数据
      this.ajaxHistoryData4 = this.data4;
      this.dataCount4 = this.data4.length;
      // 初始化显示，小于每页显示条数，全显，大于每页显示条数，取前每页条数显示
      if (this.data4.length < this.pageSize4) {
        this.historyData4 = this.ajaxHistoryData4;
      } else {
        this.historyData4 = this.ajaxHistoryData4.slice(0, this.pageSize4);
      }
    },
    changepage4(index) {
      // 当前页码
      this.page4 = index;
      let _start = (index - 1) * this.pageSize4;
      let _end = index * this.pageSize4;
      this.historyData4 = this.ajaxHistoryData4.slice(_start, _end);
    },
    pagesize4(index) {
      let _start = (this.page4 - 1) * index;
      let _end = this.page4 * index;
      this.historyData4 = this.ajaxHistoryData4.slice(_start, _end);
      // 当前展示条数
      this.pageSize4 = index;
    },
    func: function (m) {
      this.active = m;
      this.block = m;
    },
    canel() {
      alert("取消");
    },
  },
  created() {
    this.userId = storage.get("user").id;
    const that = this;
    console.log(that.userId);
    const params = {
      user_id: that.userId,
      status: that.status1,
      order_type: 1,
      is_cross_border: 0,
    };
    orderList(params).then((res) => {
      console.log(res.data.data);
      this.data1 = res.data.data;
      this.handleListApproveHistory1();
    });
    const params1 = {
      user_id: that.userId,
      status: that.status2,
      order_type: 1,
      is_cross_border: 0,
    };
    orderList(params1).then((res) => {
      console.log(res.data.data);
      this.data2 = res.data.data;
      this.handleListApproveHistory2();
    });
    const params2 = {
      user_id: that.userId,
      status: that.status3,
      order_type: 1,
      is_cross_border: 0,
    };
    orderList(params2).then((res) => {
      console.log(res.data.data);
      this.data3 = res.data.data;
      this.handleListApproveHistory3();
    });
    const params3 = {
      user_id: that.userId,
      status: that.status4,
      order_type: 1,
      is_cross_border: 0,
    };
    orderList(params3).then((res) => {
      console.log(res.data.data);
      this.data4 = res.data.data;
      this.handleListApproveHistory4();
    });
  },
};
</script>

<style scoped>
@import "../../../style/common.css";
.management-center {
  width: 1000px;
  height: 1200px;
  margin: 60px auto;
  position: relative;
}
.center-box {
  width: 100%;
  height: 660px;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}
.p-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
}
.management-centerline {
  height: 18px;
  width: 3px;
  background-color: #ff5400;
  margin-right: 10px;
}
.management-container {
  height: 1200px;
  width: 998px;
  border: 1px solid gainsboro;
}
.management-part {
  display: flex;
  justify-content: flex-start;
  margin-top: 14px;
  cursor: pointer;
}
.management-part div {
  display: inline-block;
  width: 150px;
  height: 34px;
  line-height: 34px;
  background-color: hsla(0, 0%, 100%, 0.9);
  border-radius: 2px;
  border: 1px solid #f16622;
  color: #f16622;
  text-align: center;
  cursor: pointer;
  margin-top: 10px;
}
.management-partdiv {
  margin-left: 370px;
  margin-right: 30px;
}

.management-part span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 146px;
  height: 56px;
  border: 1px solid #d8dde9;
  margin-left: 0;
  text-align: center;
  line-height: 146cpx;
  color: #999;
  background-color: #f3f4f5;
  border-bottom: 1px solid #d8dde9;
  z-index: 100;
  font-size: 16px;
}

.management-part .active {
  background-color: white;
  color: #424456;
  border-bottom: none;
}

.block {
  width: 1100px;
  min-height: 1200px;
  border: 1px solid #ddd;
  position: absolute;
  top: 146px;
  display: flex;
  justify-content: flex-start;
  color: #151515;
  flex-direction: column;
}

.recharge-rules {
  display: flex;

  align-items: center;
  position: absolute;
  right: 190px;
  top: 170px;
  color: #2fa0ed;
  cursor: pointer;
}
.recharge-rules img {
  width: 16px;
  height: 14px;
}
.titlebox {
  margin-top: 20px;
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.line {
  width: 450px;
  height: 1px;
  background-color: #d9d9d9;
}
.linebox {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.linebox p {
  font-size: 16px;
  font-weight: bold;
  margin-left: 10px;
  margin-right: 10px;
}
</style>
