import { render, staticRenderFns } from "./search.vue?vue&type=template&id=56ba0a36&scoped=true&"
import script from "./search.vue?vue&type=script&lang=js&"
export * from "./search.vue?vue&type=script&lang=js&"
import style0 from "./search.vue?vue&type=style&index=0&id=56ba0a36&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.9.6@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56ba0a36",
  null
  
)

export default component.exports