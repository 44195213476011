import request from '@/utils/request'

const userApi = {
    getCode: '/auth/code',
    login: '/auth/login',
    qrcode:'/recharge/new',
    recharge:'/recharge/record',
    price:'service/charter/price',
    invoice:'invoice/create',
    coupon:'recharge/coupon/items',
    createOrder:'order/create',
     orderList:'/order/list',
     custom:'/custom/customs',
     verify:'company/verify',
     city:'region/charter_cities',
    addressTips:'map/autocomplete',
    wallet:'wallet/coupons',
    createUser:'/user/create_normal',
    passengerList:'user/passenger_list',
    result:'/recharge/result' ,
    agreement: 'recharge/agreement'

}


/**
 * mobile 手机号
 * code 验证码
 * @param data
 */
export function userLogin(data) {
    return request({
        url: userApi.login,
        method: 'post',
        data
    })
}

/**
 * mobile 手机号
 * @param params
 */

export function userGetCode(params) {
    return request({
        url: userApi.getCode,
        method: 'get',
        params
    })
}
export function qrcode(data) {
    return request({
        url: userApi.qrcode,
        method: 'post',
        data
    })
}
export function recharge(params) {
    return request({
        url: userApi.recharge,
        method: 'get',
        params
    })
}

export function price(params) {
    return request({
        url: userApi.price,
        method: 'get',
        params
    })
}
export function invoice(data) {
    return request({
        url: userApi.invoice,
        method: 'post',
        data
    })
}
export function coupon(params) {
    return request({
        url: userApi.coupon,
        method: 'get',
        data:params
    })
}

export function createOrder(data) {
    return request({
        url: userApi.createOrder,
        method: 'post',
        data
    })
}

export function orderList(params) {
    return request({
        url: userApi.orderList,
        method: 'get',
        params
    })
}

export function custom(params) {
    return request({
        url: userApi.custom,
        method: 'get',
        data:params
    })
}


export function verify(data) {
    return request({
        url: userApi.verify,
        method: 'post',
        data
    })
}

export function city(params) {
    return request({
        url: userApi.city,
        method: 'get',
        params
    })
}

export function autocomplete(params) {
    return request({
        url: userApi.addressTips,
        method: 'get',
        params
    })
}

export function wallet(params) {
    return request({
        url: userApi.wallet,
        method: 'get',
        params
    })
}

export function createUser(data) {
    return request({
        url: userApi.createUser,
        method: 'post',
        data
    })
}

export function passengerList(params) {
    return request({
        url: userApi.passengerList,
        method: 'get',
        params
    })
}

export function result(params) {
    return request({
        url: userApi.result,
        method: 'get',
        params
    })
}

export function agreement(params) {
    return request({
        url: userApi.agreement,
        method: 'get',
        params
    })
}