<template>
    <div class="header-container">
        <div class="menu-container">
            <div class="icon" @click="logo"></div>
              <div class="outLogin" v-show="outLogin"  @mouseleave="mobileLeave">
                  <p  class="outText" @click="outText">退出登录</p>
              </div>
            <Menu mode="horizontal" :theme="theme1" active-name="1">
                <MenuItem name="1" to="/manage/center">
                    管理中心
                </MenuItem>
                <MenuItem name="2" to="/manage/recharge/online">
                    充值
                </MenuItem>
                <Submenu name="3">
                    <template slot="title">
                        财务
                    </template>
                    <MenuItem name="3-1" to="/manage/invoice/apply">发票管理</MenuItem>
                    <MenuItem name="3-2" to="/manage/water/balance">流水记录</MenuItem>
                    <MenuItem name="3-3" to="/manage/coupon/index">券管理</MenuItem>
                </Submenu>
                <Submenu name="4">
                    <template slot="title">
                        企业管理
                    </template>
                    <MenuItem name="4-1" to="/manage/verify/submit">企业认证</MenuItem>
                </Submenu>
                <MenuItem name="5" to="/manage/order/search">
                    订单查询
                </MenuItem>
            </Menu>
            <div class="df-rc action">
                <div class="df-rc user-mobile" @mouseenter="mobile" >
                  <Icon type="ios-contact" size="30" style="margin-right: 12px"/>
                  <span class="mobile" >{{ login_mobile }}</span>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import storage from "store";
    // @ is an alias to /src
    export default {
    name: "car-module-header",
    data() {
      return {
        login_mobile: "",
        outLogin:false,
    };
  },
   methods:{
       mobile(){
           console.log(1111)
           this.outLogin=true
       },
       mobileLeave(){
         this.outLogin=false
       },
       outText(){
           storage.remove("user")
           this.$router.replace("/Login")
       },
       logo(){
         this.$router.replace("/manage/center");
      },
   },

  created() {
    this.login_mobile = storage.get("user").login_mobile;
    console.log(this.login_mobile);
  }
    };
</script>

<style scoped>
    @import "../../style/common.css";

    .header-container {

    }

    .menu-container {
        display: flex;
        flex-direction: row;
        background-color: #242527;
        justify-content: space-around;
        position: fixed;
        top: 0;
        z-index: 10000;
        width: 100%;
    }

    .icon {
        width: 20vw;
        background: url("../../assets/logo.png") no-repeat center center;
    }

    .action {
        width: 20vw;
    }

    .user-mobile {
        padding: 20px;
        font-size: 16px;
        color: white;
    }

    .menu-container /deep/ .ivu-menu {
        background-color: #242527;
        flood-color: #42b983;
    }

    .menu-container /deep/ .ivu-menu-light.ivu-menu-horizontal .ivu-menu-item:hover {
        color: white;
        border-bottom: 2px solid #ff5400;
        font-weight: bold;
    }

    .menu-container /deep/ .ivu-menu-light.ivu-menu-horizontal .ivu-menu-submenu:hover {
        color: white;
        border-bottom: 2px solid #ff5400;
        font-weight: bold;
    }

    .menu-container /deep/ .ivu-menu-light.ivu-menu-horizontal .ivu-menu-item {
        color: #929293;
        font-weight: bold;
        border-bottom: 2px solid #242527;
    }

    .menu-container /deep/ .ivu-menu-light.ivu-menu-horizontal .ivu-menu-submenu {
        color: #929293;
        font-weight: bold;
        border-bottom: 2px solid #242527;
    }


    .menu-container /deep/ .ivu-menu-light.ivu-menu-horizontal .ivu-menu-item-active {
        color: white;
        border-bottom: 3px solid #ff5400;
    }
    .mobile{
        cursor: pointer;
    }
    .outLogin{
        width: 80px;
        height: 50px;
        background-color: #fff;
        position: absolute;
        right: 170px;
        top:42px;
          box-shadow: 0 2px 6px 0 rgba(66, 68, 86, 0.12);
    }
     .outText{
       margin-top:10px;
         cursor: pointer;
    }
      .outText:hover{
       color:#ff5400;

    }
</style>
