import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "@/pages/Login.vue";
import ManageIndex from "@/pages/manage/index.vue";
import ManageCenter from "@/pages/manage/center/index.vue"; //企业管理首页
import CouponIndex from "@/pages/manage/coupon/index.vue"; //优惠券
import InvoiceApply from "@/pages/manage/invoice/apply.vue"; //发票管理
import Ordinary from "@/pages/manage/ordinary/ordinary.vue"; //发票管理普通
import Special from "@/pages/manage/special/special.vue"; //发票管理专票
import RechargeOnline from "@/pages/manage/recharge/online.vue"; //充值管理
import VerifySubmit from "@/pages/manage/verify/submit.vue"; //企业认证
import WaterBalance from "@/pages/manage/water/balance.vue"; //流水记录
import OrderSearch from "@/pages/manage/order/search.vue"; //订单搜索
import CarIndex from "@/pages/car/index.vue"; //跨境车模块
import DaycharterIndex from "@/pages/daycharter/index.vue"; //境内日包车模块
import CarOrder from "@/pages/car/order/index.vue"; //在线下单
import ChargeIndex from "@/pages/car/charge/index.vue"; //收费标准
import RecordIndex from "@/pages/car/record/index.vue"; //订单记录
import ServiceIndex from "@/pages/car/service/index.vue"; //客服中心
import Charters from "@/pages/daycharter/charters/index.vue"; //包车页面
import Charterindex  from "@/pages/daycharter/charterindex/index.vue"; //包车页面
import DayCharterOrderIndex  from "@/pages/daycharter/record/index.vue"; //国内包车订单页面


import RuleIndex  from "@/pages/rule/index.vue"; //客服中心
import RuleCharge  from "@/pages/rule/charge/index.vue"; //客服中心
import RuleService  from "@/pages/rule/service/index.vue"; //收费标准





Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name:Home,
      redirect:'/login'
    },
    {
        path: "/login",
        name: "Login",
        component: Login
    },
    {
        path: "/manage/index",
        name: "manage",
        component: ManageIndex,
        children: [
            {
                path: "/manage/center",
                name: "manage_center",
                component: ManageCenter
            }, {
                path: "/manage/recharge/online",
                name: "manage_recharge_online",
                component: RechargeOnline
            }, {
                path: "/manage/invoice/apply",
                name: "manage_invoice_apply",
                component: InvoiceApply
            }, {
                path: "/manage/water/balance",
                name: "manage_water_balance",
                component: WaterBalance
            }, {
                path: "/manage/coupon/index",
                name: "manage_coupon",
                component: CouponIndex
            }, {
                path: "/manage/verify/submit",
                name: "manage_verify_submit",
                component: VerifySubmit
            }, {
                path: "/manage/order/search",
                name: "manage_order_search",
                component: OrderSearch
            },
            {
                path: "/manage/ordinary/ordinary",
                name: "manage_ordinary",
                component: Ordinary
            },
            {
                path: "/manage/special/special",
                name: "manage_special",
                component: Special
            }
        ]
    }, {
        path: "/car/index",
        name: "car_index",
        component: CarIndex,
        children: [{
            path: "/car/record/index",
            name: "car_record_index",
            component: RecordIndex
        }, {
            path: "/car/order/index",
            name: "car_order_index",
            component: CarOrder
        }, {
            path: "/car/charge/index",
            name: "car_charge_index",
            component: ChargeIndex
        }, {
            path: "/car/service/index",
            name: "car_service_index",
            component: ServiceIndex
        },
        ]
    },{
        path: "/daycharter/index",
        name: "daycharter",
        component: DaycharterIndex,
        children: [{
            path: "/daycharter/charters/index",
            name: "daycharter_charters_index",
            component: Charters
        }, {
            path: "/daycharter/charterindex/index",
            name: "daycharter_charterindex_index",
            component: Charterindex
        }, {
                path: "/daycharter/order/index",
                name: "daycharter_order_index",
                component: DayCharterOrderIndex
            },
        ]
    },{
        path: "/rule/index",
        name: "rule",
        component: RuleIndex,
        children: [{
            path: "/rule/charge/index",
            name: "rule_charge_index",
            component: RuleCharge
        }, {
            path: "/rule/service/index",
            name: "rule_service_index",
            component: RuleService
        }]
    }
];
const router = new VueRouter({
    mode: "hash",
    base: process.env.BASE_URL,
    routes
});
export default router;
