<template>
    <div class="container">
        <rule-index-header :active="active_key"></rule-index-header>
        <router-view/>
        <manage-footer></manage-footer>
    </div>
</template>

<script>
    import RuleIndexHeader from '@/components/Header/RuleIndex';
    import ManageFooter from '@/components/Footer';


    // @ is an alias to /src
    export default {
        name: "CarIndex",
        components: {
            RuleIndexHeader,
            ManageFooter
        },
        data() {
          return {
            active_key:2
          }
        },
        mounted() {
            this.active_key = this.$route.query.key;
            this.$router.push({
                path: '/rule/service/index'
            });
        }
    };
</script>

<style scoped>
    @import "../../style/common.css";

    .menu-container {
        display: flex;
        flex-direction: row;
        background-color: #242527;
        justify-content: space-around;
    }

    .icon {
        width: 20vw;
        background: url("../../assets/logo.png") no-repeat center center;
    }

    .action {
        width: 20vw;
    }

    .user-mobile {
        padding: 20px;
        font-size: 16px;
        color: white;
    }

    .menu-container /deep/ .ivu-menu {
        background-color: #242527;
        flood-color: #42b983;
    }

    .menu-container /deep/ .ivu-menu-light.ivu-menu-horizontal .ivu-menu-item:hover {
        color: white;
        border-bottom: 2px solid #ff5400;
        font-weight: bold;
    }

    .menu-container /deep/ .ivu-menu-light.ivu-menu-horizontal .ivu-menu-submenu:hover {
        color: white;
        border-bottom: 2px solid #ff5400;
        font-weight: bold;
    }

    .menu-container /deep/ .ivu-menu-light.ivu-menu-horizontal .ivu-menu-item {
        color: #929293;
        font-weight: bold;
        border-bottom: 2px solid #242527;
    }

    .menu-container /deep/ .ivu-menu-light.ivu-menu-horizontal .ivu-menu-submenu {
        color: #929293;
        font-weight: bold;
        border-bottom: 2px solid #242527;
    }


    .menu-container /deep/ .ivu-menu-light.ivu-menu-horizontal .ivu-menu-item-active {
        color: white;
        border-bottom: 3px solid #ff5400;
    }
</style>
