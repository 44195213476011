<template>
  <div class="Management-center">
    <div class="name">
      <p style="margin-right:10px">企业资质认证</p>
      <div v-show="authentication">未认证</div>
    </div>
    <!-- <div class="id">
      <p>企业ID</p>
      <span>{{unified_social_credit_code}}</span>
    </div> -->
    <div class="enterpriseName">
      <span>*</span>
      <p>企业名称</p>
      <i-input
        v-model="enterpriseName"
        placeholder="请填写营业执照上的名称"
        style="width: 300px"
      ></i-input>
    </div>
    <div class="enterprisecode">
      <span>*</span>
      <p>统一社会信用代码</p>
      <i-input
        v-model="socialCode"
        placeholder="请填写社会信用代码"
        style="width: 300px"
      ></i-input>
    </div>
    <div class="upload">
      <span>*</span>
      <p>上传营业执照复印件</p>
      <Upload multiple action="http://api.enterprise.ylt100.cn/api/recharge/upload/depositCertImg" :on-success="handleSuccess">
        <i-button type="ghost" icon="ios-cloud-upload-outline"
          >上传文件</i-button
        >
      </Upload>
      <div class="uploadtext">
        注：请上传【营业执照】或【企业机构代码证】的
        复印件，并且必须加盖红色企业公章；
        <!-- <span @click="rechargeRecord" style="cursor: pointer;">查看示例</span> -->
      </div>
    </div>
    <div class="textReminder">
      <p>1、请上传加盖企业公章（红章）的营业执照复印件照片；</p>
      <p>2、营业执照名称需与企业名称相同；</p>
      <p>3、营业执照的详细信息需清晰可见，并承诺图片内容真实有效；</p>
      <p>4、图片格式为JPEG、JPG、PNG,并且小于10M；</p>
    </div>
    <button class="btn" @click="submit">提交</button>
    <div class="show" v-show="show">
      <div class="textdiv">
        <img src="../../../assets/out.png" alt @click="out" />
      </div>
    </div>
  </div>
</template>

<script>
import storage from "store";
import { verify} from "@/api/user";

export default {
  name: "Submit",
  data() {
    return {
      show: false,
      url:'',
      enterpriseName:'',
      userId:'',
      socialCode:"",
      authentication:true,
      unified_social_credit_code:''
    };
  },
  methods: {
    rechargeRecord: function() {
      this.show = !this.show;
    },
    out: function() {
      this.show = !this.show;
    },
    handleSuccess(){
         this.url = JSON.parse(event.currentTarget.response).code.url;
    },
    submit(){
      verify({
          name:this.enterpriseName,
    business_license:this.url,
    id:this.userId,
    unified_social_credit_code:this.socialCode
         
        }).then(res => {
          console.log(res.data.unified_social_credit_code);
         
          storage.set('user', res.data)
          
          storage.set('corporateName', res.data.name)
          console.log( storage.get('user').name)
        });
    }
  },
 created() {
    this.userId = storage.get("user").id;
     this.unified_social_credit_code=storage.get("user").unified_social_credit_code
    if(this.userId!=null){
    this.authentication=false
    }
  },
};
</script>

<style scoped>
@import "../../../style/common.css";
.Management-center {
  width: 1000px;
  height: 611px;
  border: 1px solid gainsboro;
  margin: 150px auto;
  display: flex;
  flex-direction: column;
}
.name {
  width: 100%;
  height: 67px;
  border-bottom: 1px solid gainsboro;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.name p {
  margin-left: 20px;
  line-height: 67px;
  font-size: 15px;
  font-weight: bold;
}
.name div {
  margin-right: 20px;
  height: 18px;
  line-height: 18px;
  border-radius: 2px;
  border: 1px solid #9e9e9e;
  font-size: 12px;
  color: #9e9e9e;
}
.id {
  display: flex;
  flex-direction: row;
  margin-left: 238px;
  margin-top: 50px;
  font-size: 16px;
}
.id span {
  margin-left: 10px;
}
.id p {
  margin-right: 18px;
}
.enterpriseName {
  display: flex;
  flex-direction: row;
  margin-left: 206px;
  margin-top: 40px;
  font-size: 16px;
  align-items: center;
}
.enterpriseName span {
  margin-top: 20px;
  color: red;
  margin-right: 10px;
}
.enterpriseName p {
  margin-right: 26px;
}

.enterprisecode {
  display: flex;
  flex-direction: row;
  margin-left: 143px;
  margin-top: 20px;
  font-size: 16px;
  align-items: center;
}
.enterprisecode span {
  margin-top: 10px;
  color: red;
  margin-right: 10px;
}
.enterprisecode p {
  margin-right: 26px;
}
.upload {
  display: flex;
  flex-direction: row;
  margin-left: 127px;
  margin-top: 20px;
  font-size: 16px;
  align-items: center;
}
.upload span {
  margin-top: 10px;
  color: red;
  margin-right: 10px;
}
.upload p {
  margin-right: 25px;
}
.uploadtext {
  width: 280px;
  height: 54px;
  font-size: 12px;
  margin-left: 10px;
}
.textReminder {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 314px;
  margin-top: 20px;
}
.btn {
  width: 98px;
  height: 34px;
  background: #f16622;
  border: 1px solid #f16622;
  border-radius: 2px;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  margin-top: 40px;
  margin-left: 308px;
}
.show {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 100000;
}
.textdiv {
  width: 696px;
  height: 630px;
  background-color: white;
  position: fixed;
  right: 0;
  top: 64px;
}
.textdiv img {
  position: absolute;
  left: -31px;
  top: 20px;
}
</style>