<template>
  <div class="management-center">
    <div class="center-box">
      <div class="p-box">
        <div class="management-centerline"></div>
        <p>收费标准</p>
      </div>
      <div class="management-part">
        <span @click="func('A')" v-bind:class="{ active: active == 'A' }"
          >收费标准</span
        >
        <!-- <span @click="func('B')" v-bind:class="{ active: active == 'B' }"
          >中巴车</span
        >
        <span @click="func('C')" v-bind:class="{ active: active == 'C' }"
          >大巴车</span
        > -->
      </div>
      <div class="block block1" v-if="block == 'A'">
      <div class="texts">
        <p>1、系统标准计价</p>
        <p>用车预估费用由平台计价系统，根据用户填写的订单信息进行报价；报价依据路线、用车时间、司机服务费等</p>
        <p>2、订单支付金额包含</p>
        <p>实际行驶中产生的燃油费、路桥费、高速费、司机服务费、车辆使用费</p>
        <p>3、订单支付金额可选择包含</p>
        <p>行程中若因用车需求产生司机餐费、多日行程中的住宿费、途中停车费等费用，在线下支付或安排 </p>
        <p>4、订单支付金额 = 预付款+尾款</p>
        <p>5、全程保证无任何隐形消费</p>


      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServiceIndex",
  data() {
    return {
      active: "A",
      block: "A"
      // nowIndex: 0,
    };
  },
  methods: {
    func: function(m) {
      this.active = m;
      this.block = m;
    }
  }
};
</script>

<style scoped>
@import "../../../style/common.css";
.management-center {
  width: 1000px;
  height: 1200px;
  margin: 60px auto;
  position: relative;
}
.center-box {
  width: 100%;
  height: 660px;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}
.p-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
}
.management-centerline {
  height: 18px;
  width: 3px;
  background-color: #ff5400;
  margin-right: 10px;
}
.management-container {
  height: 1200px;
  width: 998px;
  border: 1px solid gainsboro;
}
.management-part {
  display: flex;
  justify-content: flex-start;
  margin-top: 14px;
  cursor: pointer;
}
.management-part div {
  display: inline-block;
  width: 150px;
  height: 34px;
  line-height: 34px;
  background-color: hsla(0, 0%, 100%, 0.9);
  border-radius: 2px;
  border: 1px solid #f16622;
  color: #f16622;
  text-align: center;
  cursor: pointer;
  margin-top: 10px;
}
.management-partdiv {
  margin-left: 370px;
  margin-right: 30px;
}

.management-part span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 146px;
  height: 56px;
  border: 1px solid #d8dde9;
  margin-left: 0;
  text-align: center;
  line-height: 146cpx;
  color: #999;
  background-color: #f3f4f5;
  border-bottom: 1px solid #d8dde9;
  z-index: 100;
  font-size: 16px;
}

.management-part .active {
  background-color: white;
  color: #424456;
  border-bottom: none;
}

.block {
  width: 998px;
  min-height: 1200px;
  border: 1px solid #ddd;
  position: absolute;
  top: 146px;
  display: flex;
  justify-content: flex-start;
  color: #151515;
  flex-direction: column;
}

.recharge-rules {
  display: flex;

  align-items: center;
  position: absolute;
  right: 190px;
  top: 170px;
  color: #2fa0ed;
  cursor: pointer;
}
.recharge-rules img {
  width: 16px;
  height: 14px;
}
.titlebox {
  margin-top: 20px;
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.line {
  width: 450px;
  height: 1px;
  background-color: #d9d9d9;
}
.linebox {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.linebox p {
  font-size: 16px;
  font-weight: bold;
  margin-left: 10px;
  margin-right: 10px;
}

.texts{
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 20px;
  margin-left: 40px;
  font-size: 16px;
}
</style>
