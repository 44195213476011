<template>
  <div class="management-center">
    <!-- 单程页面 -->
    <div class="center-part" v-show="oneway">
      <Card style="width: 101%; height: 280px" dis-hover>
        <p style="text-align: left">行程信息</p>
        <div class="tripinfo">
          <div class="inputinfo">
            <p
                style="
                color: red;
                margin-left: 10px;
                font-size: 18px;
                margin-top: 10px;
              "
            >
              *
            </p>
            <p style="margin-right: 10px; font-size: 12px; font-weight: bold">
              上车地点
            </p>
            <Select
                v-model="departureCity"
                style="width: 100px"
                placeholder="请选择城市"
            >
              <Option
                  v-for="item in cityLists"
                  :value="item.city_name"
                  :key="item.id"
              >{{ item.city_name }}
              </Option>
            </Select>
            <AutoComplete
                v-model="departure"
                :data="data1"
                @on-search="onSearchDepartureTips"
                placeholder="去哪儿接您"
                style="width: 180px; margin-left: 10px"
            >
              <div class="searchAddressListMask">
                <Icon type="ios-loading" v-if="!isGetAddressList"/>
                <div v-else class="searchAddressListContainer">
                  <div
                      v-for="tip in departureTipList"
                      :key="tip.id"
                      class="tip-container"
                      @click="selectDepartureAddress(tip)"
                  >
                    <Icon type="ios-navigate-outline"/>
                    <span>{{ tip.name }}</span>
                    <div class="line"></div>
                  </div>
                </div>
              </div>
            </AutoComplete>
          </div>
          <div class="inputinfo">
            <p
                style="
                color: red;
                margin-left: 20px;
                font-size: 18px;
                margin-top: 10px;
              "
            >
              *
            </p>
            <p style="margin-right: 10px; font-size: 12px; font-weight: bold">
              下车地点
            </p>
            <Select
                v-model="outDepartureCity"
                style="width: 180px"
                placeholder="请选择城市"
            >
              <Option
                  v-for="item in cityLists"
                  :value="item.city_name"
                  :key="item.id"
              >{{ item.city_name }}
              </Option>
            </Select>
            <AutoComplete
                v-model="departure2"
                @on-search="outonSearchDepartureTips"
                placeholder="去哪儿送您"
                style="width: 200px; margin-left: 10px"
            >
              <div class="searchAddressListMask">
                <Icon type="ios-loading" v-if="!outisGetAddressList"/>
                <div v-else class="searchAddressListContainer">
                  <div
                      v-for="tip in outdepartureTipList"
                      :key="tip.id"
                      class="tip-container"
                      @click="outselectDepartureAddress(tip)"
                  >
                    <Icon type="ios-navigate-outline"/>
                    <span>{{ tip.name }}</span>
                    <div class="line"></div>
                  </div>
                </div>
              </div>
            </AutoComplete>
          </div>
          <div class="inputinfo">
            <p
                style="
                color: red;
                margin-left: 10px;
                font-size: 18px;
                margin-top: 10px;
              "
            >
              *
            </p>
            <p style="margin-right: 10px; font-size: 14px">用车日期</p>
            <DatePicker
                v-model="date"
                format="yyyy年MM月dd日"
                @on-change="dateChange"
                placeholder="请选择日期"
                style="width: 150px"
                :options="options3"
            ></DatePicker>
            <TimePicker
                @on-change="timeChange"
                v-model="time"
                format="HH:mm"
                placeholder="请选择时间"
                style="width: 100px; margin-left: 10px"
                :disabled-hours="disabledHours"
            ></TimePicker>
          </div>
          <button class="btn" @click="onebtn">重新查询</button>
        </div>
        <div class="lines"></div>
        <p style="text-align: left; margin-top: 20px">车型</p>
        <div class="model">
          <RadioGroup v-model="onecharterCar" @on-change="onecheckcarType">
            <Radio
                v-for="(item,index) in price_list"
                :value="index"
                :key="index"
                :label="index"
                size="large">
              <span>{{ item.car_package_name }}</span>
            </Radio>
          </RadioGroup>
        </div>
        <!--        <div class="lines"></div>-->
        <!--        <p style="text-align: left; margin-top: 20px">服务商</p>-->
        <!--        <div class="model">-->
        <!--          <Checkbox v-model="onesingle" size="large" @on-change="onesinglecheck"-->
        <!--          >不限-->
        <!--          </Checkbox>-->

        <!--          <CheckboxGroup-->
        <!--              v-model="oneserviceProvider"-->
        <!--              @on-change="oneserviceCheckcar">-->
        <!--            <Checkbox-->
        <!--                v-for="item in onecharterCarFleet"-->
        <!--                :value="item.id"-->
        <!--                :key="item.id"-->
        <!--                :label="item.id"-->
        <!--                size="large">-->
        <!--              <span>{{ item.name }}</span>-->
        <!--            </Checkbox>-->
        <!--          </CheckboxGroup>-->
        <!--        </div>-->
      </Card>
      <Card
          dis-hover
          style="margin-top: 10px; width: 101%"
          v-for="item in car_prices"
          :value="item.id"
          :key="item.id"
          :label="item.id"
      >
        <div class="modelPrices">
          <div
              style="
              width: 200px;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center;
            "
          >
            <img :src="item.img" alt="" style="width: 100px; height: 80px"/>
            <p
                style="
                margin-left: 10px;
                text-align: left;
                font-weight: bold;
                font-size: 16px;
              "
            >
              {{ item.name }}
            </p>
            <!-- <div style="display: flex; flex-direction: row; margin-top: 5px">
              <img
                src="../../../assets/hgs.png"
                alt=""
                style="width: 20px; height: 20px; margin-left: 10px"
              />
              <p style="margin-left: 10px">5</p>
              <p style="margin-left: 10px">2008条评论</p>
            </div> -->
          </div>
          <div style="width: 500px; height: 100%; margin-left: 30px">
            <div style="display: flex; flex-direction: row">
              <p style="font-size: 18px; font-weight: bold">
                {{ item.car_name }}
              </p>
            </div>
            <div style="display: flex; flex-direction: row; margin-top: 20px">
              <img
                  src="../../../assets/txs.png"
                  alt=""
                  style="width: 20px; height: 20px; margin-left: 10px"
              />
              <span style="margin-left: 10px">{{ item.seat_count }}</span>
              <img
                  src="../../../assets/xls.png"
                  alt=""
                  style="width: 20px; height: 20px; margin-left: 10px"
              />
              <span style="margin-left: 10px">{{ item.luggage_num }}</span>

            </div>
            <p style="position: absolute; top: 120px; left: 200px">
              接送时间24小时前免费取消
            </p>
          </div>
          <div
              style="
              width: 1px;
              height: 130px;
              background-color: #d1d1d1;
              margin-left: 500px;
            "
          ></div>
          <div
              style="
              width: 200px;
              height: 100%;
              display: flex;
              flex-direction: column;
            "
          >
            <span
                style="
                font-size: 16px;
                color: #ff5400;
                font-weight: bold;
                margin-left: 30px;
              "
            >￥{{ item.price }}</span
            >
            <p style="font-size: 12px; margin-left: 30px">
              含税及其他费用
              <!-- 共有<span style="color: #ff5400; font-weight: bold">3</span
              >个报价  -->
            </p>
            <button
                style="
                color: #ffff;
                font-size: 12px;
                margin-top: 20px;
                width: 100px;
                height: 30px;
                background-color: #ff5400;
                outline: none;
                border: none;
                margin-left: 49px;
                border-radius: 4px;
              "
                :data-price="item.price"
                :data-price_id="item.price_id"
                :data-car_id="item.id"
                @click="checkbtn($event)"
            >
              立即预定(单程)
            </button>
          </div>
        </div>
      </Card>
      <Spin fix v-if="spinShow">
       <div class="demo-spin-icon-load">
         <i class="iconfont icon-loader--line"></i>
       </div>
       <div style='font-size:16px' >Loading...</div>
      </Spin>
    </div>

    <!-- 套餐页面 -->
    <div class="center-part" v-show="setmeal">
      <Card dis-hover style="width: 101%; height: 380px">
        <p style="text-align: left">行程信息</p>
        <div class="tripinfo">
          <div class="inputinfo">
            <p
                style="
                color: red;
                margin-left: 10px;
                font-size: 18px;
                margin-top: 10px;
              "
            >
              *
            </p>
            <p style="margin-right: 10px; font-size: 12px; font-weight: bold">
              上车地点
            </p>
            <Select
                v-model="departureCity"
                style="width: 100px"
                placeholder="请选择城市"
            >
              <Option
                  v-for="item in cityLists"
                  :value="item.city_name"
                  :key="item.id"
              >{{ item.city_name }}
              </Option>
            </Select>
            <AutoComplete
                v-model="departure"
                :data="data1"
                @on-search="onSearchDepartureTips"
                placeholder="去哪儿接您"
                style="width: 200px; margin-left: 10px"
            >
              <div class="searchAddressListMask">
                <Icon type="ios-loading" v-if="!isGetAddressList"/>
                <div v-else class="searchAddressListContainer">
                  <div
                      v-for="tip in departureTipList"
                      :key="tip.id"
                      class="tip-container"
                      @click="selectDepartureAddress(tip)"
                  >
                    <Icon type="ios-navigate-outline"/>
                    <span>{{ tip.name }}</span>
                    <div class="line"></div>
                  </div>
                </div>
              </div>
            </AutoComplete>
          </div>
          <div class="inputinfo">
            <p
                style="
                color: red;
                margin-left: 10px;
                font-size: 18px;
                margin-top: 10px;"
            >
              *
            </p>
            <p style="margin-right: 10px; font-size: 14px">用车日期</p>
            <DatePicker
                v-model="date"
                format="yyyy年MM月dd日"
                @on-change="dateChange"
                placeholder="请选择日期"
                style="width: 150px"
                :options="options3"
            ></DatePicker>
            <TimePicker
                @on-change="timeChange"
                v-model="time"
                format="HH:mm"
                placeholder="请选择时间"
                style="width: 100px; margin-left: 10px"
                :disabled-hours="disabledHours"
            ></TimePicker>
          </div>
          <div class="inputinfo" v-show="usedays">
            <p
                style="
                color: red;
                margin-left: 10px;
                font-size: 18px;
                margin-top: 10px;
              "
            >
              *
            </p>
            <p style="margin-right: 10px; font-size: 14px">用车天数</p>
            <Select
                v-model="carDays"
                style="width: 150px"
                placeholder="用车天数"
                @on-change="carDay"
            >
              <Option
                  v-for="item in dateList"
                  :value="item.value"
                  :key="item.id"
              >{{ item.label }}
              </Option>
            </Select>
          </div>
          <button class="btn" @click="charteredRequery">重新查询</button>
        </div>
        <div class="lines"></div>
        <p style="text-align: left; margin-top: 20px">套餐</p>
        <div class="model">
          <RadioGroup v-model="selectedPackage" size="large" @on-change="carPackage">
            <Radio
                v-for="(item,index) in charterCarPackage"
                :value="index"
                :key="index"
                :label="index"
            >{{ item.name }}
            </Radio
            >
          </RadioGroup>
        </div>
        <div class="lines"></div>
        <p style="text-align: left; margin-top: 20px">车型</p>
        <div class="model">
        <RadioGroup v-model="charterCar" @on-change="checkcarType">
            <Radio
                v-for="(item,index) in price_list"
                :value="index"
                :key="index"
                :label="index"
                size="large">
              <span>{{ item.car_package_name }}</span>
            </Radio>
          </RadioGroup>
          <!-- <Checkbox v-model="single1" size="large" @on-change="singlecheck1"
          >不限
          </Checkbox
          >
          <CheckboxGroup v-model="charterCar" @on-change="checkcarType">
            <Checkbox
                v-for="item in charterCartype"
                :value="item.id"
                :key="item.id"
                :label="item.id"
                size="large"
            >
              <span>{{ item.name }}</span>
            </Checkbox>
          </CheckboxGroup> -->
        </div>
        <!-- <div class="lines"></div>
         <p style="text-align: left; margin-top: 20px">服务商</p>
        <div class="model">
          <Checkbox v-model="single" size="large" @on-change="singlecheck"
          >不限
          </Checkbox
          >

          <CheckboxGroup v-model="serviceProvider" @on-change="serviceCheckcar">
            <Checkbox
                v-for="item in charterCarFleet"
                :value="item.id"
                :key="item.id"
                :label="item.id"
                size="large"
            >
              <span>{{ item.name }}</span>
            </Checkbox>
          </CheckboxGroup>
        </div> -->
      </Card>
      <Card
          dis-hover
          style="margin-top: 10px; width: 101%"
          v-for="item in car_prices"
          :value="item.id"
          :key="item.id"
          :label="item.id"
      >
        <div class="modelPrices">
          <div
              style="
              width: 200px;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center;
            "
          >
            <img :src="item.img" alt="" style="width: 100px; height: 80px"/>
            <p
                style="
                margin-left: 10px;
                text-align: left;
                font-weight: bold;
                font-size: 14px;
              "
            >
              {{ item.name }}
            </p>
            <!-- <div style="display: flex; flex-direction: row; margin-top: 5px">
              <img
                src="../../../assets/hgs.png"
                alt=""
                style="width: 20px; height: 20px; margin-left: 10px"
              />
              <p style="margin-left: 10px">5</p>
              <p style="margin-left: 10px">2008条评论</p>
            </div> -->
          </div>
          <div style="width: 500px; height: 100%; margin-left: 30px">
            <div style="display: flex; flex-direction: row">
              <p style="font-size: 18px; font-weight: bold">
                {{ item.car_name }}
              </p>
            </div>
            <div style="display: flex; flex-direction: row; margin-top: 20px">
              <img
                  src="../../../assets/txs.png"
                  alt=""
                  style="width: 20px; height: 20px; margin-left: 10px"
              />
              <span style="margin-left: 10px">{{ item.seat_count }}</span>
              <img
                  src="../../../assets/xls.png"
                  alt=""
                  style="width: 20px; height: 20px; margin-left: 10px"
              />
              <span style="margin-left: 10px">{{ item.luggage_num }}</span>

            </div>
            <p style="position: absolute; top: 120px; left: 200px">
              接送时间24小时前免费取消
            </p>
          </div>
          <div
              style="
              width: 1px;
              height: 130px;
              background-color: #d1d1d1;
              margin-left: 500px;
            "
          ></div>
          <div
              style="
              width: 200px;
              height: 100%;
              display: flex;
              flex-direction: column;
            "
          >
            <span
                style="
                font-size: 16px;
                color: #ff5400;
                font-weight: bold;
                margin-left: 30px;
              "
            >￥{{ item.price }}</span
            >
            <p style="font-size: 12px; margin-left: 30px">
              含税及其他费用
              <!-- 共有<span style="color: #ff5400; font-weight: bold">3</span
              >个报价  -->
            </p>
            <button
                style="
                color: #ffff;
                font-size: 12px;
                margin-top: 20px;
                width: 100px;
                height: 30px;
                background-color: #ff5400;
                outline: none;
                border: none;
                margin-left: 49px;
                border-radius: 4px;
              "
                :data-price="item.price"
                :data-service_id="item.service_id"
                :data-price_id="item.price_id"
                :data-coo_id="item.coo_id"
                :data-car_id="item.id"
                @click="checkbtns($event)"
            >
              立即预定(包车)
            </button>
          </div>
        </div>
      </Card>
      <Spin fix v-if="spinShow">
       <div class="demo-spin-icon-load">
         <i class="iconfont icon-loader--line"></i>
       </div>
       <div style='font-size:16px' >Loading...</div>
      </Spin>
    </div>
    <div class="show" v-show="tables">
      <div class="showtip">
        <img
            @click="outCheck"
            src="../../../assets/ca.png"
            alt=""
            style="
            width: 20px;
            height: 20px;
            position: absolute;
            right: 10px;
            top: 10px;
          "
        />
        <div class="infos" style="margin-bottom: 20px">
          <p style="margin-left: 10px">
            用车日期：{{ this.dates + this.time }}
          </p>
          <p style="margin-left: 210px">
            上车地址：{{ this.departure }}
          </p>
        </div>
        <div
            style="
            width: 100%;
            height: 50px;
            display: flex;
            flex-direction: row;
            align-items: center;
          "
        >
          <p style="margin-top: 5px; margin-left: 40px; margin-right: 10px">
            选择乘车人:
          </p>
          <Select
              v-model="passengerNumber"
              :label-in-value="true"
              @on-change="passengerChange"
              placeholder="请选择乘车人"
              style="width: 340px; margin-left: 0px"
          >
            <Option
                v-for="item in passenger"
                :label="item.contact"
                :value="`${item.contact}  ${item.mobile} `"
                :key="item.id"
                :data-uid="item.uid"
            >{{ item.contact }} {{ item.mobile }}
            </Option>
          </Select>
          <div
              @click="addPassenger"
              style="
              margin-left: 20px;
              border-radius: 2px;
              border: 1px #d9d9d9 dashed;
              width: 300px;
              height: 30px;
              text-align: center;
              line-height: 30px;
            "
          >
            +添加乘车人
          </div>
        </div>
        <div class="lines"></div>
        <div style="text-align: left; margin-left: 30px; margin-right: 30px">
          <p style="margin-left: 10px; margin-right: 10px; margin-top: 10px">
            预定须知
          </p>
          <div style="margin-left: 10px; margin-right: 10px">
            <div style="margin-left: 10px; margin-right: 10px">
              <p style="margin-top: 10px">
                套餐不含:由乘客临时增加用车需求而产生的费用按如下标准收费。详情如下：
              </p>
              <table
                  style="
                  border-collapse: collapse;
                  width: 800px;
                  border: 1px black solid;
                  margin-top: 10px;
                "
              >
                <!-- <tr>
                  <td
                    style="
                      width: 100px;
                      text-align: center;
                      border: 1px black solid;
                    "
                  >
                    超公里费
                  </td>
                  <td style="border: 1px black solid">
                    当日用车总里程超过100公里后，司机将按2元/公里收取超公里费。
                  </td>
                </tr> -->
                <tr>
                  <td
                      style="
                      width: 100px;
                      text-align: center;
                      border: 1px black solid;
                    "
                  >
                    超时长费
                  </td>
                  <td style="border: 1px black solid; padding-left: 5px">
                    当日用车总时长超过8小时后，司机将按15元/半小时标准收取超时长费。半小时为最小计量单位，超过5分钟向上取整，不足5分钟向下取整。
                  </td>
                </tr>
                <tr>
                  <td
                      style="
                      width: 100px;
                      text-align: center;
                      border: 1px black solid;
                    "
                  >
                    第三方收费
                  </td>
                  <td style="border: 1px black solid; padding-left: 5px">
                    实际行驶中产生的停车费、过路过桥费、高速费等第三方费用由乘客自行支付，如由司机垫付须线下支付司机并向司机索要报销凭证。
                    注：如产生套餐不含费用项请在服务结束后，将钱（RMB或等额外币）面付司机。严禁司机额外乱收费，如发现可联系客服核实退还。
                  </td>
                </tr>
              </table>
              <!-- <p style="margin-top: 10px">
                取消时间:取消时间:2020-11-24 07:00
                以前可免费取消。超过最晚取消时间，将收取您350元违约金。
                开具发票:如需发票，由携程统一开具。请在支付成功后至订单详情页填写开票信息，开票总金额包含超时超公里费用（发票金额不含礼品卡支付金额以及客人自付第三方停车、路桥费金额）。
                套餐期限:预订多日包车，每日套餐内容不累计，当日24点仍未使用自动作废。
              </p> -->
            </div>

            <div
                style="
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
              "
            >
              <p
                  v-show="showcou"
                  style="
                  font-size: 14px;
                  margin-right: 10px;
                  margin-top: 25px;
                  color: #ff5400;
                "
              >
                立减券已抵扣{{ checkdiscount }}元
              </p>
              <p style="margin-right: 10px">
                需支付￥<span style="color: #ff5400">{{ checkPrice }}</span>
                元
              </p>
              <button
                  style="
                  color: #ffff;
                  font-size: 12px;
                  margin-top: 20px;
                  width: 100px;
                  height: 30px;
                  background-color: #ff5400;
                  outline: none;
                  border: none;
                "
                  @click="paycharteredOrder"
              >
                去支付
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="show" v-show="table">
      <div class="showtip">
        <img
            @click="outCheckorder"
            src="../../../assets/ca.png"
            alt=""
            style="
            width: 20px;
            height: 20px;
            position: absolute;
            right: 10px;
            top: 10px;
          "
        />
        <div class="infos">
          <p style="margin-left: 10px">
            用车日期：{{ this.dates + this.time }}
          </p>
        </div>
        <div class="infos" style="margin-bottom: 20px">
          <p style="margin-left: 10px">
            上车地址：{{ this.departureCity + this.departure }}
          </p>
          <p style="margin-left: 60px">
            下车地址：{{ this.outDepartureCity + this.departure2 }}
          </p>
        </div>
        <div
            style="
            width: 100%;
            height: 50px;
            display: flex;
            flex-direction: row;
            align-items: center;
          "
        >
          <p style="margin-top: 5px; margin-left: 40px; margin-right: 10px">
            选择乘车人:
          </p>
          <Select
              v-model="passengerNumber"
              :label-in-value="true"
              @on-change="passengerChange"
              placeholder="请选择乘车人"
              style="width: 340px; margin-left: 0px"
          >
            <Option
                v-for="item in passenger"
                :label="item.contact"
                :value="`${item.contact}  ${item.mobile} `"
                :key="item.id"
                :data-uid="item.uid"
            >{{ item.contact }} {{ item.mobile }}
            </Option>
          </Select>
          <div
              @click="addPassenger"
              style="
              margin-left: 20px;
              border-radius: 2px;
              border: 1px #d9d9d9 dashed;
              width: 300px;
              height: 30px;
              text-align: center;
              line-height: 30px;
            "
          >
            +添加乘车人
          </div>
        </div>
        <div class="lines"></div>
        <div style="text-align: left; margin-left: 30px; margin-right: 30px">
          <p
              style="
              margin-left: 10px;
              margin-right: 10px;
              margin-top: 10px;
              font-size: 13px;
            "
          >
            预定须知
          </p>
          <div style="margin-left: 10px; margin-right: 10px">
            <div
                style="
                margin-top: 10px;
                font-size: 12px;
                display: flex;
                flex-direction: column;
              "
            >
              <span>1、禁止在车内饮食</span>
              <span
              >2、各车型的承载人数及行李都有所限制，请您仔细核对，由于您自身选择车型不符而影响您的用车，该损失由您自行承担。</span
              >
              <span
              >3、原则上儿童、婴儿计入乘车人数。实际乘车情况可与司机协商车辆安排遵照同级或升级车辆安排准则，车品牌不可指定。</span
              >
            </div>

            <div
                style="
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
              "
            >
              <p
                  v-show="showcoupon"
                  style="
                  font-size: 14px;
                  margin-right: 10px;
                  margin-top: 25px;
                  color: #ff5400;
                "
              >
                立减券已抵扣{{ onediscount }}元
              </p>
              <p style="margin-right: 10px">
                需支付￥<span style="color: #ff5400">{{ onewayprices }}</span>
                元
              </p>
              <button
                  style="
                  color: #ffff;
                  font-size: 12px;
                  margin-top: 20px;
                  width: 100px;
                  height: 30px;
                  background-color: #ff5400;
                  outline: none;
                  border: none;
                "
                  @click="orderbtn"
              >
                去支付
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="show" v-show="tiptext">
      <div class="showtips">
        <p>请填写正确的电话号码</p>
        <p class="tipBtn" @click="tipbtns">确认</p>
      </div>
    </div>
    <div class="show" v-show="paytip">
      <div class="showtips">
        <p>下单成功，余额剩余:{{ balance }}</p>
        <p class="tipBtn" @click="paytipbtn">确认</p>
      </div>
    </div>
    <div class="show" v-show="payerrs">
      <div class="showtips">
        <p>余额不足，请充值！</p>
        <p class="tipBtn" @click="payerr">确认</p>
      </div>
    </div>
    <div class="show" v-show="tiptexts">
      <div class="showtips" style="width: 340px">
        <p>暂未开通此线路,如有定制需求,联系客服0755-86665622</p>
        <p class="tipBtn" style="margin-top: 16px" @click="lxbtn">确认</p>
      </div>
    </div>
    <div class="show" v-show="tipPassenger">
      <div class="showtipPassenger">
        <img
            @click="outPassenger"
            src="../../../assets/ca.png"
            alt=""
            style="
            width: 20px;
            height: 20px;
            position: absolute;
            right: 10px;
            top: 10px;
          "
        />
        <div
            style="
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: 30px;
            margin-top: 25px;
          "
        >
          <p
              style="
              margin-top: 25px;
              margin-left: 10px;
              margin-right: 10px;
              font-size: 14px;
            "
          >
            乘车联系人
          </p>
          <input
              type="text"
              id="inputId"
              placeholder="请输入联系人姓名"
              v-model="cname"
              style="
              padding-left: 10px;
              width: 200px;
              height: 30px;
              outline-color: #ff5400;
              border: 1px solid #d9d9d9;
              margin-top: 25px;
            "
          />
        </div>
        <div style="display: flex; flex-direction: row; align-items: center">
          <p
              style="
              font-size: 14px;
              margin-left: 41px;
              margin-right: 10px;
              margin-top: 25px;
            "
          >
            联系人电话
          </p>
          <input
              type="text"
              id="inputId1"
              placeholder="请输入联系人电话"
              style="
              padding-left: 10px;
              width: 200px;
              height: 30px;
              border: 1px solid #d9d9d9;
              outline-color: #ff5400;
              margin-top: 25px;
            "
              v-model="cnumber"
          />
        </div>
        <p class="tipBtn" @click="passengerBtn">确认</p>
      </div>
    </div>
  </div>
</template>

<script>
import storage from "store";
import {
  domesticCities,
  autocomplete,
  onewayPrice,
  charOrder,
  passengerList,
  createUser,
  oneCoupon,
  daycharterPrice,
  payOrder,
  getCombos
} from "@/api/charterCar";

import moment from "moment";

export default {
  name: "Charterindex",
  data() {
    return {
      spinShow: false,
      disabledHours: [],
      payerrs: false,
      usedays: false,
      tables: false,
      paytip: false,
      tiptexts: false,
      passengerNumber: "",
      passenger: [],
      tipPassenger: false,
      tiptext: false,
      cnumber: "",
      cname: "",
      table: false,
      outdepartureTipList: "",
      outisGetAddressList: false,
      departure2: "",
      oneway: true,
      setmeal: false,
      departure: "",
      showcoupon: false,
      showcou: false,
      single: true,
      single1: true,
      onesingle: true,
      onesingle1: true,
      selectedPackage: 0,
      unlimited: "",
      charterCar: [""],
      serviceProvider: [""],
      onecharterCar: 0,
      oneserviceProvider: [""],
      date: "",
      time: "",
      is_in_mainland: 1,
      usertips: false,
      active: "A",
      block: "A",
      // nowIndex: 0,
      social: ["facebook", "github"],
      columns12: [
        {
          title: "服务商",
          slot: "name",
          align: "center",
        },
        {
          title: "评分",
          key: "age",
          align: "center",
        },
        {
          title: "11",
          key: "address",
          align: "center",
        },
        {
          title: "价格",
          key: "p",
          align: "center",
        },
        {
          title: "详情",
          slot: "action",
          width: 150,
          align: "center",
        },
      ],
      data6: [
        {
          name: "小马出行",
          age: 18,
          address: "New York No. 1 Lake Park",
          p: "￥900",
        },
        {
          name: "阳光出行",
          age: 24,
          address: "London No. 1 Lake Park",
          p: "￥900",
        },
      ],
      setMeal: [
        {mealName: "8小时100公里"},
        {mealName: "8小时200公里"},
        {mealName: "10小时300公里"},
      ],
      aa: "mei",
      cityLists: "",
      departureCity: "",
      remind:
          "在选项状态发生改变时触发，返回已选中的数组。通过修改外部的数据改变时不会触发在选项状态发生改变时触发，返回已选中的数组。通过修改外部的数据改变时不会触发",
      dateList: [
        {value: "1", label: "1"},
        {value: "2", label: "2"},
        {value: "3", label: "3"},
        {value: "4", label: "4"},
        {value: "5", label: "5"},
        {value: "6", label: "6"},
        {value: "7", label: "7"},
        {value: "8", label: "8"},
        {value: "9", label: "9"},
        {value: "10", label: "10"},
      ],
      serviceCarType: "",
      carDays: "",
      carType: "",
      cityId: "",
      charterCartype: "",
      charterCarFleet: "",
      charterCarPackage: "",
      onecharterCarPackage: "",
      price_list: [],
      car_prices: [],
      onecharterCarFleet: "",
      cardata: "",
      onecarnumber: "",
      carsit: "",
      carbuy: "",
      departureTipList: "",
      carnumber: "",
      outDepartureCity: "",
      outCityId: "",
      onewayprices: "",
      dates: "",
      balance: "",
      service_ids: "",
      car_id: "",
      uid: "",
      uname: "",
      unumber: "",
      onecouponName: "",
      onediscount: 0,
      onecouponId: "",
      checkPrice: "",
      oneconpondis: "",
      bcdept_lat: "",
      bcdept_lng: "",
      coo_id: "",
      checkCouponname: "",
      checkCouponId: "",
      checkdiscount: 0,
      options3: {
        disabledDate(date) {
          // console.log(date);
          return date && date.valueOf() < Date.now() - 86400000;
        },
      },
    };
  },
  methods: {
    onSearchDepartureTips(value) {
      autocomplete({content: value, city: storage.get("insideCityId")}).then(
          (res) => {
            this.departureTipList = [];
            this.departureTipList = res.data.tips;
            if (res.data.tips.length > 0) {
              this.isGetAddressList = true;
            }
          }
      );
    },
    onSearchDestinationTips(value) {
      autocomplete({content: value, city: storage.get("insideCityId")}).then(
          (res) => {
            this.destinationTipList = res.data.tips;
          }
      );
    },
    selectDepartureAddress(tip) {
      console.log(1111);
      this.departure = tip.name;
      this.departureAddress = tip;
      this.insidelat = tip.location;
      storage.set("dept_lat", tip.location.split(",")[0]);
      storage.set("dept_lng", tip.location.split(",")[1]);
      console.log(this.departureAddress.location);
    },
    selectDestinationAddress(tip) {
      this.destination = tip.name;
      this.destinationAddress = tip;
    },

    outonSearchDepartureTips(value) {
      autocomplete({content: value, city: storage.get("outCityId")}).then(
          (res) => {
            this.outdepartureTipList = [];
            this.outdepartureTipList = res.data.tips;
            if (res.data.tips.length > 0) {
              this.outisGetAddressList = true;
              console.log(this.outdepartureTipList);
            }
          }
      );
    },
    outeonSearchDestinationTips(value) {
      autocomplete({content: value, city: storage.get("outCityId")}).then(
          (res) => {
            this.destinationTipList = res.data.tips;
          }
      );
    },
    outselectDepartureAddress(tip) {
      this.departure2 = tip.name;
      this.outlng = tip.location;
      console.log(this.outlng);
      storage.set("outdept_lat", tip.location.split(",")[0]);
      storage.set("outdept_lng", tip.location.split(",")[1]);
      this.departureAddress = tip;
      storage.set("departure2", this.departure2);
    },

    charteredRequery() {
        this.spinShow = true;
        this.selectedPackage = 0;
        this.beginDate = moment(this.date).format("YYYY-MM-DD");
        const paramter = {
        dept_lat: this.insidelat.split(',')[1],
        dept_lng: this.insidelat.split(',')[0],
        dest_lat: this.insidelat.split(',')[1],
        dest_lng: this.insidelat.split(',')[0],
        date: this.beginDate + " " + this.time,
        service_type: 2,
        service_combo_id:this.charterCarPackage[this.selectedPackage].id
      };
      onewayPrice(paramter).then((res) => {
        if (res.code == 201) {
          this.tiptexts = !this.tiptexts; //控制窗体显示
          this.spinShow = false;
        }
        this.price_list = res.data.price_list
        this.charterCar = 0
        this.car_prices = res.data.price_list[0].cars
        this.spinShow = false;
       });
    },
    func: function (m) {
      this.active = m;
      this.block = m;
    },
    show(index) {
      console.log(index);
      this.date = moment().format("yyyy年MM月DD日");
      this.usertips = !this.usertips;
    },
    out() {
      this.usertips = !this.usertips;
    },
    dateChange(date) {
      // this.date=moment().format("yyyy年MM月DD日")
      const selectedDate = date
      const start = selectedDate.lastIndexOf("月")+1
      const stop = start + 2
      const daySelectedDate = date.substring(start,stop)
      const today = new Date()
      const dayToday = today.getDate()
      const hourToday = new Date().getHours();
      if(daySelectedDate == dayToday)
      {
        let disabledHour= hourToday+4
        for(let i=0;i<disabledHour;i++)
        {
          this.disabledHours.push(i)
        }
      }
      else
      {
        this.disabledHours = []
      }
    },
    timeChange() {
      console.log(this.time);
      storage.set("times", this.time);
    },
    carTypes() {
      console.log(this.carType);
      storage.set("carType", this.carType);
    },
    carDay() {
      console.log(this.carDays);
      storage.set("carDays", this.carDays);
    },

    // 套餐类不限清空列表
    singlecheck1() {
      this.date = moment(this.date).format("YYYY-MM-DD");
      console.log("车型数据测试");
      if (this.single1 == true || this.serviceProvider == [""]) {
        this.charterCar = [""];
        if (this.userCarType == 3) {
          daycharterPrice({
            date: this.date + " " + this.time,
            area_id: this.cityId,
            coo_ids: 1,
            car_type_ids: "1,2,3,4",
            package_id: 5,
            type: this.userCarType,
          }).then((res) => {
            console.log(res.data);
            this.carnumber = res.data.cars;
          });
        } else if (this.userCarType == 3) {
          daycharterPrice({
            date: this.date + " " + this.time,
            area_id: this.cityId,
            coo_ids: 1,
            car_type_ids: "1,2,3,4",
            package_id: 9,
            type: this.userCarType,
          }).then((res) => {
            console.log(res.data);
            this.carnumber = res.data.cars;
          });
        }
      } else {
        this.date = moment(this.date).format("YYYY-MM-DD");
        if (this.userCarType == 3) {
          daycharterPrice({
            date: this.date + " " + this.time,
            area_id: this.cityId,
            coo_ids: this.serviceProvider,
            car_type_ids: "1,2,3,4",
            package_id: 9,
            type: this.userCarType,
          }).then((res) => {
            console.log(res.data);
            this.carnumber = res.data.cars;
          });
        } else if (this.userCarType == 4) {
          daycharterPrice({
            date: this.date + " " + this.time,
            area_id: this.cityId,
            coo_ids: this.serviceProvider,
            car_type_ids: "1,2,3,4",
            package_id: 9,
            type: this.userCarType,
          }).then((res) => {
            console.log(res.data);
            this.carnumber = res.data.cars;
          });
        }
      }
    },
    singlecheck() {
      this.date = moment(this.date).format("YYYY-MM-DD");
      if (this.single == true || this.charterCar == [""]) {
        this.serviceProvider = [""];
        daycharterPrice({
          area_id: this.cityId,
          coo_ids: 1,
          car_type_ids: "1,2,3,4",
          package_id: 5,
          date: this.date + " " + this.time,
          type: this.userCarType,
        }).then((res) => {
          console.log(res.data);
          this.carnumber = res.data.cars;
        });
      } else {
        daycharterPrice({
          date: this.date + " " + this.time,
          type: this.userCarType,
          area_id: this.cityId,
          coo_ids: 1,
          car_type_ids: this.charterCar,
          package_id: 5,
        }).then((res) => {
          console.log(res.data);
          this.carnumber = res.data.cars;
        });
      }
    },
    // 套餐列表有值清空不限
    checkcarType(e) {
      this.car_prices = this.price_list[e].cars
    },
    serviceCheckcar() {
      this.date = moment(this.date).format("YYYY-MM-DD");
      console.log("测试服务商数据测试");
      this.single = false;
      this.carbuy = this.serviceProvider.slice(1).join(",");
      console.log(this.serviceProvider.slice(1).join(","));
      if (this.charterCar !== [""]) {
        console.log(1111111);
        const params = {
          area_id: this.cityId,
          coo_ids: this.carbuy,
          car_type_ids: "1,2,3,4",
          package_id: this.selectedPackage,
          date: this.date + " " + this.time,
          type: this.userCarType,
        };
        daycharterPrice(params).then((res) => {
          console.log(res.data);
          this.carnumber = res.data.cars;
        });
      } else {
        const params = {
          area_id: this.cityId,
          coo_ids: this.carbuy,
          car_type_ids: this.carsit,
          package_id: this.selectedPackage,
          date: this.date + " " + this.time,
          type: this.userCarType,
        };
        daycharterPrice(params).then((res) => {
          console.log(res.data);
          this.carnumber = res.data.cars;
        });
      }
    },
    carPackage(e) {
      this.date = moment(this.date).format("YYYY-MM-DD");
      console.log("测试套餐");
      console.log(e);
      console.log(this.charterCarPackage[e].id);
      storage.set("carpackage", this.selectedPackage);
      const params = {
        dept_lat: this.ucInsidelat.split(',')[1],
        dept_lng: this.ucInsidelat.split(',')[0],
        dest_lat: this.ucOutlng.split(',')[1],
        dest_lng: this.ucOutlng.split(',')[0],
        date: this.beginDate + " " + this.time,
        service_type: 2,
        service_combo_id:this.charterCarPackage[e].id
      };
      onewayPrice(params).then((res) => {
        if (res.code == 201) {
          this.tiptexts = !this.tiptexts; //控制窗体显示
        }
        console.log(res.data)
        this.price_list = res.data.price_list
        this.charterCar = 0
        this.car_prices = res.data.price_list[0].cars
        const param = {
          user_id: storage.get("user").id
        };
        oneCoupon(param).then((res) => {
          console.log(res.data);
          if (res.code === 200) {
            this.showcoupon = true;
          } else if (res.code === 201) {
            this.showcoupon = false;
          }
          this.onecouponName = res.data.coupon.name;
          this.onediscount = res.data.coupon.discount;
          this.oneconpondis = res.data.coupon.discount;
          this.onecouponId = res.data.coupon_id;
        });
      });
    },
    //单程列表清空不限
    onecheckcarType(e) {
      this.onesingle1 = false;
      this.date = moment(this.date).format("YYYY-MM-DD");
      this.car_prices = this.price_list[e].cars
    },
    oneserviceCheckcar() {
      console.log(11111);
      this.onesingle = false;
      this.onecarsit = this.onecharterCar.slice(1).join(",");
      this.onecarbuy = this.oneserviceProvider.slice(1).join(",");
      this.date = moment(this.date).format("YYYY-MM-DD");
      //  if(this.onecharterCar ==''){
      const params = {
        dept_lat: this.insidelat.split(',')[1],
        dept_lng: this.insidelat.split(',')[0],
        dest_lat: this.outlng.split(',')[1],
        dest_lng: this.outlng.split(',')[0],
        date: this.date + " " + this.time,
        service_type: 1
      };
      onewayPrice(params).then((res) => {
        this.price_list = res.data.price_list
        this.onecharterCar = 0
        this.car_prices = res.data.price_list[0].cars
      });
    },
    onExpand(e) {
      console.log(e.name);
    },

    outCheck() {
      this.tables = !this.tables;
    },
    checkbtns(event) {
      console.log(event)
      this.tables = !this.tables;
      this.checkPrice = event.target.dataset.price;
      this.dates = moment(this.date).format("yyyy年MM月DD日");
      this.service_ids = event.target.dataset.service_id;
      this.price_id = event.target.dataset.price_id;
      this.coo_id = event.target.dataset.coo_id;
      this.car_id = event.target.dataset.car_id;
    },
    checkbtn(event) {
      console.log(event);
      this.table = true;
      this.original_price = event.path[1].innerText.substring(1, 5) - 0;
      this.onewayprices = event.path[1].innerText.substring(1, 5) - this.onediscount;
      this.dates = moment(this.date).format("yyyy年MM月DD日");
      console.log(this.date);
      this.service_ids = event.target.dataset.service_id;
      this.price_id = event.target.dataset.price_id;
      this.car_id = event.target.dataset.car_id;
      console.log(this.service_id);
      console.log(event.target.dataset);
    },
    paycharteredOrder() {
      if (this.unumber != "" && this.uname != "") {
        this.date = moment(this.date).format("YYYY-MM-DD");
        charOrder({
          dept_loc: this.departureCity + this.departure,
          dest_loc: this.departureCity + this.departure,
          dept_date_time: this.date + " " + this.time,
          route_id: 1,
          passenger_name: this.uname,
          passenger_mobile: this.unumber,
          amount: this.checkPrice - this.checkdiscount,
          user_id: storage.get("user").id,
          custom_id: 1,
          type: this.userCarType,
          uid: this.uid,
          coupon_id: this.checkCouponId,
          service_id: this.service_ids,
          car_type_id: this.car_id,
          //dept_lng: this.bcdept_lng,
          //dept_lat: this.bcdept_lat,
          combo_id: this.charterCarPackage[this.selectedPackage].id,
          price_id: this.price_id,
          price: this.checkPrice,
        }).then((res) => {
            console.log('res.data.order_id',res.data.order_id);
            payOrder({order_id:res.data.order_id,pay_type:4}).then((res) => {
              if(res.code == 201){
                this.payerrs = !this.payerrs;
              }else{
                this.balance = res.data.balance;
                storage.set("balance", res.data.balance);
                this.paytip = !this.paytip;
                this.tables = !this.tables;
              }
            });

        });
      } else {
        this.tiptext = !this.tiptext;
      }
    },
    payerr() {
      this.payerrs = !this.payerrs;
      this.$router.push({path: "/manage/recharge/online"});
    },
    orderbtn() {
      this.date = moment(this.date).format("YYYY-MM-DD");
      if (this.unumber != "" && this.uname != "") {
        charOrder({
          dept_loc: this.departureCity + this.departure,
          dest_loc: this.outDepartureCity + this.departure2,
          dept_date_time: this.date + " " + this.time,
          route_id: 1,
          passenger_name: this.uname,
          passenger_mobile: this.unumber,
          amount: this.onewayprices,
          user_id: storage.get("user").id,
          custom_id: 1,
          type: 1,
          uid: this.uid,
          coupon_id: this.onecouponId,
          service_id: this.service_ids,
          car_type_id: this.car_id,
          price_id: this.price_id,
          price: this.original_price,
        }).then((res) => {
            payOrder({order_id:res.data.order_id,pay_type:4}).then((res) => {
              if (res.code == 201) {
                this.payerrs = !this.payerrs;
              }else{
                this.balance = res.data.balance;
                storage.set("balance", res.data.balance);
                this.paytip = !this.paytip;
                this.table = !this.table;
              }
            });
        });
      } else {
        this.tiptext = !this.tiptext;
      }
    },
    paytipbtn() {
      this.paytip = !this.paytip;
      this.$router.replace("/manage/center");
    },
    outCheckorder() {
      this.table = !this.table;
    },
    tipbtns() {
      this.tiptext = !this.tiptext;
    },
    outPassenger() {
      this.tipPassenger = !this.tipPassenger;
    },
    passengerBtn() {
      if (this.cnumber != "" && this.cname != "") {
        createUser({
          user_mobile: this.cnumber,
          user_id: storage.get("user").id,
          nick_name: this.cname,
        }).then((res) => {
          this.uid = res.data.customer_id;
          passengerList({user_id: storage.get("user").id}).then((res) => {
            this.passenger = res.data;
          });
        });
        this.userinfo = !this.userinfo;
      } else {
        this.tiptext = !this.tiptext;
      }

      this.tipPassenger = !this.tipPassenger;
    },
    addPassenger() {
      this.tipPassenger = !this.tipPassenger;
    },
    passengerChange(e) {
      this.uname = e.label;
      this.uid = event.target.dataset.uid;
      this.unumber = this.passengerNumber.replace(/[^0-9]/gi, "");
      console.log(this.passengerNumber.replace(/[^0-9]/gi, ""));
      console.log(event.target.dataset.uid);
    },
    onebtn() {
      this.spinShow=true;
      this.onesingle = true;
      this.onesingle1 = true;
      this.onecharterCar = [""];
      this.oneserviceProvider = [""];
      this.date = moment(this.date).format("YYYY-MM-DD");
      onewayPrice({
        dept_lat: this.insidelat.split(',')[1],
        dept_lng: this.insidelat.split(',')[0],
        dest_lat: this.outlng.split(',')[1],
        dest_lng: this.outlng.split(',')[0],
        date: this.date + " " + this.time,
        service_type: 1,
      }).then((res) => {
        this.price_list = res.data.price_list
        this.onecharterCar = 0
        this.car_prices = res.data.price_list[0].cars
        this.spinShow=false;
        if (res.code == 201) {
          this.tiptexts = !this.tiptexts;
          this.spinShow=false;
        }
      });
    },
    handleSpinCustom () {
                this.$Spin.show({
                    render: (h) => {
                        return h('div', [
                            h('Icon', {
                                'class': 'demo-spin-icon-load',
                                props: {
                                    type: 'ios-loading',
                                    size: 18
                                }
                            }),
                            h('div', 'Loading')
                        ])
                    }
                });
                setTimeout(() => {
                    this.$Spin.hide();
                }, 3000);
            },
    lxbtn() {
      this.$router.replace("/daycharter/charters/index");
      this.tiptexts = !this.tiptexts;
    },
  },
  created() {
    this.balance = console.log(storage.get("balance"));
    storage.set("carpackage", this.selectedPackage);
    //console.log(storage);
    const params = {
      is_in_mainland: this.is_in_mainland,
    };
    domesticCities(params).then((res) => {
      //console.log(res.data);
      this.cityLists = res.data;
    });
    //console.log(this.$route.params.outDepartureCity);
    this.departureCity = this.$route.params.departureCity;
    this.outDepartureCity = this.$route.params.outDepartureCity;
    this.departure2 = this.$route.params.departure2;
    //console.log(this.departure2);
    this.time = this.$route.params.time;
    this.departure = this.$route.params.departure;
    this.carDays = this.$route.params.carDays;
    this.carType = this.$route.params.carType;
    this.cityId = this.$route.params.cityId;
    this.insidelat = this.$route.params.insidelat;
    this.outlng = this.$route.params.outlng;
    this.outCityId = this.$route.params.outCityId;
    this.userCarType = this.$route.params.userCarType;
    this.ucInsidelat = this.$route.params.ucInsidelat;
    this.ucOutlng = this.$route.params.ucOutlng;
    //console.log(3333333333333333333333333333333333333333333333333333);
    //console.log(this.userCarType);
    if (this.userCarType == 3) {
      this.selectedPackage = 5;
      this.bcdept_lat = storage.get(" withindept_lat");
      this.bcdept_lng = storage.get(" withindept_lng");
    } else if (this.userCarType == 4) {
      this.selectedPackage = 9;
      this.usedays = !this.usedays;
      this.bcdept_lat = storage.get(" externaldept_lat");
      this.bcdept_lng = storage.get(" externaldept_lng");
    }
    //console.log(this.outDepartureCity);
    if (this.$route.params.date !== "") {
      this.date = this.$route.params.date;
      this.date = moment(this.date).format("YYYY-MM-DD");
      //console.log(this.date);
    }
    if (this.userCarType == 2) {
      this.spinShow=true;
      this.oneway = true;
      this.beginDate = moment(this.date).format("YYYY-MM-DD");
      onewayPrice({
        dept_lat: this.insidelat.split(',')[1],
        dept_lng: this.insidelat.split(',')[0],
        dest_lat: this.outlng.split(',')[1],
        dest_lng: this.outlng.split(',')[0],
        date: this.beginDate + " " + this.time,
        service_type: 1
      }).then((res) => {
        if (res.code == 201) {
          this.tiptexts = !this.tiptexts; //控制窗体显示
          this.spinShow=false;
        }
        this.price_list = res.data.price_list
        this.onecharterCar = 0
        this.car_prices = res.data.price_list[0].cars
        this.spinShow=false;
        const param = {
          user_id: storage.get("user").id
        };
        oneCoupon(param).then((res) => {
          console.log(res.data);
          if (res.code === 200) {
            this.showcoupon = true;
          } else if (res.code === 201) {
            this.showcoupon = false;
          }
          this.onecouponName = res.data.coupon.name;
          this.onediscount = res.data.coupon.discount;
          this.oneconpondis = res.data.coupon.discount;
          this.onecouponId = res.data.coupon_id;
        });
      });
    } else {
      this.spinShow=true;
      this.setmeal = true;
      this.oneway = false;
      this.beginDate = moment(this.date).format("YYYY-MM-DD");
      getCombos().then((res) => {
        this.charterCarPackage = res.data;
        this.selectedPackage=0;
        const paramter = {
        dept_lat: this.ucInsidelat.split(',')[1],
        dept_lng: this.ucInsidelat.split(',')[0],
        dest_lat: this.ucOutlng.split(',')[1],
        dest_lng: this.ucOutlng.split(',')[0],
        date: this.beginDate + " " + this.time,
        service_type: 2,
        service_combo_id:this.charterCarPackage[this.selectedPackage].id
      };
      onewayPrice(paramter).then((res) => {
        if (res.code == 201) {
          this.tiptexts = !this.tiptexts; //控制窗体显示
          this.spinShow=false;
        }
        console.log(res.data)
        this.price_list = res.data.price_list
        this.charterCar = 0
        this.car_prices = res.data.price_list[0].cars
        this.spinShow=false;
        const param = {
          user_id: storage.get("user").id
        };
        oneCoupon(param).then((res) => {
          console.log(res.data);
          if (res.code === 200) {
            this.showcoupon = true;
          } else if (res.code === 201) {
            this.showcoupon = false;
          }
          this.onecouponName = res.data.coupon.name;
          this.onediscount = res.data.coupon.discount;
          this.oneconpondis = res.data.coupon.discount;
          this.onecouponId = res.data.coupon_id;
        });
       });
      });
    }
    passengerList({user_id: storage.get("user").id}).then((res) => {
      this.passenger = res.data;
    });
  },
};
</script>

<style scoped>
@import "../../../style/common.css";

input::-webkit-input-placeholder {
  color: #d9d9d9;
}

input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #d9d9d9;
}

input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #d9d9d9;
}

input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #d9d9d9;
}

.management-center /deep/ .ivu-radio-checked .ivu-radio-inner {
  border-color: #ff5400;
}

.management-center /deep/ .ivu-radio-checked .ivu-radio-inner:after {
  background-color: #ff5400;
}

.management-center /deep/ .ivu-checkbox-checked .ivu-checkbox-inner {
  border-color: #ff5400;
  background-color: #ff5400;
}

.management-center /deep/ .ivu-btn-primary {
  color: #fff;
  background-color: #ff5400;
  border-color: #ff5400;
}

.management-center {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.center-part {
  width: 95%;
  height: 500px;
  margin-top: 70px;
}

.tripinfo {
  display: flex;
  flex-direction: row;
  align-content: center;
}

.model {
  display: flex;
  flex-direction: row;
  align-content: center;
  margin-top: 20px;
}

.inputinfo {
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}

.searchAddressListMask {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  flex-direction: column;
}

.searchAddressListContainer {
  display: flex;
  justify-content: center;
  width: 730px;
  height: 200px;
  flex-direction: column;
  overflow-y: auto;
}

.tip-container {
  padding: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.line {
  width: 100%;
  height: 1px;
  background-color: #d9d9d9;
  position: absolute;
  left: 0;
  top: 0px;
}

.lines {
  width: 100%;
  height: 1px;
  background-color: #d9d9d9;
  margin-top: 30px;
}

.btn {
  width: 150px;
  height: 40px;
  color: white;
  border-radius: 4px;
  background-color: #ff5400;
  outline: none;
  border: none;
  font-size: 14px;
  margin-left: 30px;
}

.modelPrice {
  height: 130px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.modelPrices {
  height: 130px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.logo {
  width: 100px;
  height: 80px;
  background-color: chartreuse;
}

.show {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 100000;
}

.showpay {
  width: 55%;
  background-color: #ffff;
  margin: 100px auto;
  z-index: 100000;
  border-radius: 4px;
}

.info {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.info p {
  margin-left: 10px;
  font-size: 13px;
}

.show {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 100000;
}

.showtip {
  text-align: center;
  width: 880px;
  height: 400px;
  background-color: #ffff;
  position: absolute;
  z-index: 100000;
  border-radius: 4px;
  left: 50%;
  top: 50%;
  margin-left: -440px;
  margin-top: -200px;
}

.showtip p {
  margin-top: 20px;
  font-size: 18px;
}

.showtip .tipBtn {
  margin: 52px auto 139px;
  width: 100px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  color: #fff;
  background: #f16622;
  border-radius: 2px;
  border: 1px solid #f16622;
  cursor: pointer;
}

.infos {
  display: flex;
  flex-direction: row;
  margin-left: 30px;
  font-size: 12px;
}

.showtips {
  text-align: center;
  width: 280px;
  height: 150px;
  background-color: #ffff;
  position: absolute;
  z-index: 100000;
  border-radius: 4px;
  left: 50%;
  top: 50%;
  margin-left: -140px;
  margin-top: -75px;
}

.showtipPassenger {
  text-align: center;
  width: 500px;
  height: 250px;
  background-color: #ffff;
  position: absolute;
  z-index: 100000;
  border-radius: 4px;
  left: 50%;
  top: 50%;
  margin-left: -250px;
  margin-top: -125px;
}

.showtipPassenger .tipBtn {
  margin: 40px auto 139px;
  width: 100px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  color: #fff;
  background: #f16622;
  border-radius: 2px;
  border: 1px solid #f16622;
  cursor: pointer;
}

.showtips .tipBtn {
  margin: 40px auto 139px;
  width: 100px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  color: #fff;
  background: #f16622;
  border-radius: 2px;
  border: 1px solid #f16622;
  cursor: pointer;
}

.showtips p {
  margin-top: 15px;
  font-size: 18px;
}

.user {
  width: 400px;
  height: 30px;
  border: 1px #d9d9d9 dashed;
  margin-left: 80px;
  margin-bottom: 20px;
  border-radius: 4px;
  color: #515a6e;
  text-align: center;
  line-height: 30px;
}

.demo-spin-icon-load{
  animation: ani-demo-spin 1s linear infinite;
}
.demo-spin-container{
    	display: inline-block;
        width: 200px;
        height: 100px;
        position: relative;
        border: 1px solid #eee;
    }
</style>
